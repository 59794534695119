import React, { FC, SetStateAction } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface ICheckBoxProps {
  value: boolean;
  setValue?: React.Dispatch<SetStateAction<boolean>>;
  label: string;
  color?: string;
  size?: 'small' | 'medium';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  dataCy?: string;
}

const CheckBox: FC<ICheckBoxProps> = ({
  value,
  setValue,
  label,
  color,
  size,
  onChange,
  onMouseDown,
  dataCy,
}) => {
  const sx = {
    width: 'fit-content',
    height: 20,
    fontFamily: "'Noto Sans', serif !important",
    fontSize: '16px !important',
    lineHeight: '22px !important',
    color: '#110B11 !important',
    marginBottom: '8px',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      sx={sx}
      onMouseDown={onMouseDown}
      control={
        <Checkbox
          checked={value}
          onChange={onChange ? onChange : handleChange}
          size={size}
          sx={{
            color: '#110B11',
            '&:hover': {
              background: 'transparent !important',
            },
            '&.Mui-checked': {
              color: color ? color : '#DD2006',
            },
          }}
          data-cy={dataCy}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
