import React, { useEffect } from 'react';
import './filter.scss';
import { api } from '../../services/Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllCategoryIds } from '../../utils/get';
import { useActions } from '../../hooks/redux';
import { setReceivedPosts } from './filterOptions';
import Header from '../header/Header';
import FilterContent from './FilterContent';
import Footer from '../footer/Footer';
import { FilterSortByValueType, FilterSortByVariants } from '../../types';
import { ISearchRequest } from '../../services/types';

const Filter = () => {
  const { data: categories } = api.useGetAllCategoriesQuery();
  const { data: locationsData } = api.useGetLocationsQuery();
  const [search, { data, isLoading, isSuccess }] = api.useSearchByQueryMutation();

  const { category_id, query } = useParams();
  const location = useLocation();

  const {
    setIsHandmade,
    setQuery,
    setPosts,
    setSortBy,
    setPriceRange,
    setPostsStates,
    setLocationsStates,
    setFieldsStates,
    setCategoryIds,
    setPriceValue,
    setPagesCount,
    setAmountPostsFound,
    setCurrentPage,
  } = useActions();

  const parseSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const handmade = searchParams.get('handmade');
    const usedState = searchParams.get('used') === 'true';
    const newState = searchParams.get('new') === 'true';
    const sortBy = searchParams.get('sort_by') || 'newest';
    const priceMin = searchParams.get('price_min');
    const priceMax = searchParams.get('price_max');
    const locations = searchParams.get('locations')?.split(',');
    const page = parseInt(searchParams.get('page') || '1', 10);

    const fieldsParam = searchParams.get('fields');
    const fields = fieldsParam ? JSON.parse(decodeURIComponent(fieldsParam)) : [];

    return { handmade, usedState, newState, sortBy, priceMin, priceMax, locations, page, fields };
  };

  useEffect(() => {
    clearFilterOption();
    const { handmade, usedState, newState, sortBy, priceMin, priceMax, locations, page, fields } =
      parseSearchParams();

    setIsHandmade(handmade === 'true' ? true : handmade === 'false' ? false : undefined);
    setCurrentPage(page);
    setPostsStates({ new: newState, used: usedState });
    setSortBy(sortBy as FilterSortByValueType);

    if (categories && category_id) {
      const categoryIds = getAllCategoryIds(categories, category_id);
      setCategoryIds(categoryIds);

      const searchRequestObject: ISearchRequest = {
        category_id: categoryIds,
        q: query || undefined,
        page: page,
        sort_by: sortBy,
        handmade: handmade === 'true' ? true : handmade === 'false' ? false : undefined,
        used: usedState ? true : newState ? false : undefined,
        location_id: locations,
        category_fields: fields,
      };

      search(searchRequestObject).then(response => {
        if (response && 'data' in response) {
          setPriceRange({
            min_price: response.data.filters_values.min_price,
            max_price: response.data.filters_values.max_price,
          });

          if (priceMin && priceMax) {
            setPriceValue([Number(priceMin), Number(priceMax)]);
          }
        }
      });
    }
  }, [categories, category_id, query]);

  useEffect(() => {
    const { locations, fields } = parseSearchParams();

    if (fields.length) {
      const fieldsObject = fields.reduce((acc: Record<string, string>, field: any) => {
        field.values.forEach((value: string) => {
          acc[value] = field.id;
        });
        return acc;
      }, {});
      setFieldsStates(fieldsObject);
    }

    const locationsObject = locationsData
      ?.filter(loc => locations?.includes(loc.id))
      .reduce((acc: Record<string, any>, location) => {
        acc[location.name] = location.id;
        return acc;
      }, {});

    if (locationsObject) {
      setLocationsStates(locationsObject);
    }
  }, [locationsData]);

  useEffect(() => {
    setReceivedPosts(isSuccess, data, setPosts);

    if (data) {
      const categoryCounts = data.category_counts;
      const amountPostsFound = Object.keys(categoryCounts).reduce(
        (acc, key) => acc + categoryCounts[key],
        0,
      );

      setPagesCount(data.pages_count);
      setAmountPostsFound(amountPostsFound);
    }
  }, [data, isSuccess]);

  const clearFilterOption = (): void => {
    setPosts(undefined);
    setSortBy(FilterSortByVariants.NEWEST);
    setPriceRange({
      min_price: null,
      max_price: null,
    });
    setPriceValue([]);
    setPostsStates({ new: false, used: false });
    setLocationsStates({});
    setFieldsStates({});
    setPagesCount(0);
    setIsHandmade(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [useParams()]);

  useEffect(() => {
    if (query) {
      setQuery(query);
    }

    return function () {
      setQuery('');
    };
  }, []);

  return (
    <React.Fragment>
      <Header />

      <FilterContent category_id={category_id} isLoading={isLoading} search={search} />

      <Footer />
    </React.Fragment>
  );
};

export default Filter;
