import { useState } from 'react';

export const usePagination: ({ data, itemsPerPage }: { data: any; itemsPerPage: any }) => {
  next: () => void;
  prev: () => void;
  currentPage: number;
  currentData: any;
  maxPage: number;
  jump: (page: any) => void;
} = ({ data, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = data && Math.ceil(data.length / itemsPerPage);

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  };

  const next = () => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  };

  const jump = (page: any) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return { next, prev, jump, currentData, currentPage, maxPage };
};
