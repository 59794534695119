import React, { FC, useEffect, useState } from 'react';
import { CheckBox } from "../../../default_components";
import { ICategoryField } from "../../../../types";

interface IBooleanField {
  field: ICategoryField;
  fields: Map<string, string>;
}

const FieldBoolean: FC<IBooleanField> = ({ field, fields }) => {
  const [value, setValue] = useState<boolean>(fields.get(field.id) == "true");

  useEffect(() => {
    fields.set(field.id, `${value}`);
  }, [value]);

  return <CheckBox
    color="black"
    value={value}
    setValue={setValue}
    label={field.title}
  />
};

export default FieldBoolean;
