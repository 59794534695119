import React, { FC, SetStateAction, useEffect, useRef, useState } from 'react';
import './avatarUploadModal.scss';
import AvatarCrop from './AvatarCrop';
import { apiProfile } from '../../../../services/ApiProfile';
import { useActions } from '../../../../hooks/redux';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import { Icon, Loader, Snackbar } from '../../../default_components';
import { getFileUrl, getShotFileName } from '../../../../utils/get';
import { bytesToSize } from '../../../../utils/bytesToSize';
import { t } from 'i18next';

interface AvatarUploadModal {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  avatar: string | undefined;
}

const maxSize: number = 10000000;

const AvatarUploadModal: FC<AvatarUploadModal> = React.memo(({ open, setOpen, avatar }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { setCrop, setSelectedSize } = useActions();

  const [updateAvatar, { isSuccess, reset }] = apiProfile.useUpdateAvatarMutation();
  const {
    open: activeSnackbar,
    message,
    severity,
    closeSnackbar,
    getActiveSnackbar,
  } = useMethodsSnackbar();

  const [drag, setDrag] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<File>();
  const [PermissionToRequest, setPermissionToRequest] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      setCroppedImage(undefined);
      setFile(undefined);
      reset();
      setPermissionToRequest(false);
      setSelectedSize(100);
      setCrop({
        width: 80,
        height: 80,
        x: 80,
        y: 80,
        unit: 'px',
      });
    }

    if (!file) {
      setCroppedImage(undefined);
    }
  }, [isSuccess, avatar, file]);

  useEffect(() => {
    croppedImage && sendUploadedAvatar();
  }, [croppedImage]);

  const handleChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
        if (file.size < maxSize) {
          setFile(file);
        } else {
          getActiveSnackbar('File size limit exceeded', 'info');
        }
      } else {
        getActiveSnackbar('Invalid file format', 'error');
      }
    }
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDrag(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setDrag(false);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    let file = e.dataTransfer.files;
    if (file) {
      setFile(file[0]);
    }
  };

  const clearInputFile = (): void => {
    setFile(undefined);
    setCrop({
      width: 80,
      height: 80,
      x: 80,
      y: 80,
      unit: 'px',
    });
    setSelectedSize(100);
  };

  const sendUploadedAvatar = (): void => {
    const formData = new FormData();

    if (croppedImage) {
      formData.append('avatar', croppedImage);
      updateAvatar(formData);
    }
  };

  return (
    <div className={`modal_frame ${open && 'show'}`} onClick={() => setOpen(false)}>
      <div className="modal_container" onClick={e => e.stopPropagation()}>
        <div className="avatar-update-modal-content" onClick={e => e.stopPropagation()}>
          <p className="modal_title">{t('translation:profile.upload_avatar.message')}</p>

          {file ? (
            <AvatarCrop
              flag={PermissionToRequest}
              src={getFileUrl(file)}
              fileName={file.name}
              imageRef={imageRef}
              setCroppedImage={setCroppedImage}
            />
          ) : (
            <label htmlFor="upload_avatar">
              <div
                onDragStart={onDragStart}
                onDragLeave={onDragLeave}
                onDragOver={onDragStart}
                onDrop={handleOnDrop}
              >
                <div className={`upload-square ${drag && 'pressed-file'}`}>
                  <Icon type="uploadArrow" />

                  <p className="upload-square-text">
                    {t('translation:profile.upload_avatar_1.message')}
                    <span>{t('translation:profile.upload_avatar_2.message')}</span>
                    {t('translation:profile.upload_avatar_3.message')}
                  </p>
                </div>
              </div>
            </label>
          )}

          <input
            accept=".jpg,.jpeg,.png"
            id="upload_avatar"
            type="file"
            style={{ display: 'none' }}
            onChange={handleChangeAvatar}
          />

          {file && (
            <div className="box-file-info_frame">
              <div className="box-file-info_container">
                <div className="box-file-root-info">
                  <Icon type="fileUploaded" />

                  <div className="file-name-size">
                    <p className="title">{getShotFileName(file)}</p>

                    <p className="size">{bytesToSize(file.size)}</p>
                  </div>
                </div>

                <div className="delete-avatar_container" onClick={clearInputFile}>
                  <Icon type="deleteType" />
                </div>
              </div>
            </div>
          )}

          <div
            className={`upload-button ${file ? 'active' : 'disabled'}`}
            onClick={() => file && setPermissionToRequest(true)}
          >
            {PermissionToRequest ? (
              <Loader isWhite={true} />
            ) : (
              t('translation:profile.upload_avatar.message')
            )}
          </div>

          <div className="modal_close-button" onClick={() => setOpen(false)}>
            <Icon type="crossForModal" />
          </div>
        </div>
      </div>

      <Snackbar open={activeSnackbar} message={message} severity={severity} close={closeSnackbar} />
    </div>
  );
});

export default AvatarUploadModal;
