import React, { FC } from 'react';
import './chipsContainer.scss';
import Chip from './Chip';
import { t } from 'i18next';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { FilterSortByVariants, IFilterOption, IPostsStates } from '../../../../types';
import { ISearchRequest } from '../../../../services/types';
import { categoryFields } from '../../filterOptions';
import { Icon } from '../../../default_components';
import { useNavigate, useParams } from 'react-router-dom';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface IChipsContainer {
  search: ({}: ISearchRequest) => void;
}

const ChipsContainer: FC<IChipsContainer> = ({ search }) => {
  const {
    setQuery,
    setPostsStates,
    setLocationsStates,
    setFieldsStates,
    setPriceValue,
    setPosts,
    setSortBy,
    setIsHandmade,
    setCurrentPage,
  } = useActions();

  const { updateQueryParams } = useUpdateQueryParams();

  const {
    query,
    priceValue,
    priceRange,
    postsStates,
    locationsStates,
    fieldsStates,
    sortBy,
    categoryIds,
    isHandmade,
  } = useAppSelector(state => state.filterReducer);

  const navigateWithLocale = useNavigateWithLocale();
  const { category_id, query: UrlQuery } = useParams();

  const range = Object.values(priceValue);
  const regions = Object.values(locationsStates);

  const isShowRangeChip: boolean =
    (range.length > 0 && range[0] !== priceRange.min_price) ||
    (range.length > 0 && range[1] !== priceRange.max_price);

  const searchAfterDisable = (obj: {}) => {
    setPosts(undefined);

    const requestObject: ISearchRequest = {
      page: 1,
      category_id: categoryIds,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
      ...obj,
    };

    if (query) {
      requestObject.q = query;
    }

    if (!requestObject.handmade) {
      delete requestObject.handmade;
    }

    if (isHandmade && !obj.hasOwnProperty('handmade')) {
      requestObject.handmade = isHandmade;
    }

    search(requestObject);

    updateQueryParams({ ...obj });
  };

  const searchDisablePriceRange = (): void => {
    if (typeof priceRange.min_price == 'number' && typeof priceRange.max_price == 'number') {
      const newPriceRange = [priceRange.min_price, priceRange.max_price];
      setPriceValue(newPriceRange);
      searchAfterDisable({
        price: [],
      });
    }
  };

  const searchDisableStates = (statesObject: { used?: boolean; new?: boolean }): void => {
    searchAfterDisable({
      used:
        (!statesObject.used && !statesObject.new) || (statesObject.used && statesObject.new)
          ? undefined
          : statesObject.used
          ? statesObject.used
          : false,
    });
  };

  const searchDisableLocations = (locationsObject: {}): void => {
    const regions = Object.values(locationsObject);

    searchAfterDisable({
      location_id: regions.length ? regions : undefined,
    });
  };

  const searchDisableFields = (fieldsObject: {}): void => {
    searchAfterDisable({
      category_fields: categoryFields(fieldsObject),
    });
  };

  const handleDisableHandmadeOption = () => {
    setIsHandmade(false);

    searchAfterDisable({
      handmade: false,
    });
  };

  const removeAllOptions = (): void => {
    if (typeof priceRange.min_price == 'number' && typeof priceRange.max_price == 'number') {
      setPriceValue([priceRange.min_price, priceRange.max_price]);
    }
    setCurrentPage(1);
    setPosts(undefined);
    setPostsStates({ new: false, used: false });
    setLocationsStates({});
    setFieldsStates({});
    setSortBy(FilterSortByVariants.NEWEST);
    setQuery('');
    setIsHandmade(false);

    if (UrlQuery) {
      navigateWithLocale(`/category/${category_id}`);
    } else {
      search({
        page: 1,
        category_id: categoryIds,
        sort_by: FilterSortByVariants.NEWEST,
      });
    }

    updateQueryParams({});
  };

  const isShowRemoveAllButton = (): boolean => {
    const thereIsPositiveOptions = (array: IFilterOption | IPostsStates) =>
      Object.entries(array).filter(state => Boolean(state[1])).length;

    return Boolean(
      query ||
        isHandmade ||
        isShowRangeChip ||
        thereIsPositiveOptions(postsStates) ||
        thereIsPositiveOptions(locationsStates) ||
        thereIsPositiveOptions(fieldsStates),
    );
  };

  const getLabelForChip = (key: string): string => {
    const labelMapping: Record<string, string> = {
      new: t('translation:filter.new.message'),
      used: t('translation:filter.used.message'),
    };

    return labelMapping[key] || key;
  };

  const filterOptionsArray = <V, T extends { [key: string]: V }>(
    state: T,
    setState: ActionCreatorWithPayload<T>,
    searchFunction: (obj: {}) => void,
  ) => {
    const disableFilterOption = (key: string): void => {
      const copy = { ...state };
      delete copy[key];
      setState(copy);
      searchFunction(copy);
    };

    return Object.entries(state).map(stateEntry => {
      const [key, value] = stateEntry;
      const label = getLabelForChip(key);

      return (
        <Chip
          key={key}
          text={label}
          isShow={Boolean(value)}
          disableOption={() => disableFilterOption(key)}
        />
      );
    });
  };

  const isAnyChipVisible =
    query ||
    isHandmade ||
    isShowRangeChip ||
    Object.values(postsStates).some(Boolean) ||
    Object.values(locationsStates).some(Boolean) ||
    Object.values(fieldsStates).some(Boolean);

  if (!isAnyChipVisible) {
    return null;
  }

  return (
    <div className="chips-container_frame">
      <Chip
        key="query"
        text={getLabelForChip(query)}
        isShow={Boolean(query)}
        disableOption={removeAllOptions}
      />

      <Chip
        key="handmade"
        text={t('translation:filter.handmade.message')}
        isShow={Boolean(isHandmade)}
        disableOption={handleDisableHandmadeOption}
      />

      <Chip
        text={`${priceValue && range[0]} - ${priceValue && range[1]}`}
        isShow={isShowRangeChip}
        disableOption={searchDisablePriceRange}
        priceIcon={<Icon type="gel" className="chip-price-icon" />}
      />

      {filterOptionsArray<boolean, IPostsStates>(postsStates, setPostsStates, searchDisableStates)}
      {filterOptionsArray<string, IFilterOption>(
        locationsStates,
        setLocationsStates,
        searchDisableLocations,
      )}
      {filterOptionsArray<string, IFilterOption>(
        fieldsStates,
        setFieldsStates,
        searchDisableFields,
      )}

      {isShowRemoveAllButton() && (
        <div className="clear-all-filters__container" onClick={removeAllOptions}>
          <p>{t('translation:filter.remove_all.message')}</p>
        </div>
      )}
    </div>
  );
};

export default ChipsContainer;
