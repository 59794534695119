import React, { FC, useEffect, useState } from 'react';
import arrowBackModal from '../../default_components/assets/icons/ArrowBackModal.svg';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import { useActions } from '../../../hooks/redux';
import { useSelector } from 'react-redux';
import '../authorization.scss';
import { t } from 'i18next';
import { apiProfile } from '../../../services/ApiProfile';
import { validateField } from '../../../lib/textFieldsValidators';
import { InputGamar, Loader, Snackbar } from '../../default_components';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';

interface ILoginProps {
  handleClose: () => void;
  setAuthType: (authType: string) => void;
}

const ForgotPassword: FC<ILoginProps> = ({ handleClose, setAuthType }) => {
  const [resetPassword, { isSuccess: isCodeSentSuccessfully, isLoading }] =
    apiProfile.useResetPasswordMutation();
  const [
    resetPasswordVerification,
    {
      isLoading: verificationLoading,
      isError: verificationError,
      error: verificationErrorMessage,
      isSuccess: verificationSuccess,
    },
  ] = apiProfile.useResetPasswordVerificationMutation();
  const [resetPasswordConfirmation, { isLoading: confirmLoading }] =
    apiProfile.useResetPasswordConfirmationMutation();

  const authData = useSelector((state: any) => state.authorizationReducer);
  const { saveEmail } = useActions();

  const [email, setEmail] = useState(authData.email);
  const [code, setCode] = useState<string>('');

  const [password, setPassword] = useState(authData.password);
  const [passwordError, setPasswordError] = useState<string>('');
  const [hideErrorPassword, setHideErrorPassword] = useState<boolean>(true);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    saveEmail(email);
  }, [email]);

  useEffect(() => {
    validateField(setPasswordError, passwordError, password, setHideErrorPassword, 'password', t);
  }, [password]);

  useEffect(() => {
    setPassword('')
  }, [isCodeSentSuccessfully]);

  useEffect(() => {
    const error = verificationErrorMessage;

    if (error && 'status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
      getActiveSnackbar(JSON.parse(errMsg).error, 'error');
    }
  }, [verificationError]);

  const moveTo = (page: string) => {
    setAuthType(page);
  };

  const handleClickOnConfirm = () => {
    if (!isCodeSentSuccessfully && email) {
      resetPassword(email);
    }
    if (isCodeSentSuccessfully && !verificationSuccess && code) {
      resetPasswordVerification({
        email,
        reset_token: code,
      });
    }
    if (isCodeSentSuccessfully && verificationSuccess && !passwordError) {
      resetPasswordConfirmation({
        email,
        reset_token: code,
        password: password,
        password_confirmation: password,
      }).then(() => {
        moveTo('LOGIN');
      });
    }
  };

  return (
    <React.Fragment>
      <div className="authorization__header pb-30">
        <div className="authorization__icon__back">
          <img alt="back" src={arrowBackModal} onClick={() => moveTo('LOGIN')} />
        </div>

        <div className="authorization__title">{t('translation:auth.forgot_password.message')}</div>

        <div className="authorization__icon__close">
          <img alt="close" src={crossSmallForModal} onClick={handleClose} />
        </div>
      </div>

      <div className="authorization__body with-back-icon">
        <form className="authorization__form">
          <div className="authorization__small-text mb-60">
            {t('translation:auth.forgot_password_text.message')}
          </div>

          {!isCodeSentSuccessfully && !verificationSuccess && (
            <InputGamar
              value={email}
              setValue={setEmail}
              label={t('translation:auth.email.message')}
              placeholder={'your_e@mail.com'}
              type="email"
            />
          )}

          {isCodeSentSuccessfully && !verificationSuccess && (
            <InputGamar
              value={code}
              setValue={setCode}
              label={t('translation:profile.modal.code_label.message')}
            />
          )}

          {isCodeSentSuccessfully && verificationSuccess && (
            <InputGamar
              value={password}
              setValue={setPassword}
              label={t('translation:auth.password.message')}
              placeholder={'password'}
              passwordType={true}
              showPlaceholder={true}
              error={passwordError}
              hideError={hideErrorPassword}
              setHideError={setHideErrorPassword}
              onBlur={() => setHideErrorPassword(false)}
            />
          )}

          <div className="authorization__button mt-80" onClick={handleClickOnConfirm}>
            {isLoading || verificationLoading || confirmLoading ? (
              <Loader isWhite />
            ) : (
              isCodeSentSuccessfully
                ? (!verificationSuccess
                  ? t('translation:auth.submit_code.message')
                  : t('translation:auth.reset_password.message'))
                : t('translation:auth.send_code.message')
            )}
          </div>
        </form>
      </div>

      <Snackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </React.Fragment>
  );
};

export default ForgotPassword;
