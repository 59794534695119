import React, { useEffect } from 'react';
import RatingStars from '../RatingStars/RatingStars';
import './ratingBars.scss';
import { ReviewType, Distribution } from '../../../../types/reviewsTypes';
import { rating_distribution } from '../../../../utils/calculate';

const RatingBars: React.FC<{ reviews: ReviewType[] | undefined }> = ({ reviews }) => {
  const [distribution, setDistribution] = React.useState<Distribution>({});
  const [totalReviews, setTotalReviews] = React.useState<number>(0);

  useEffect(() => {
    if (reviews) {
      const newDistribution: Distribution = rating_distribution(reviews);
      setDistribution(newDistribution);
      setTotalReviews(reviews.length);
    }
  }, [reviews]);

  const sortedDistribution = Object.entries(distribution).sort(
    ([starA], [starB]) => parseInt(starB) - parseInt(starA),
  );

  return (
    <div className="rating__charts">
      {sortedDistribution.map(([star, count]) => (
        <div key={star} className="row">
          <RatingStars rating={parseInt(star)} noDigit={true} starsSize="medium-small" />

          <div className="bar">
            <div className="line">
              <div className="line-fill" style={{ width: `${(count / totalReviews) * 100}%` }} />
            </div>
          </div>

          <span className="count">{count}</span>
        </div>
      ))}
    </div>
  );
};

export default RatingBars;
