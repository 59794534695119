import React, { FC, SetStateAction, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import ReactCrop from 'react-image-crop';
import { useCropFunction } from './CropFunction';
import CropSlider from './CropSlider';

interface IAvatarCrop {
  src: string;
  flag: boolean;
  fileName: string;
  imageRef: React.RefObject<HTMLImageElement>;
  setCroppedImage: React.Dispatch<SetStateAction<File | undefined>>;
}

const AvatarCrop: FC<IAvatarCrop> = ({ src, imageRef, fileName, flag, setCroppedImage }) => {
  const { crop } = useAppSelector(state => state.avatarReducer);
  const { setCrop } = useActions();

  const makeClientCrop = useCropFunction(imageRef, fileName, setCroppedImage);

  useEffect(() => {
    flag && setTimeout(() => makeClientCrop(crop), 100);
  }, [flag]);

  return (
    <div className="edit-avatar-square">
      <div className="cont-crop">
        <ReactCrop circularCrop keepSelection locked crop={crop} onChange={c => setCrop(c)}>
          <img src={src} className="avatar-image" ref={imageRef} alt="User Avatar" />
        </ReactCrop>
      </div>

      <div className="slider-crop">
        <CropSlider imageRef={imageRef} crop={crop} setCrop={setCrop} />
      </div>
    </div>
  );
};

export default AvatarCrop;
