import React, { FC, SetStateAction, useEffect, useState } from 'react';
import './password.scss';
import { apiProfile } from '../../../../services/ApiProfile';
import { t } from 'i18next';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import { ConfirmModal, SaveButton } from '..';
import { InputGamar, Rule, Snackbar } from '../../../default_components';
import { IUser } from '../../../../types';

interface IPassword {
  profileInfo: IUser | undefined;
  timeBeforeResendingCodeForPass: number;
  setTimeBeforeResendingCodeForPass: React.Dispatch<SetStateAction<number>>;
  setCurrentStep: React.Dispatch<SetStateAction<number>>;
}

enum PASS_ACTIONS {
  PASS_SEND_CODE = 'PASS_SEND_CODE',
  PASS_VERIFICATION_CODE = 'PASS_VERIFICATION_CODE',
  PASS_CONFIRM = 'PASS_CONFIRM',
}

const Password: FC<IPassword> = ({
  profileInfo,
  timeBeforeResendingCodeForPass,
  setTimeBeforeResendingCodeForPass,
  setCurrentStep,
}) => {
  const [resetPassword] = apiProfile.useResetPasswordMutation();
  const [
    resetPasswordVerification,
    {
      isLoading: verificationLoading,
      isError: verificationError,
      error: verificationErrorMessage,
      isSuccess: verificationSuccess,
    },
  ] = apiProfile.useResetPasswordVerificationMutation();
  const [
    resetPasswordConfirmation,
    {
      data: confirmAnswer,
      isLoading: confirmLoading,
      isError: confirmError,
      isSuccess: confirmSuccess,
    },
  ] = apiProfile.useResetPasswordConfirmationMutation();

  const [currentPass, setCurrentPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confirmPass, setConfirmPass] = useState<string>('');
  const [submittedPass, setSubmittedPass] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showRules, setShowRules] = useState<boolean>(false);
  const [showConfirmRule, setShowConfirmRule] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    validationPass(newPass) &&
    newPass === confirmPass &&
    currentPass.length > 7 &&
    currentPass !== newPass
      ? setCanSave(true)
      : setCanSave(false);
  }, [currentPass, newPass, confirmPass]);

  useEffect(() => {
    timeBeforeResendingCodeForPass > 0 &&
      setTimeout(setTimeBeforeResendingCodeForPass, 1000, timeBeforeResendingCodeForPass - 1);
  }, [timeBeforeResendingCodeForPass]);

  useEffect(() => {
    if (confirmSuccess && verificationSuccess) {
      getActiveSnackbar(confirmAnswer.message, 'success');
      setShowRules(false);
      setShowConfirmRule(false);
      setShowPopup(false);
      setNewPass('');
      setCurrentPass('');
      setConfirmPass('');
      setCode('');
    }
  }, [confirmSuccess]);

  useEffect(() => {
    verificationSuccess && actionWithPass(PASS_ACTIONS.PASS_CONFIRM);
  }, [verificationSuccess]);

  useEffect(() => {
    !showPopup && setCode('');
  }, [showPopup, code]);

  useEffect(() => {
    if (verificationErrorMessage && 'status' in verificationErrorMessage) {
      const errMsg =
        'error' in verificationErrorMessage
          ? verificationErrorMessage.error
          : JSON.stringify(verificationErrorMessage.data);

      getActiveSnackbar(JSON.parse(errMsg)?.error + ' ' + verificationErrorMessage.status, 'error');
      setErrorMessage(JSON.parse(errMsg).error);
    }
  }, [verificationError, confirmError]);

  const validationPass = (pass: string): boolean =>
    pass.length > 7 && /[a-zA-Z]/.test(pass) && /\d/.test(pass);

  const actionWithPass = (typeAction: string, newSend?: boolean) => {
    setShowPopup(true);

    if (profileInfo) {
      switch (typeAction) {
        case PASS_ACTIONS.PASS_SEND_CODE: {
          if (timeBeforeResendingCodeForPass === 0 || newSend) {
            resetPassword(profileInfo.email);
          }
          return;
        }
        case PASS_ACTIONS.PASS_VERIFICATION_CODE: {
          if (code?.length) {
            resetPasswordVerification({
              email: profileInfo.email,
              reset_token: code,
            });
          }
          return;
        }
        case PASS_ACTIONS.PASS_CONFIRM: {
          if (code?.length) {
            resetPasswordConfirmation({
              email: profileInfo.email,
              reset_token: code,
              password: newPass,
              password_confirmation: newPass,
            });
          }
          return;
        }
        default:
          return false;
      }
    }
  };

  const clickOnSave = () => {
    if (canSave) {
      if (validationPass(submittedPass)) {
        if (submittedPass === newPass) {
          setShowPopup(true);
        } else {
          timeBeforeResendingCodeForPass === 0 && setTimeBeforeResendingCodeForPass(60);
          setSubmittedPass(newPass);
          actionWithPass(PASS_ACTIONS.PASS_SEND_CODE, true);
        }
      } else {
        if (timeBeforeResendingCodeForPass === 0) {
          setTimeBeforeResendingCodeForPass(60);
        }
        setSubmittedPass(newPass);
        actionWithPass(PASS_ACTIONS.PASS_SEND_CODE);
      }
    }
  };

  const moveToEmailTab = (): void => {
    setCurrentStep(1);
  };

  if (!profileInfo?.email) {
    return (
      <div className="passwords_frame">
        <p className="absent-email">
          {t('translation:profile.password_page.absent_email.message')}
          <span onClick={moveToEmailTab}>
            {' '}
            {t('translation:profile.password_page.absent_email_span.message')}{' '}
          </span>
        </p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <form className="passwords_frame" autoComplete="off">
        <InputGamar
          value={currentPass}
          setValue={setCurrentPass}
          label={t('translation:profile.password_change.current_password_label.message')}
          placeholder={
            t('translation:profile.password_change.current_password_placeholder.message') ||
            'Enter your password'
          }
          passwordType
          showPlaceholder
        />

        <div className={`passwords_inputs_container ${showRules && 'show'}`}>
          <InputGamar
            onFocus={() => setShowRules(true)}
            value={newPass}
            setValue={setNewPass}
            label={t('translation:profile.password_change.new_password_label.message')}
            placeholder={
              t('translation:profile.password_change.new_password_placeholder.message') ||
              'At least 8 characters'
            }
            passwordType
            showPlaceholder={showRules}
          />

          <div className={`pass-rules-container ${showRules && 'show'}`}>
            <Rule
              successfully={newPass.length > 7}
              error={newPass.length < 8 && newPass.length > 0}
              label={t('translation:profile.password_change.rules.rule_1.message')}
            />

            <Rule
              successfully={/[a-zA-Z]/.test(newPass)}
              error={newPass.length > 0 && !/[a-zA-Z]/.test(newPass)}
              label={t('translation:profile.password_change.rules.rule_2.message')}
            />

            <Rule
              successfully={/\d/.test(newPass)}
              error={newPass.length > 0 && !/\d/.test(newPass)}
              label={t('translation:profile.password_change.rules.rule_3.message')}
            />
          </div>

          <div className="confirm-pass_frame">
            <InputGamar
              onFocus={() => setShowConfirmRule(true)}
              value={confirmPass}
              setValue={setConfirmPass}
              label={t('translation:profile.password_change.confirm_password_label.message')}
              passwordType
            />

            <div className={`confirm-pass-rule_container ${showConfirmRule && 'show'}`}>
              <Rule
                successfully={confirmPass.length > 0 && newPass === confirmPass}
                error={confirmPass.length > 0}
                label={t('translation:profile.password_change.rules.rule_4.message')}
              />
            </div>
          </div>
        </div>
      </form>

      <SaveButton isLoading={false} isCanSave={canSave} handleOnSave={clickOnSave} />

      <ConfirmModal
        open={showPopup}
        setOpen={setShowPopup}
        code={code}
        setCode={setCode}
        title={t('translation:profile.password_change.password_changing.message')}
        newValue={profileInfo?.email}
        fromPassword
        time={timeBeforeResendingCodeForPass}
        loading={verificationLoading || confirmLoading}
        isError={verificationError}
        errorMessage={errorMessage}
        sendAgain={() => {
          actionWithPass(PASS_ACTIONS.PASS_SEND_CODE);
          setTimeBeforeResendingCodeForPass(60);
        }}
        confirmAction={() => actionWithPass(PASS_ACTIONS.PASS_VERIFICATION_CODE)}
      />

      <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
    </React.Fragment>
  );
};

export default Password;
