import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FilterSortByValueType,
  IFilterOption,
  IPost,
  IPostsStates,
  IPriceRange,
  FilterSortByVariants,
} from '../../types';

interface IInitialState {
  posts: IPost[] | undefined;
  query: string;
  sortBy: FilterSortByValueType;
  priceRange: IPriceRange;
  priceValue: number | number[];
  postsStates: IPostsStates;
  locationsStates: IFilterOption;
  fieldsStates: IFilterOption;
  categoryIds: unknown | string[];
  pagesCount: number;
  currentPage: number;
  amountPostsFound: number;
  isHandmade?: boolean;
}

const initialState: IInitialState = {
  posts: [],
  sortBy: FilterSortByVariants.NEWEST,
  query: '',
  priceRange: {
    min_price: null,
    max_price: null,
  },
  priceValue: [],
  postsStates: { new: false, used: false },
  locationsStates: {},
  fieldsStates: {},
  categoryIds: [],
  pagesCount: 0,
  currentPage: 1,
  amountPostsFound: 0,
  isHandmade: undefined,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<IPost[] | undefined>) => {
      state.posts = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setSortBy: (state, action: PayloadAction<FilterSortByValueType>) => {
      state.sortBy = action.payload;
    },
    setPriceRange: (state, action: PayloadAction<IPriceRange>) => {
      state.priceRange = action.payload;
    },
    setPriceValue: (state, action: PayloadAction<number | number[]>) => {
      state.priceValue = action.payload;
    },
    setPostsStates: (state, action: PayloadAction<IPostsStates>) => {
      state.postsStates = action.payload;
    },
    setLocationsStates: (state, action: PayloadAction<IFilterOption>) => {
      state.locationsStates = action.payload;
    },
    setFieldsStates: (state, action: PayloadAction<IFilterOption>) => {
      state.fieldsStates = action.payload;
    },
    setCategoryIds: (state, action: PayloadAction<unknown | string[]>) => {
      state.categoryIds = action.payload;
    },
    setPagesCount: (state, action: PayloadAction<number>) => {
      state.pagesCount = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setAmountPostsFound: (state, action: PayloadAction<number>) => {
      state.amountPostsFound = action.payload;
    },
    setIsHandmade: (state, action: PayloadAction<boolean | undefined>) => {
      state.isHandmade = action.payload;
    },
  },
});

export const filterReducer = filterSlice.reducer;
export const filterAction = filterSlice.actions;
