import React, { FC, useEffect, useState } from 'react';
import './myPurchases.scss';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import NotPurchases from '../default_components/assets/icons/NotPurchases.svg';
import MyPurchaseCard from './myPurchaseCard/MyPurchaseCard';
import { api } from '../../services/Api';
import { CommonSortItemType, SortingMenu, sortVariants } from '../myPosts';
import { IPurchase, IExpandedPurchase } from '../../services/types';
import { t } from 'i18next';
import { IPost } from '../../types';
import { useGetProfileInfo } from '../../hooks/useGetProfileInfo';

const MyPurchases: FC = () => {
  const { profileInfo } = useGetProfileInfo();

  const { data: purchases, isSuccess: isGotPurchases } = api.useGetMyPurchasesQuery();
  const { data: posts, isSuccess: isGotPosts } = api.useGetAsBuyerPostsQuery();

  const [fetchReviews] = api.useLazyGetReviewsByPostQuery();

  const [myPurchases, setMyPurchases] = useState<IExpandedPurchase[]>([]);
  const [enableOptionWithReviews, setEnableOptionWithReviews] = useState<boolean>(false);
  const [enableOptionWithoutReviews, setEnableOptionWithoutReviews] = useState<boolean>(false);
  const [sort, setSort] = useState<CommonSortItemType<string>>(sortVariants[0]);

  const isShowPurchases = Boolean(myPurchases.length > 0);

  useEffect(() => {
    const fetchData = async () => {
      if (isGotPurchases && isGotPosts) {
        const purchasesWithPosts: IExpandedPurchase[] = await mapPurchasesToPosts(purchases, posts);
        setMyPurchases(purchasesWithPosts);
      }
    };

    fetchData();
  }, [isGotPurchases, isGotPosts, purchases, posts, profileInfo]);

  const mapPurchasesToPosts = async (purchases: IPurchase[], posts: IPost[]): Promise<IExpandedPurchase[]> => {
    const purchasesWithReviews: IExpandedPurchase[] = [];

    for (const purchase of purchases) {
      const post = posts.find((p: IPost) => p.id === purchase.post_id);

      if (!post) continue;

      const reviewsResponse = await fetchReviews(post.id);
      const reviews = reviewsResponse.data || [];
      const isReviewExist = Boolean(reviews.find(review => review.user.id === profileInfo?.id));

      purchasesWithReviews.push({ ...purchase, post, reviews, isReviewExist });
    }

    return purchasesWithReviews;
  };

  const handleGetPurchasesWithReviews = () => {
    setEnableOptionWithReviews(!enableOptionWithReviews);
  };

  const handleGetPurchasesWithoutReviews = () => {
    setEnableOptionWithoutReviews(!enableOptionWithoutReviews);
  };

  const filterPurchases = (purchases: IExpandedPurchase[]): IExpandedPurchase[] => {
    if (enableOptionWithReviews && !enableOptionWithoutReviews) {
      return purchases.filter((p) => p.isReviewExist);
    } else if (!enableOptionWithReviews && enableOptionWithoutReviews) {
      return purchases.filter((p) => !p.isReviewExist);
    }
    return purchases;
  };

  const sortPurchases = (purchases: IExpandedPurchase[]) => {
    switch (sort.value) {
      case 'new_first':
        return [...purchases].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      case 'old_first':
        return [...purchases].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      case 'price_low_first':
        return [...purchases].sort((a, b) => a.post.prices.gel - b.post.prices.gel);
      case 'price_high_first':
        return [...purchases].sort((a, b) => b.post.prices.gel - a.post.prices.gel);
      case 'alphabetical':
        return [...purchases].sort((a, b) => a.post.title.localeCompare(b.post.title));
      default:
        return purchases;
    }
  };

  const sortedAndFilteredPurchases = isShowPurchases
    ? filterPurchases(sortPurchases(myPurchases || []))
    : [];

  return (
    <React.Fragment>
      <Header />

      <div className="my-purchases-frame">
        <div className="my-purchases-header">
          <h2 className="title">{t('translation:my_purchases.title.message')}</h2>
          <p className="items-count">
            {isShowPurchases ? myPurchases.length : 0}&nbsp;
            {t('translation:my_purchases.items.message')}
          </p>
        </div>

        <div className="my-purchases-tools">
          <div className="chips">
            <button
              className={`${enableOptionWithReviews && "active"}`}
              onClick={handleGetPurchasesWithReviews}
            >
              {t('translation:my_purchases.with_reviews.message')}
            </button>

            <button
              className={`${enableOptionWithoutReviews && "active"}`}
              onClick={handleGetPurchasesWithoutReviews}
            >
              {t('translation:my_purchases.without_reviews.message')}
            </button>
          </div>

          <div className="sorting-tools">
            <div className="toggle-cards-view"></div>
            <SortingMenu assets={sortVariants} sort={sort} setSort={setSort} />
          </div>
        </div>

        {isShowPurchases
          ? (
            <section className="my-purchases">
              {sortedAndFilteredPurchases.map(p => <MyPurchaseCard purchase={p} />)}
            </section>
          ) : (
            <div className="my-purchases-blank-slate">
              <img src={NotPurchases} />
              <p>{t('translation:my_purchases.not_any_purchases.message')}<br />
                {t('translation:my_purchases.bought_be_here.message')}
              </p>
            </div>
          )
        }
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default MyPurchases;
