import { localStorageKeys } from "../../routes";

export const setCurrency = (
  currency: string, updateProfileCurrency: (currency: string) => Promise<unknown>
): void => {
  const token: string | null = localStorage.getItem(localStorageKeys.token);
  localStorage.setItem(localStorageKeys.currency, currency);

  token
    ? updateProfileCurrency(currency).then((res) => res && window.location.reload())
    : window.location.reload();
};
