import React, { useEffect, useState, useCallback } from 'react';
import { api } from '../../../services/Api';
import { useParams } from 'react-router-dom';
import './userPosts.scss';
import { PostCard, Skeletons } from '../../default_components';
import { IPost } from '../../../types';
import { Pagination } from '@mui/material';
import { PaginationStyle } from '../../myFavoritePosts/PaginationStyle';
import CategoryRow from './CategoryRow/CategoryRow';
import { useSelector } from 'react-redux';

const UserPosts = () => {
  const { userId } = useParams();
  const { activeLocale } = useSelector((state: any) => state.authReducer);

  const [search, { data: searchData, isLoading: isSearchPostsLoading }] =
    api.useSearchByQueryMutation();

  const [posts, setPosts] = useState<IPost[] | undefined>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [numberPage, setNumberPage] = useState<number>(1);

  useEffect(() => {
    const searchParams = {
      user_id: userId,
      page: numberPage,
      ...(selectedCategories.length >= 1 && { category_id: selectedCategories }),
    };

    search(searchParams)
      .unwrap()
      .then(result => {
        setPosts(result.posts.filter(post => post.status === 'active'));
      });
  }, [selectedCategories, numberPage, activeLocale, search]);

  const handlePostsByCategory = useCallback((id: string) => {
    setSelectedCategories(prevCategories =>
      prevCategories.includes(id)
        ? prevCategories.filter(categoryId => categoryId !== id)
        : [...prevCategories, id],
    );
    setNumberPage(1);
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setNumberPage(value);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'user-posts__container'}>
      <CategoryRow
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        onClick={handlePostsByCategory}
        posts={posts}
      />

      <div className={'user-posts__posts-container'}>
        {isSearchPostsLoading &&
          Array(10)
            .fill(0)
            .map((_, index) => <Skeletons.SkeletonPostCard key={index} />)}

        {posts?.map(post => (
          <PostCard post={post} />
        ))}
      </div>

      {searchData && searchData?.pages_count > 1 && posts && posts?.length > 0 && (
        <Pagination
          count={searchData.pages_count}
          size="large"
          page={numberPage}
          variant="text"
          shape="rounded"
          onChange={handleChange}
          sx={PaginationStyle}
        />
      )}
    </div>
  );
};

export default UserPosts;
