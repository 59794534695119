import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IconType } from "../../components/default_components/Icon/IconType";
import { IUser } from "../../types";

interface IOptions {
  value: string;
  label: string;
  short_label?: string;
  whiteIcon?: IconType;
  coinIcon?: IconType;
  currencySymbol?: string;
}

const CURRENCY_OPTIONS: IOptions[] = [
  { value: 'gel', label: 'GEL', currencySymbol: "₾", whiteIcon: "whiteGel", coinIcon: "whiteCoinGel" },
  { value: 'usd', label: 'USD', currencySymbol: "$", whiteIcon: "whiteUsd", coinIcon: "whiteCoinUsd" },
];

const LOCALE_OPTIONS: IOptions[] = [
  { value: 'en', label: 'English', short_label: "ENG" },
  { value: 'ka', label: 'Georgian', short_label: "GEO" },
  { value: 'ru', label: 'Russian', short_label: "RUS" },
];

interface IInitialState {
  isAuthorized: boolean;
  activeLocale: IOptions;
  activeCurrency: IOptions;
  profileInfo: IUser | undefined;
}

const initialState: IInitialState = {
  isAuthorized: false,
  activeLocale: LOCALE_OPTIONS[0],
  activeCurrency: CURRENCY_OPTIONS[1],
  profileInfo: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthorized: (state, action: PayloadAction<boolean>) => {
     state.isAuthorized = action.payload
    },
    setActiveLocale: (state, action: PayloadAction<string>) => {
      const options = LOCALE_OPTIONS.find(option => option.value === action.payload);

      if (options) {
        state.activeLocale = options;
      }
    },
    setActiveCurrency: (state, action: PayloadAction<string>) => {
      const options = CURRENCY_OPTIONS.find(option => option.value === action.payload);

      if (options) {
        state.activeCurrency = options;
      }
    },
    setProfileInfo: (state, action: PayloadAction<IUser | undefined>) => {
      state.profileInfo = action.payload
    },
  }
});

export const authReducer = authSlice.reducer;
export const authAction = authSlice.actions;
export {
  IOptions, CURRENCY_OPTIONS, LOCALE_OPTIONS
}
