import React, { FC } from 'react';
import { Snackbar, Alert } from "@mui/material";

interface IDefaultSnackbar {
  open: boolean;
  close: () => void;
  message: string;
  severity: ISeveritySnackbar;
}

export type ISeveritySnackbar = 'error' | 'warning' | 'info' | 'success';

const DefaultSnackbar: FC<IDefaultSnackbar> = ({ open, close, message, severity }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={1500}
      onClose={close}
    >
      <Alert onClose={close} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default DefaultSnackbar;
