import React from 'react';
import { api } from "../../services/Api";
import { PostActionsPage } from "./index";

const CreationRoute = () => {
  const [sendPost, { data, isLoading, isError, error }] = api.useSendPostMutation();

  return (
    <PostActionsPage
      sendPost={sendPost}
      data={data}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
};

export default CreationRoute;
