import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import '../postSwiper.scss';
import './postZoomSwiper.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import { IPost } from '../../../../../types';
import { Icon } from '../../../../default_components';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { t } from 'i18next';
import { getPostPrice } from '../../../../../utils/get';
import { useAppSelector } from '../../../../../hooks/redux';

interface IPostZoomSwiper {
  post?: IPost;
  images?: string[];
  isShow: boolean;
  currentSlideNumber?: number;
  setIsShow: Dispatch<SetStateAction<boolean>>;
}

const PostZoomSwiper: FC<IPostZoomSwiper> = ({
  post,
  images,
  currentSlideNumber,
  isShow,
  setIsShow,
}) => {
  const { activeCurrency } = useAppSelector(state => state.authReducer);
  const swiperRef = useRef<SwiperRef>(null);

  const [currentSlide, setCurrentSlide] = useState<number>(currentSlideNumber ?? 1);

  useEffect(() => {
    if (currentSlideNumber) {
      setCurrentSlide(currentSlideNumber);
    }
  }, [currentSlideNumber]);

  useEffect(() => {
    if (swiperRef.current && currentSlideNumber) {
      swiperRef.current.swiper.slideTo(currentSlideNumber - 1);
    }
  }, [currentSlideNumber]);

  const onSlideChange = () => {
    if (swiperRef.current) {
      setCurrentSlide(swiperRef.current.swiper.realIndex + 1);
    }
  };

  const pagination = {
    el: '.zoom-swiper__custom-pagination',
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
    clickable: true,
  };

  const sliderImages =
    images || (post && [...post.images_variants].reverse().map(image => image.original)) || [];

  const ZoomSwiperHeader = () => {
    return (
      <div className="zoom-swiper__header">
        <div className="zoom-swiper__header_counter">
          <span>{`${currentSlide}/${sliderImages.length}`}</span>
        </div>

        {post && (
          <div className="zoom-swiper__header__title">
            <span className="zoom-swiper__header__title_price">
              {post.price === null
                ? t('translation:product_page.discussed.message')
                : post.price == 0.0
                ? t('translation:product_page.free.message')
                : getPostPrice(post, activeCurrency)}
            </span>
            <span className="zoom-swiper__header__title_text">
              {(post.user_location?.name ? post.user_location?.name + ' ● ' : '') + post.title}
            </span>
          </div>
        )}

        <div className="zoom-swiper__header_button-close" onClick={() => setIsShow(false)}>
          <Icon type="whiteCrossSmall" />
        </div>
      </div>
    );
  };

  return (
    <div className={`zoom-swiper__modal-container ${isShow && 'show'}`}>
      <div className="zoom-swiper__wrapper">
        {ZoomSwiperHeader()}

        <div className="zoom-swiper__container">
          <Swiper
            ref={swiperRef}
            onSnapIndexChange={index => setCurrentSlide(index.activeIndex + 1)}
            navigation={{
              enabled: true,
              nextEl: '.button-slide-next__zoom-swiper',
              prevEl: '.button-slide-prev__zoom-swiper',
            }}
            pagination={pagination}
            modules={[Navigation, Pagination]}
            onSlideChange={onSlideChange}
            className="mySwiper"
          >
            {sliderImages.map((image, id) => (
              <SwiperSlide className="zoom-swiper-slide" key={id}>
                <img className="zoom-swiper-image" src={image} alt="icon" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <button disabled={false} className="button-slide-prev__zoom-swiper" type="button">
          <Icon type="arrowVertical" />
        </button>

        <button disabled={false} className="button-slide-next__zoom-swiper" type="button">
          <Icon type="arrowVertical" />
        </button>

        <div className="zoom-swiper__custom-pagination" />
      </div>
    </div>
  );
};

export default PostZoomSwiper;
