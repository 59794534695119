import React, { useEffect, useState } from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import './postingRules.scss';
import { localStorageKeys } from '../../../routes';
import ru from './translations/ru.json';
import en from './translations/en.json';
import ka from './translations/ka.json';

const locales = { ru, en, ka };

const PostingRules = () => {
  const locale = (localStorage.getItem(localStorageKeys.locale) || 'ka') as keyof typeof locales;
  const [translation, setTranslation] = useState(locales[locale]);

  useEffect(() => {
    setTranslation(locales[locale] || ka);
  }, [locale]);

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე" />
        <meta
          property="og:description"
          content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."
        />
        <meta
          property="og:image"
          content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"
        />
        <meta property="og:url" content="https://gamarmarket.ge" />

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე" />
        <meta
          name="twitter:description"
          content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."
        />
        <meta name="twitter:url" content="https://gamarmarket.ge" />
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია." />
      </Helmet>
      <Header />

      <div className="static-page__frame">
        <div className="static-page__title-container">
          <h1>{t('translation:footer.posting_rules.message')}</h1>
        </div>

        <hr className="divider" />

        <p className="posting-rules">
          {Object.entries(translation).map(([title, content]) => (
            <div key={title} className="posting-rules__rule-box">
              <h2 className="posting-rules__rule-title">{title}</h2>
              {Object.entries(content as Record<string, string[]>).map(([subtitle, items]) => (
                <div key={subtitle}>
                  <h3 className="posting-rules__rule-subtitle">{subtitle}</h3>
                  <ul>
                    {items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <hr className="hr" />
                </div>
              ))}
            </div>
          ))}
        </p>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default PostingRules;
