import { ReviewType } from '../types/reviewsTypes';
import { t } from 'i18next';

const to_stars = (rating?: number): string[] => {
  if (!rating) return new Array(5).fill('GrayEmpty');

  const roundedRating: number = Math.round(rating * 2) / 2;
  const fullStars: number = Math.floor(roundedRating);
  const halfStar: 1 | 0 = roundedRating % 1 ? 1 : 0;
  const emptyStars: number = 5 - fullStars - halfStar;

  return new Array(fullStars)
    .fill('RedFull')
    .concat(halfStar ? ['RedHalf'] : [])
    .concat(new Array(emptyStars).fill('GrayEmpty'));
};

const to_date = (date: string | undefined, format: string = 'long'): string => {
  if (!date) return '';

  const dateObj = new Date(date);
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();

  const monthName = t(`translation:default.months.${monthIndex}`);

  if (format === 'long') {
    return `${monthName} ${year}`;
  } else if (format === 'short') {
    return `${monthIndex + 1}/${year}`;
  } else if (format === 'inverse short') {
    return `${year} ${monthName.toLowerCase()}`;
  }

  return '';
};

const to_rating_digit = (reviews: ReviewType[]): number => {
  if (!reviews || reviews.length === 0) return 0.0;

  const totalRating: number = reviews.reduce(
    (acc: number, review: ReviewType) => acc + review.rating,
    0,
  );
  const averageRating: number = totalRating / reviews.length;

  return parseFloat((Math.round(averageRating * 100) / 100).toFixed(1));
};

const to_rating_status = (rating?: number): string => {
  if (!rating) return 'none';

  const statuses: Array<Record<any, any>> = [
    { max: 0, status: 'none' },
    { max: 0.75, status: 'terrible' },
    { max: 1.25, status: 'very_poor' },
    { max: 1.75, status: 'poor' },
    { max: 2.25, status: 'fair' },
    { max: 2.75, status: 'average' },
    { max: 3.25, status: 'good' },
    { max: 3.75, status: 'very_good' },
    { max: 4.25, status: 'great' },
    { max: 4.75, status: 'excellent' },
    { max: 5.0, status: 'brilliant' },
  ];

  const status = statuses.find(s => rating <= s.max);
  return status ? status.status : 'none';
};

const to_short_text = (text?: string, size: number = 50, dots: boolean = true): string => {
  if (!text) return '';

  return text.length > size ? text.slice(0, size) + (dots ? '...' : '') : text;
};

const to_initials_from_name = (name: string): string => {
  const names: string[] = name.split(' ');

  return names
    .map((n: string) => n[0].toUpperCase())
    .join('')
    .slice(0, 2);
};

const to_avatar_from_name = (name: string): string => {
  const initials = to_initials_from_name(name);
  const svg = `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="100%" height="100%" fill="#ffffff"/>
    <text x="50%" y="50%" font-family="Arial" font-size="30" fill="#dd2006" text-anchor="middle" alignment-baseline="central">${initials}</text>
  </svg>`;

  const base64 = btoa(unescape(encodeURIComponent(svg)));

  return `data:image/svg+xml;base64,${base64}`;
};

export {
  to_stars,
  to_date,
  to_rating_digit,
  to_rating_status,
  to_short_text,
  to_initials_from_name,
  to_avatar_from_name,
};
