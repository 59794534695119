import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination } from 'swiper';
import { IPostImageVariants } from '../../../../types';
import './postCardCarousel.scss';
import { isMobile } from '../../../../style';

const PostCardCarousel = ({
  images,
  isFilter,
}: {
  images: IPostImageVariants[];
  isFilter?: boolean;
}) => {
  return (
    <div className={'post-card-carousel'}>
      {
        isMobile && (
          <div className="unique-post-swiper">
            <div className="swiper-slide">
              <img src={images[0].large} alt="Post image" />
            </div>
          </div>
        ) ||
          <Swiper
            loop
            centeredSlides={true}
            spaceBetween={50}
            slidesPerView={1}
            navigation={images.length > 1}
            pagination={{ clickable: true }}
            className="unique-post-swiper"
            modules={[Navigation, Pagination]}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image.large} alt={`Post image number ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
      }
    </div>
  );
};

export default PostCardCarousel;
