import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './locales/en/translation.json';
import translationGeorgian from './locales/ka/translation.json';
import translationRussian from './locales/ru/translation.json';

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEnglish,
      },
      ka: {
        translation: translationGeorgian,
      },
      ru: {
        translation: translationRussian,
      },
    },
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(e => console.log(e));
