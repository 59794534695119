import React, { ChangeEvent, FC, useState } from 'react';
import { Icon, Skeletons } from '../../../default_components';
import './imageCard.scss';

interface IImageCard {
  url: string;
  isLast?: boolean;
  removeCard: () => void;
  selectUrlToEdit: () => void;
  editCard: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageCard: FC<IImageCard> = React.memo(
  ({ url, isLast, removeCard, selectUrlToEdit, editCard }) => {
    const className = isLast ? 'image-card last' : 'image-card';

    const [downloadedImage, setDownloadedImage] = useState<boolean>(false);
    const uploadImage = (e: ChangeEvent<HTMLInputElement>) => e.target.files && editCard(e);

    return (
      <div className={className}>
        {!downloadedImage && <Skeletons.SkeletonSmallPicture />}
        <img src={url} alt="Product Image" onLoad={() => setDownloadedImage(true)} />

        <div className="overlay">
          <label htmlFor="edit_card">
            <Icon type="pencil" onClick={selectUrlToEdit} />
          </label>

          <input
            accept=".jpg,.jpeg,.png"
            id="edit_card"
            type="file"
            hidden
            onChange={uploadImage}
          />

          <Icon type="deleteType" onClick={removeCard} />
        </div>
      </div>
    );
  },
);

export default ImageCard;
