export const SkeletonCategoryButtons = {
  width: 120,
  height: 28,
  borderRadius: 1,
};

/*** category card ***/
export const SkeletonCategoryCardText = {
  width: '70%',
  height: 28,
  margin: '0 auto',
};

export const SkeletonCategoryCardImage = {
  width: '100%',
  height: '100%',
};

/*** post card ***/
export const SkeletonPostCardImage = {
  width: '100%',
  height: '100%',
  minWidth: '220px',
  minHeight: '220px',
  borderRadius: '10px',
};

export const SkeletonPostCardPrice = {
  width: '40%',
  height: 25,
};

export const SkeletonPostCardState = {
  width: '20%',
  height: 25,
};

export const SkeletonPostCardTitle = {
  width: '100%',
  height: 20,
};

/*** below styles of skeletons for creation page ***/
export const SkeletonSmallPicture = {
  width: 100,
  height: 700,
  borderRadius: '10px',
};

/*** below styles of skeletons for product page ***/
export const SkeletonPostCategory = {
  width: 220,
  height: 27,
};

export const SkeletonPostTitle = {
  width: 300,
  height: 33.6,
};

export const SkeletonPostPrice = {
  width: 200,
  height: 38,
};

export const SkeletonPostLocation = {
  width: 100,
  height: '22px',
};

export const SkeletonPostAvatarCircle = {
  width: 60,
  height: 60,
  borderRadius: 1000,
};

export const SkeletonPostAvatarName = {
  width: 100,
  height: 27,

  '@media(max-width: 500px)': {
    display: 'none',
  },
};

export const SkeletonMessageCallButton = {
  width: 100,
  height: 40,
  borderRadius: '10px',

  '@media(max-width: 500px)': {
    width: 90,
  },
};

export const SkeletonPostSlider = {
  width: 460,
  height: 308,
  borderRadius: '10px',
};

export const SkeletonPostThumb = {
  width: 100,
  height: 70,
  borderRadius: '10px',
};

export const SkeletonPostDots = {
  width: 100,
  height: 7,
  borderRadius: '5px',
  margin: '0 auto',
};

export const SkeletonPostDescription = {
  maxWidth: 500,
  width: '100%',
  height: 76,
};

export const SkeletonPhoneNumber = {
  width: 200,
  height: 33,
};

export const SkeletonGoogleMaps = {
  height: 300,
  borderRadius: '10px',
};

export const SkeletonUserAvatar = {
  width: 200,
  height: 200,
  borderRadius: 1000,

  '@media(max-width: 500px)': {
    width: 140,
    height: 140,
  },
};

export const Skeleton__PublicProfile__Username = {
  width: 200,
  height: 33,
};

export const Skeleton__PublicProfile__Location = {
  width: 50,
  height: 33,
};

export const SkeletonSubscribe = {
  width: 160,
  height: 40,

  '@media(max-width: 500px)': {
    width: 90,
    height: 30,
  },
};

export const SkeletonSmallBtn = {
  width: 40,
  height: 40,

  '@media(max-width: 500px)': {
    width: 30,
    height: 30,
  },
};

export const SkeletonDate = {
  width: 250,
  height: 18,

  '@media(max-width: 500px)': {
    width: 200,
  },
};

export const SkeletonRatingDigits = {
  width: 40,
  height: 30,
};

export const SkeletonRatingStars = {
  width: 150,
  height: 30,
};

export const SkeletonReviewLink = {
  width: 50,
  height: 30,
};
