import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAuthorization {
  name?: string;
  phone?: number | null;
  email?: string;
  password?: string;
  otp?: string;
  otpTimeLeft: any;
}

const initialState: IAuthorization = {
  name: '',
  phone: null,
  email: '',
  password: '',
  otp: '',
  otpTimeLeft: localStorage.getItem('otpTimeLeft'),
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    saveName(state, action: PayloadAction<any>) {
      state.name = action.payload;
    },
    savePhone(state, action: PayloadAction<any>) {
      state.phone = action.payload;
    },
    saveEmail(state, action: PayloadAction<any>) {
      state.email = action.payload;
    },
    savePassword(state, action: PayloadAction<any>) {
      state.password = action.payload;
    },
    saveOtp(state, action: PayloadAction<any>) {
      state.otp = action.payload;
    },

    startOtpTimer(state) {
      localStorage.removeItem('otpTimeLeft');
      localStorage.setItem('otpTimeLeft', '30');
      state.otpTimeLeft = localStorage.getItem('otpTimeLeft');
    },
    updateOtpTimer(state, action: PayloadAction<any>) {
      state.otpTimeLeft = action.payload;
    },
    resetOtpTimeLeft(state) {
      localStorage.removeItem('otpTimeLeft');
      localStorage.setItem('otpTimeLeft', '30');
      state.otpTimeLeft = localStorage.getItem('otpTimeLeft');
    },
  },
});

export const authorizationReducer = authorizationSlice.reducer;
export const authorizationAction = authorizationSlice.actions;
