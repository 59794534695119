import React from 'react';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta property="og:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta property="og:image" content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"/>
        <meta property="og:url" content="https://gamarmarket.ge"/>

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta name="twitter:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta name="twitter:url" content="https://gamarmarket.ge"/>
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია."/>
      </Helmet>
      <Header />

      <div className="static-page__frame">
        <div className="static-page__title-container">
          <h1>{t('translation:footer.privacy_policy.message')}</h1>
        </div>

        <hr className="divider" />

        <p className="static-page__content">
          {t('translation:privacy_policy.text_1.message')}
          <hr className="hr" />
          <li>{t('translation:privacy_policy.text_2.message')}</li>
          <hr className="hr" />
          <li>{t('translation:privacy_policy.text_3.message')}</li>
          <hr className="hr" />
          <li>{t('translation:privacy_policy.text_4.message')}</li>
          <hr className="hr" />
          <li> {t('translation:privacy_policy.text_5.message')}</li>
          <hr className="hr" />
          <li>{t('translation:privacy_policy.text_6.message')}</li>
          <hr className="hr" />
          <li> {t('translation:privacy_policy.text_7.message')}</li>
          <hr className="hr" />
          <li> {t('translation:privacy_policy.text_8.message')}</li>
          <hr className="hr" />
          <li> {t('translation:privacy_policy.text_9.message')}</li>
        </p>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
