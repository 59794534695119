import React, { FC, useEffect, useState } from 'react';
import './personalInfo.scss';
import { api } from '../../../../services/Api';
import { apiProfile } from '../../../../services/ApiProfile';
import { t } from 'i18next';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import { ProfileAvatar, SaveButton } from '..';
import { AutocompleteSelect, InputGamar, Snackbar } from '../../../default_components';
import { ILocation, IUser } from '../../../../types';
import { validateField } from '../../../../lib/textFieldsValidators';

interface IPersonalInfo {
  profileInfo: IUser | undefined;
}

const PersonalInfo: FC<IPersonalInfo> = ({ profileInfo }) => {
  const [updateProfileInfo, { isLoading, isError, error, isSuccess }] =
    apiProfile.useUpdateProfileInfoMutation();
  const { data: locations } = api.useGetLocationsQuery();

  const [isCanSave, setIsCanSave] = useState<boolean>(false);

  const [avatar, setAvatar] = useState<string | undefined>();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string>('');
  const [hideErrorName, setHideErrorName] = useState<boolean>(true);

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [hideErrorPhone, setHideErrorPhone] = useState<boolean>(true);

  const [location, setLocation] = useState<ILocation | undefined>();

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    if (profileInfo) {
      profileInfo.username && setName(profileInfo.username);
      profileInfo.phone && setPhone(profileInfo.phone);
      profileInfo.location && setLocation(profileInfo.location);
      profileInfo.avatar ? setAvatar(profileInfo.avatar) : setAvatar(undefined);
    }
  }, [profileInfo]);

  useEffect(() => {
    isSuccess && !isLoading && setIsCanSave(false);
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      getActiveSnackbar(t('translation:profile.personal_info_change.success.message'), 'success');
    } else if (error && 'status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
      getActiveSnackbar(JSON.parse(errMsg)?.message + ' ' + error.status, 'error');
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    validateField(setNameError, nameError, name, setHideErrorName, 'name', t);
    validateField(setPhoneError, phoneError, phone, setHideErrorPhone, 'phone', t);
  }, [name, phone]);

  useEffect(() => {
    if (nameChanged() || locationChanged() || phoneChanged()) {
      if (!nameError && !phoneError) {
        setIsCanSave(true);
      } else {
        setIsCanSave(false);
      }
    } else {
      setIsCanSave(false);
    }
  }, [nameError, location, phoneError, name, phone]);

  const nameChanged = (): boolean => Boolean(name && name != profileInfo?.username);
  const locationChanged = (): boolean => Boolean(location && location != profileInfo?.location);
  const phoneChanged = (): boolean => Boolean(phone.length > 7 && phone != profileInfo?.phone);

  const phoneMask = (value: string) => {
    if (value[0] === '+') {
      return value.replace(/(\d{3})(\d)/, '$1 $2').replace(/(\d{3})(\d)/, '$1 $2');
    } else {
      return value.replace(/(\d{3})(\d)/, '+$1 $2').replace(/(\d{3})(\d)/, '+$1 $2');
    }
  };

  const clickOnSave = (): void => {
    isCanSave &&
      updateProfileInfo({
        username: name,
        phone: phone,
        location_id: location?.id,
      });
  };

  return (
    <React.Fragment>
      <div className="personal-info_frame">
        <ProfileAvatar name={name} avatar={avatar} profileInfo={profileInfo} />

        <div className="personal-info_inputs_container">
          <InputGamar
            value={name}
            setValue={setName}
            label={t('translation:auth.name.message')}
            placeholder={'John Doe'}
            type="name"
            error={nameError}
            hideError={hideErrorName}
            setHideError={setHideErrorName}
            onBlur={() => setHideErrorName(false)}
          />

          {locations && (
            <AutocompleteSelect
              assets={locations}
              selectedItem={location}
              setSelectedItem={setLocation}
              title={t('translation:profile.personal_info_change.location_label.message')}
              placeholder={
                t('translation:profile.personal_info_change.location_placeholder.message') ||
                'Tbilisi'
              }
            />
          )}
        </div>

        <InputGamar
          value={phone && phoneMask(phone)}
          setValue={setPhone}
          label={t('translation:auth.phone.message')}
          placeholder={'+995 555 549419'}
          type="phone"
          maxLength={16}
          error={phoneError}
          hideError={hideErrorPhone}
          setHideError={setHideErrorPhone}
          onBlur={() => setHideErrorPhone(false)}
        />
      </div>

      <SaveButton isLoading={isLoading} isCanSave={isCanSave} handleOnSave={clickOnSave} />

      <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
    </React.Fragment>
  );
};

export default PersonalInfo;
