import React, { Dispatch, FC, SetStateAction } from 'react';
import './catalogMobile.scss';
import { ICategory } from '../../../../types';
import CatalogMobileDrop from './catalogMobileDrop/CatalogMobileDrop';
import crossSmallForModal from '../../../default_components/assets/icons/CrossSmallForModal.svg';
import { t } from 'i18next';

interface ICatalogMobile {
  categories: ICategory[];
  selectedCategory: ICategory | undefined;
  setSelectedCategory: Dispatch<SetStateAction<ICategory | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CatalogMobile: FC<ICatalogMobile> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  setOpen,
}) => {
  return (
    <div className="catalog-mobile_frame">
      <div className={`catalog-mobile_categories-container ${selectedCategory && 'move-left'}`}>
        <button
          type="button"
          className="catalog-navigation-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <span className="catalog-mobile_categories-button_title">
            {t('translation:header.catalog.message')}
          </span>
          <img alt="closeIcon" style={{ color: '#000000' }} src={crossSmallForModal} />
        </button>

        {categories.map((category, key) => (
          <div className="category-image_frame" key={key}>
            <div className="category-image_container" onClick={() => setSelectedCategory(category)}>
              {category.image && <img src={category.image} alt={category.image} />}

              <div className="category-image_title-container">
                <p className="category-image_title">{category.name}</p>
              </div>
            </div>

            <CatalogMobileDrop
              category={category}
              setCategory={setSelectedCategory}
              isShow={selectedCategory?.id == category.id}
              setOpen={setOpen}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogMobile;
