import React from 'react';
import './blogFeedCard.scss';

const BlogFeedCard = ({ item }: { item: any }) => {
  const contentMatch = item.content.match(/<img.*?src=["'](.*?)["']/);
  const backgroundImage = contentMatch ? contentMatch[1] : '';

  const descriptionMatch = item.description.match(/<p>(.*?)<\/p>/);
  const description = descriptionMatch ? descriptionMatch[1] : '';
  const shortDescription =
    description.length > 100 ? `${description.substring(0, 100)}...` : description;

  return (
    <a href={item.link} target="_blank" rel="noopener noreferrer" className="blog-feed-card">
      <img className="blog-feed-card-image" src={backgroundImage} alt="Blog Post Image" />
      <div className="blog-feed-card-content">
        <p className="blog-feed-card-date">{new Date(item.pubDate).toLocaleDateString()}</p>
        <h3 className="blog-feed-card-title">{item.title}</h3>

        <p className="blog-feed-card-description">{shortDescription}</p>
      </div>
    </a>
  );
};

export default BlogFeedCard;
