import React, { useEffect, useState } from 'react';
import '../StaticPages.scss';
import Header from '../../header/Header';
import { t } from 'i18next';
import Footer from '../../footer/Footer';
import { localStorageKeys } from '../../../routes';
import { Helmet } from 'react-helmet';

const About = () => {
  const [lineHeight, setLineHeight] = useState<number>(12);

  useEffect(() => {
    const locale: string | 'en' | null = localStorage.getItem(localStorageKeys.locale);
    setLineHeight(locale === 'ka' ? 44 : 30);

    const token: string | null = localStorage.getItem(localStorageKeys.token);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta property="og:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta property="og:image" content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"/>
        <meta property="og:url" content="https://gamarmarket.ge"/>

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta name="twitter:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta name="twitter:url" content="https://gamarmarket.ge"/>
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია."/>
      </Helmet>
      <Header />

      <div className="static-page__frame">
        <div className="static-page__title-container">
          <h1>{t('translation:footer.about.message')}</h1>
        </div>

        <hr className="divider" />

        <p
          className="static-page__content"
          style={{
            lineHeight: `${lineHeight}px`,
          }}
        >
          {t('translation:about.text_1.message')}
          <hr className="hr" />
          {t('translation:about.text_2.message')}
          <hr className="hr" />
          {t('translation:about.text_3.message')}
          <hr className="hr" />
          {t('translation:about.text_4.message')}
          <hr className="hr" />
          {t('translation:about.text_5.message')}
          <hr className="hr" />
          {t('translation:about.text_6.message')}
          <hr className="hr" />
          {t('translation:about.text_7.message')}
          <hr className="hr" />
        </p>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default About;
