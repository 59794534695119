export const messageInputStyle = {
  width: '90%',
  caretColor: "#446BF2",

  '.MuiInputBase-root': {
    fontFamily: 'Noto Sans, sans serif !important',
    fontSize: '14px !important',

    color: '#110B11 !important',
    padding: "20px",
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar': {
    width: '5px !important',
  },

  '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input::-webkit-scrollbar-thumb': {
    backgroundColor: '#f2f2f2 !important',
    border: '3px solid #f2f2f2 !important',
  }
};
