import { ICategory } from "../../types";

const getAllCategoryIds = (categories: ICategory[], category_id: string): string[] | void => {
  const category = categories.find(category => category.id === category_id);
  if (category) {
    return getAllChildrenCategory(category).map(c => c.id)
  }
};

const getAllChildrenCategory = (category: ICategory) => {
  if (category.children.length) {
    return [category, ...category.children]
  }
  return [category]
};

export { getAllCategoryIds, getAllChildrenCategory };
