import React, { FC, SetStateAction } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { Icon } from '../../../default_components';
import { Box, SwipeableDrawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import UsersButton from './UsersButton';
import { IconType } from '../../../default_components/Icon/IconType';
import LocaleCurrencyButton from './LocaleCurrencyButton';
import { CURRENCY_OPTIONS, LOCALE_OPTIONS } from '../../../../store/reducers/auth.slice';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface IUsersDrawer {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  handleLogOut: () => void;
  setOpenAuthPopup: React.Dispatch<SetStateAction<boolean>>;
  isShowRedCircle: boolean;
}

interface IButtonProps {
  title: string;
  onClick: () => void;
  blackIcon: IconType;
  whiteIcon: IconType;
  active: boolean;
  isShowRedCircle?: boolean;
}

const drawerStyles = {
  '.MuiBackdrop-root': {
    backdropFilter: 'blur(4px)',
    transition: 'opacity 0.8s ease, visibility 0s !important',
  },

  '.MuiDrawer-paper': {
    backgroundColor: '#110B11',
    width: '320px',

    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },

    '@media(max-width: 320px)': {
      width: '100%',
    },
  },
};

const UsersDrawer: FC<IUsersDrawer> = ({
  open,
  setOpen,
  handleLogOut,
  setOpenAuthPopup,
  isShowRedCircle,
}) => {
  const { isAuthorized } = useAppSelector(state => state.authReducer);
  const { activeLocale, activeCurrency } = useAppSelector(state => state.authReducer);
  const navigateWithLocale = useNavigateWithLocale();
  const location = useLocation();

  const buttonOnClick = (path: string): void => {
    setOpen(false);
    setTimeout(() => {
      navigateWithLocale(path);
    }, 60);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const buttonProps: IButtonProps[] = [
    {
      title: t('translation:header.profile_select_item_1.message'),
      onClick: () => buttonOnClick('/my-profile'),
      blackIcon: 'user',
      whiteIcon: 'whiteUser',
      active: location.pathname == '/my-profile',
    },
    {
      title: t('translation:header.profile_select_item_2.message'),
      onClick: () => buttonOnClick('/my-posts'),
      blackIcon: 'blackPosts',
      whiteIcon: 'whitePosts',
      active: location.pathname == '/my-posts',
    },
    {
      title: t('translation:header.profile_select_item_3.message'),
      onClick: () => buttonOnClick('/my-favorite-posts'),
      blackIcon: 'like',
      whiteIcon: 'whiteLike',
      active: location.pathname == '/my-favorite-posts',
    },
    {
      title: t('translation:header.profile_select_item_5.message'),
      onClick: () => buttonOnClick('/my-purchases'),
      blackIcon: 'blackPurchases',
      whiteIcon: 'whitePurchases',
      active: location.pathname == '/my-purchases',
    },
    {
      title: t('translation:header.profile_select_item_4.message'),
      onClick: () => buttonOnClick('/chat'),
      blackIcon: 'chat',
      whiteIcon: 'whiteChat',
      active: location.pathname == '/chat',
      isShowRedCircle,
    },
  ];

  const handleSignClick = (): void => {
    setOpen(false);
    isAuthorized ? handleLogOut() : setOpenAuthPopup(true);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={drawerStyles}
    >
      <Box role="presentation" onKeyDown={toggleDrawer(false)}>
        <div className="user-menu_container">
          <div className="icon-cross_container" onClick={toggleDrawer(false)}>
            <Icon type="whiteCrossSmall" />
          </div>

          <div className="user-buttons_container">
            {isAuthorized && (
              <div className="user-auth-buttons">
                {buttonProps.map(e => (
                  <UsersButton
                    key={e.title}
                    isActive={e.active}
                    title={e.title}
                    onClick={e.onClick}
                    blackTypeIcon={e.blackIcon}
                    whiteTypeIcon={e.whiteIcon}
                    isShowRedCircle={e.isShowRedCircle}
                  />
                ))}
              </div>
            )}

            <LocaleCurrencyButton
              isLocale
              translationKey="default_components.language_and_currency_selector.language_selector_title.message"
              label={activeLocale?.short_label}
              options={LOCALE_OPTIONS}
            />

            <LocaleCurrencyButton
              isCurrency
              translationKey="default_components.language_and_currency_selector.currency_selector_title.message"
              label={activeCurrency?.label}
              options={CURRENCY_OPTIONS}
            />
          </div>

          <UsersButton
            title={
              isAuthorized
                ? t('translation:header.logout.message')
                : t('translation:header.login.message')
            }
            onClick={handleSignClick}
            blackTypeIcon="blackLogOut"
            whiteTypeIcon={isAuthorized ? 'whiteLogOut' : 'whiteLogIn'}
          />
        </div>
      </Box>
    </SwipeableDrawer>
  );
};

export default UsersDrawer;
