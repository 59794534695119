type postsStatus = {
  label: string;
  value: string;
  index: number;
};

type CommonSortItemType<T> = {
  value: T;
  index: number;
  label?: string;
};

const postsStatuses: postsStatus[] = [
  { label: 'active', value: 'active', index: 0 },
  { label: 'on_moderation', value: 'review', index: 1 },
  { label: 'rejected', value: 'rejected', index: 2 },
  { label: 'inactive', value: 'closed', index: 3 },
];

const sortVariants = [
  { value: 'new_first', index: 0 },
  { value: 'old_first', index: 1 },
  { value: 'price_low_first', index: 2 },
  { value: 'price_high_first', index: 3 },
  { value: 'alphabetical', index: 4 },
];

const noPostLabels = [
  {
    index: 0,
    buttonValue: 'no_active_ads_btn',
    link: '/creation',
    value: 'no_active_ads',
  },
  {
    index: 1,
    buttonValue: 'no_moderation_ads_btn',
    link: '/',
    value: 'no_moderation_ads',
  },
  {
    index: 2,
    buttonValue: 'no_rejected_ads_btn',
    link: '/posting-rules',
    value: 'no_rejected_ads',
  },
  {
    index: 3,
    buttonValue: 'no_inactive_ads_btn',
    link: '/',
    value: 'no_inactive_ads',
  },
];

const MyPostCardSkeletonStyle = {
  width: '31.5%',
  height: '250px',
  margin: '0 10px 20px',
  borderRadius: '10px',

  '@media (max-width: 1200px)': {
    width: '47.5%',
  },

  '@media (max-width: 840px)': {
    width: '46.5%',
  },

  '@media (max-width: 760px)': {
    width: '98%',
  },
};

const MyPostCardHorizontalSkeletonStyle = {
  width: '100%',
  height: '284px',
  marginBottom: '20px',
  borderRadius: '10px',

  '@media (max-width: 768px)': {
    height: '218px',
    marginBottom: '16px',
  },

  '@media (max-width: 460px)': {
    height: '190px',
  },
};

const CustomBadgeStyles = {
  '.MuiBadge-badge': {
    marginTop: '14px',
    position: 'relative',
    backgroundColor: '#F2F2F2',
    color: '#3C3C3B',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Noto Sans,sans-serif;',
  },
};

export {
  postsStatus,
  postsStatuses,
  CommonSortItemType,
  sortVariants,
  noPostLabels,
  MyPostCardSkeletonStyle,
  MyPostCardHorizontalSkeletonStyle,
  CustomBadgeStyles,
};
export { default as NoPosts } from './noPosts/NoPosts';
export { default as SortingMenu } from './sortingMenu/SortingMenu';
