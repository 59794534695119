import React, { FC } from 'react';
import { Icon } from "../../../default_components";
import { IconType } from "../../../default_components/Icon/IconType";

interface IUserButton {
  isActive?: boolean;
  whiteTypeIcon: IconType;
  blackTypeIcon: IconType;
  title: string;
  onClick: () => void;
  isShowRedCircle?: boolean;
}

const UsersButton: FC<IUserButton> = ({ 
  isActive,
  title, 
  whiteTypeIcon, 
  blackTypeIcon, 
  onClick, 
  isShowRedCircle,
}) => {
  return (
    <div className={`user-button ${isActive && "active"}`} onClick={onClick}>
      <div className="icon-box">
        <Icon type={whiteTypeIcon} />
        <Icon type={blackTypeIcon} />
        {isShowRedCircle && <div className='identificator-unread-message'/>}
      </div>
      <p>{title}</p>
    </div>
  );
};

export default UsersButton;
