import React, { FC } from 'react';
import { StarType } from '../../../../types/reviewsTypes';
import { to_rating_status, to_stars } from '../../../../utils/convert';
import Icon, { IconSizeType } from '../../Icon/Icon';
import './ratingStars.scss';
import { t } from 'i18next';

const RatingStars: FC<StarType> = ({
  rating,
  noDigit = false,
  starsSize = 'large',
  mode = 'view',
  setRating,
}) => {
  const handleStarClick = (newRating: number): void => {
    if (mode === 'add' && setRating) {
      setRating(newRating);
    }
  };

  const stars = to_stars(rating).map((star: string, index: number) => (
    <Icon
      key={index}
      type={`star${star}`}
      size={starsSize as IconSizeType}
      className="star"
      onClick={mode === 'add' ? () => handleStarClick(index + 1) : undefined}
    />
  ));

  if (mode === 'view') {
    return (
      <div className="rating__stars-view">
        <div className="stars">{stars}</div>
      </div>
    );
  }

  if (mode === 'add') {
    return (
      <div className="rating__stars-add-frame">
        <div className="rating__stars-add">
          <div className="stars">{stars}</div>
        </div>

        <div className="rating__stars-add__status">
          {t(`translation:add_review.rating_status.${to_rating_status(rating)}.message`)}
        </div>
      </div>
    );
  }

  return null;
};

export default RatingStars;
