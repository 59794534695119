import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/auth.slice';
import { api } from '../services/Api';
import { chatReducer } from './reducers/chat.slice';
import { avatarReducer } from './reducers/avatar.slice';
import { searchReducer } from './reducers/search.slice';
import { authorizationReducer } from './reducers/authorization.slice';
import { chatApi } from '../services/chatApi';
import { apiProfile } from '../services/ApiProfile';
import { filterReducer } from "./reducers/filter.slice";

const rootReducer = combineReducers({
  authReducer,
  avatarReducer,
  chatReducer,
  searchReducer,
  authorizationReducer,
  filterReducer,
  [api.reducerPath]: api.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [apiProfile.reducerPath]: apiProfile.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(api.middleware, apiProfile.middleware, chatApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
