import React from "react";
import { IPost } from "../../../types";
import { Icon } from "../index";

const MyPostsCardViews = (post: IPost) => {
  const field_counts = [
    { count: post.views_count, typeIcon: "viewCount" },
    { count: post.likes_count, typeIcon: "likeCount" },
    { count: post.messages_count, typeIcon: "chatCount" },
    { count: 0, typeIcon: "phoneCount" },
  ];

  return (
    <div className="my-posts-card__views">
      {field_counts.map((field, id) =>
        <div key={id} className="my-posts-card__views__item my-posts-card__text">
          {field.count}
          <Icon type={field.typeIcon} />
        </div>
      )}
    </div>
  );
};

export { MyPostsCardViews };
