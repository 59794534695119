import React, { FC } from 'react';
import { FormControl, TextField } from '@mui/material';
import { searchChatInputStyle } from './SearchChatInputStyle';
import { t } from 'i18next';
import { useActions } from '../../../hooks/redux';

const SearchChatInput: FC = () => {
  const { filterUsers } = useActions();

  return (
    <FormControl sx={searchChatInputStyle}>
      <TextField
        onChange={e => filterUsers(e.target.value)}
        placeholder={`${t('translation:chat.search.message')}`}
        type="text"
        variant="outlined"
      />
    </FormControl>
  );
};

export default SearchChatInput;
