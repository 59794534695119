import React, { useEffect, useState } from 'react';
import './headerMenu.scss';
import { Icon } from '../../default_components';
import { useActions, useAppSelector } from '../../../hooks/redux';
import UsersMenu from './usersMenu/UsersMenu';
import { useNavigate } from 'react-router-dom';
import Authorization from '../../auth/Authorization';
import { localStorageKeys, REDIRECT_URLS } from '../../../routes';
import { t } from 'i18next';
import UsersDrawer from './usersMenu/UsersDrawer';
import BurgerMenu from '../../default_components/assets/icons/Menu.svg';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

const HeaderMenu = () => {
  const { chats } = useAppSelector(state => state.chatReducer);
  const path = window.location.pathname;

  const { isAuthorized } = useAppSelector(state => state.authReducer);
  const { setIsAuthorized } = useActions();
  const navigateWithLocale = useNavigateWithLocale();

  const [redirectUrl, setRedirectUrl] = useState<string>(REDIRECT_URLS.DASHBOARD);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(path == '/register');
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

  const areUnreadMassages: boolean =
    chats.filter(c => c.unread_messages && c.unread_messages > 0).length > 0;

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (!openAuthPopup && path == '/register') {
      navigateWithLocale('/dashboard');
    }

    if (isAuthorized && path == '/register') {
      setOpenAuthPopup(false);
      navigateWithLocale('/dashboard');
    }
  }, [openAuthPopup, isAuthorized]);

  const resizeHandler = (e: any) => {
    const width = e.target.innerWidth;
    if (width > 760) {
      setShowUserMenu(false);
    }
    if (!showUserMenu && width < 760) {
      setAnchorEl(null);
    }
  };

  const moveToCreation = () => {
    if (isAuthorized) {
      navigateWithLocale(REDIRECT_URLS.CREATION_PAGE);
    } else {
      setOpenAuthPopup(true);
      setRedirectUrl(REDIRECT_URLS.CREATION_PAGE);
    }
  };

  const clickOnLogin = (): void => {
    setOpenAuthPopup(true);
    setRedirectUrl(window.location.pathname);
  };

  const handleLogOut = (): void => {
    localStorage.removeItem(localStorageKeys.token);
    setIsAuthorized(false);
    window.FB.getLoginStatus((response: any) => {
      if (response.authResponse) {
        window.FB.api('/me/permissions', 'delete', null, () =>
          window.FB.logout(response.authResponse.accessToken),
        );
      }
    });
    window.location.replace('/');
  };

  return (
    <div className="header-menu-frame">
      <div
        onClick={moveToCreation}
        className="header-menu__button sell"
        data-cy="create-post-button"
      >
        <Icon type="plus" />
        <p>{t('translation:header.sell.message')}</p>
      </div>

      {isAuthorized ? (
        <UsersMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleLogOut={handleLogOut} />
      ) : (
        <div
          onClick={clickOnLogin}
          className="header-menu__button log-in"
          data-cy="header-login-button"
        >
          <p>{t('translation:header.login.message')}</p>
        </div>
      )}

      <div className="header-menu__burger-button" onClick={() => setShowUserMenu(true)}>
        {areUnreadMassages && <div className="identificator-unread-message" />}
        <img src={BurgerMenu} alt="Burger Menu" />
      </div>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={redirectUrl}
      />

      <UsersDrawer
        open={showUserMenu}
        setOpen={setShowUserMenu}
        handleLogOut={handleLogOut}
        setOpenAuthPopup={setOpenAuthPopup}
        isShowRedCircle={areUnreadMassages}
      />
    </div>
  );
};

export default HeaderMenu;
