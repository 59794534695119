import React, { FC, useEffect } from 'react';
import { useGetProfileInfo } from '../../hooks/useGetProfileInfo';
import { useParams } from 'react-router-dom';
import { api } from '../../services/Api';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import PostContent from './postContent/PostContent';
import PostsRow from '../default_components/postsRow/PostsRow';
import './postContent/postContent.scss';
import { useAppSelector } from '../../hooks/redux';

const PostPage: FC = () => {
  const { isAuthorized, activeLocale } = useAppSelector(state => state.authReducer);

  const postId = useParams().itemId;
  const {
    data: post,
    refetch: postRefetch,
    isFetching,
  } = api.useFetchPostQuery({ post_id: postId! });
  const { data: posts, isLoading, refetch } = api.useGetSimilarPostsQuery(postId ?? '');
  const { data: viewedPosts, isLoading: isViewedPostLoading } =
    api.useGetRecentlyViewedPostsQuery();

  const { profileInfo } = useGetProfileInfo();

  useEffect(() => {
    if (postId) {
      postRefetch();
      refetch();
    }
  }, [postId, postRefetch, refetch, activeLocale]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {post && !isFetching
            ? `${t('translation:seo.product_page.buy.message')}${post.title} ${t(
                'translation:seo.product_page.on_gamar_market.message',
              )}`
            : `${t('translation:seo.product_page.loading.message')}`}
        </title>
        <meta name="description" content={post?.description} />
        <meta
          property="og:title"
          content={`${t('translation:seo.product_page.buy.message')}${post?.title} ${t(
            'translation:seo.product_page.on_gamar_market.message',
          )}`}
        />
        <meta property="og:description" content={post?.description} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={post?.images[0]} />
      </Helmet>

      <Header />

      <div className={`post-page__wrapper ${profileInfo?.id == post?.user.id && 'is-my-post'}`}>
        <PostContent post={post} isPostFetching={isFetching} />

        <div className={'post-rows__container'}>
          <PostsRow
            title={t('translation:product.similar_ads.message')}
            posts={posts}
            isLoading={isLoading}
            style={{ marginTop: 0 }}
          />

          {isAuthorized && (
            <PostsRow
              title={t('translation:product.recently_viewed.message')}
              posts={viewedPosts}
              isLoading={isViewedPostLoading}
              style={{ marginTop: 0 }}
              loop={false}
            />
          )}
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default PostPage;
