import React, { FC, SetStateAction, useEffect, useState } from 'react';
import './confirmModal.scss';
import { t } from 'i18next';
import { Icon, InputGamar, Loader } from '../../../default_components';

interface IConfirmModal {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  code: string;
  setCode: React.Dispatch<SetStateAction<string>>;
  title: string;
  newValue: string | undefined;
  fromPassword?: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  time: number;
  loading: boolean;
  sendAgain: () => void;
  confirmAction: () => void;
}

const ConfirmModal: FC<IConfirmModal> = ({
  open,
  setOpen,
  code,
  setCode,
  title,
  newValue,
  fromPassword,
  time,
  loading,
  isError,
  errorMessage,
  confirmAction,
  sendAgain,
}) => {
  const [hideError, setHideError] = useState<boolean>(false);

  useEffect(() => {
    setHideError(!isError);
  }, [isError]);

  useEffect(() => {
    !open && setHideError(true);
  }, [open]);

  return (
    <div onClick={() => setOpen(false)} className={`modal_frame ${open && 'show'}`}>
      <div onClick={e => e.stopPropagation()} className="modal_container">
        <div onClick={e => e.stopPropagation()} className="confirm-modal-content">
          <p className="modal_title">{title}</p>

          <div className="confirm-modal-message">
            ⠀
            <p>
              {t('translation:profile.modal.code_sent_1.message')}&nbsp;
              <span>{newValue}</span>. {t('translation:profile.modal.code_sent_2.message')}
              {fromPassword ? t('translation:profile.modal.code_sent_3.message') : '.'}
            </p>
          </div>

          <div className="input-code_container">
            <div className="input-code">
              <InputGamar
                value={code}
                setValue={setCode}
                label={t('translation:profile.modal.code_label.message')}
                error={errorMessage}
                hideError={hideError}
                setHideError={setHideError}
              />
            </div>

            <div className="send-again-text__container">
              <p
                onClick={sendAgain}
                className={`send-again-button ${time === 0 && 'pointer'}`}
              >
                {t('translation:profile.modal.send_again.message')}
              </p>

              <p>
                {t('translation:profile.modal.in_code.message')}
                {time < 10 && '0'}
                {time}
              </p>
            </div>
          </div>

          <div
            onClick={() => {
              confirmAction();
            }}
            className={`confirm-button ${code.length && 'active'}`}
          >
            {loading ? <Loader isWhite={true} /> : t('translation:profile.modal.confirm.message')}
          </div>
        </div>

        <div className="modal_close-button" onClick={() => setOpen(false)}>
          <Icon type="crossForModal" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
