import React, { FC, useEffect, useState } from 'react';
import { RatingStars } from '../../../default_components/reviews';
import { AddReviewModalType } from '../../../../types/reviewsTypes';
import { Icon } from '../../../default_components';
import { Fade, Modal } from '@mui/material';
import './addReviewModal.scss';
import { to_avatar_from_name, to_date, to_short_text } from '../../../../utils/convert';
import Chip from '../../../default_components/chips/Chip';
import DefaultButton from '../../../default_components/button/DefaultButton';
import { apiProfile } from '../../../../services/ApiProfile';
import { api } from '../../../../services/Api';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import Snackbar from '../../../default_components/snackbar/DefaultSnackbar';
import { t } from 'i18next';
import { useLocaleFromUrl } from '../../../../utils/localeFromUrl';

const AddReviewModal: FC<AddReviewModalType> = ({ open, setOpen, post, hideAddReviewButton }) => {
  const { data } = apiProfile.useGetUserProfileQuery(post.user.id ?? '');
  const [addReview] = api.usePostReviewMutation();
  const localeStr = useLocaleFromUrl();

  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);

  const {
    open: openSnackbar,
    message,
    severity,
    closeSnackbar,
    getActiveSnackbar,
  } = useMethodsSnackbar();

  const tagValues = [
    t('translation:add_review.tag.friendliness.message'),
    t('translation:add_review.tag.quality.message'),
    t('translation:add_review.tag.price_quality.message'),
    t('translation:add_review.tag.flexibility.message'),
    t('translation:add_review.tag.description.message'),
    t('translation:add_review.tag.bonuses.message'),
  ];

  useEffect(() => {
    setRating(0);
    setComment('');
    setTags([]);
  }, [open]);

  const resetForm = (): void => {
    setOpen(false);
    setRating(0);
    setComment('');
    setTags([]);
  };

  const handleAddReview = async () => {
    try {
      const reviewData = {
        post_id: post.id,
        rating,
        comment,
      };

      const { data, error }: { data?: any; error?: any } = await addReview(reviewData);

      if (error) {
        if (error.status !== 200) {
          getActiveSnackbar(error.data?.errors, 'error');
        }
      } else {
        getActiveSnackbar(t('translation:add_review.success.message'), 'success');
        resetForm();
        hideAddReviewButton && hideAddReviewButton();
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleChipClick = (label: string) => {
    if (tags.includes(label)) {
      setTags(tags.filter(chip => chip !== label));
    } else {
      setTags([...tags, label]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason: 'backdropClick' | 'escapeKeyDown') => setOpen(!open)}
      closeAfterTransition
      className="add__review-container"
      disableScrollLock={false}
    >
      <Fade in={open}>
        <div className="add__review-modal">
          <div className="add__review-modal-header">
            <p className="add__review-modal-header__title">
              {t('translation:add_review.add_review.message')}
            </p>

            <Icon
              type="crossDefault"
              className="add__review-modal-header__close"
              onClick={() => setOpen(!open)}
            />
          </div>

          <div className="add__review-modal-body">
            <div className="add__review-modal-body__user">
              <div
                className="add__review-modal-body__user-info"
                onClick={() => window.open(`/user/${data?.id}`, '_blank')}
              >
                {data && (
                  <a
                    href={localeStr ? `${localeStr}/user/${data?.id}` : `/user/${data?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data?.avatar ? data?.avatar : to_avatar_from_name(data.username)}
                      className="add__review-modal-body__user-image"
                      alt="User image"
                    />
                  </a>
                )}

                <div>
                  <div className="add__review-modal-body__user-name">{data?.username}</div>

                  <div className="add__review-modal-body__user-post">
                    {to_short_text(post.title, window.innerWidth < 500 ? 15 : 30)}
                  </div>
                </div>
              </div>

              <div className="add__review-modal-body__user-date">
                <p>{to_date(post.created_at)}</p>
              </div>
            </div>

            <div className="add__review-modal-body__rate">
              <p>{t('translation:add_review.rate.message')}</p>

              <RatingStars
                rating={rating}
                setRating={setRating}
                noDigit={true}
                starsSize="3x-large"
                mode="add"
              />
            </div>

            <div className="add__review-modal-body__tags">
              <p>{t('translation:add_review.what_did_you_like.message')}</p>

              <div className="add__review-modal-body__tags-chips">
                {tagValues.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={handleChipClick}
                    isActive={tags.includes(tag)}
                  />
                ))}
              </div>
            </div>

            <div className="add__review-modal-body__comment">
              <p>{t('translation:add_review.leave_feedback.message')}</p>

              <textarea
                value={comment}
                onChange={e => setComment(e.target.value)}
                className="add__review-modal-body__comment-input"
                placeholder={t('translation:add_review.textarea_placeholder.message') || ''}
              />
            </div>
          </div>

          <div className="add__review-modal-footer">
            <DefaultButton
              onClickAction={() => setOpen(!open)}
              styleType="gray-gray_outline-gray-gray-gray"
              sizeType="cancel-responsive"
              translateKey="add_review.cancel.message"
            />

            <DefaultButton
              onClickAction={handleAddReview}
              styleType="accent-outline-gray-accent-gray"
              sizeType="submit-responsive"
              translateKey="add_review.submit.message"
            />
          </div>

          <Snackbar
            open={openSnackbar}
            close={closeSnackbar}
            message={message}
            severity={severity}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default AddReviewModal;
