import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { CategoryChip } from '../CategoryChip/CategoryChip';
import { api } from '../../../../services/Api';
import { Icon, Skeletons } from '../../../default_components';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { IPost } from '../../../../types';

interface CategoryRowProps {
  selectedCategories: string[];
  setSelectedCategories: Dispatch<string[]>;
  onClick: (id: string) => void;
  posts: IPost[] | undefined;
}

const CategoryRow = ({
  selectedCategories,
  setSelectedCategories,
  onClick,
  posts,
}: CategoryRowProps) => {
  const { data: categories } = api.useGetCategoriesQuery();
  const [search, { data: searchData, isLoading: isSearchPostsLoading }] =
    api.useSearchByQueryMutation();
  const { activeLocale } = useSelector((state: any) => state.authReducer);

  const { userId } = useParams();

  useEffect(() => {
    search({ user_id: userId });
  }, [search, userId, activeLocale, posts]);

  const userCategories = useMemo(() => {
    const result: { id: string; name: string; count: number }[] = [];

    categories?.forEach(category => {
      const addCategory = (category: { id: string; name: string }) => {
        const count = searchData?.category_counts[`${category.id}`];
        if (count) {
          result.push({ ...category, count });
        }
      };

      addCategory(category);
      category.children.forEach(addCategory);
    });

    return result;
  }, [categories, searchData, activeLocale]);

  const categoriesRowRef = useRef<HTMLDivElement | null>(null);
  const [showLeftBlacking, setShowLeftBlacking] = useState<boolean>(false);
  const [showRightBlacking, setShowRightBlacking] = useState<boolean>(true);

  const clickOnBlackingBlock = (side: string): void => {
    if (side === 'left') {
      categoriesRowRef.current?.scrollBy(-400, 0);
    }
    if (side === 'right') {
      categoriesRowRef.current?.scrollBy(400, 0);
    }
  };

  useEffect(() => {
    onScrollCategories();
  }, [categories, isSearchPostsLoading]);

  const onScrollCategories = () => {
    const row = categoriesRowRef.current;

    if (row) {
      const isScrolledToStart = row.scrollLeft <= 10;
      const isScrolledToEnd = row.scrollWidth - (row.scrollLeft + row.offsetWidth) <= 10;

      const isScrollable = row.scrollWidth > row.offsetWidth;

      setShowLeftBlacking(!isScrolledToStart && isScrollable);
      setShowRightBlacking(!isScrolledToEnd && isScrollable);
    }
  };

  return (
    <div className="categories-row">
      {selectedCategories.length >= 1 && (
        <div className="clear-all-btn" onClick={() => setSelectedCategories([])}>
          {t('translation:filter.remove_all.message')}
        </div>
      )}

      <div className={'user-posts__categories-row-container'}>
        {categories && !isSearchPostsLoading && showLeftBlacking && (
          <div
            className={'arrow-btn row-left-button'}
            style={{ position: 'absolute' }}
            onClick={() => clickOnBlackingBlock('left')}
          >
            <Icon type="arrowVertical" />
          </div>
        )}

        <div
          onScroll={onScrollCategories}
          ref={categoriesRowRef}
          className={'user-posts__categories-row'}
        >
          {userCategories?.map((category: any, index) => (
            <div key={index}>
              <CategoryChip
                label={category.name}
                count={category.count}
                onClick={() => onClick(category.id)}
                selected={selectedCategories.includes(category.id)}
              />
            </div>
          ))}

          {isSearchPostsLoading &&
            [...Array(15)].map((_, index) => (
              <div key={index} className="category-button__skeleton">
                <Skeletons.SkeletonCategoryButton />
              </div>
            ))}
        </div>

        {categories && !isSearchPostsLoading && showRightBlacking && userCategories.length > 6 && (
          <div
            className={'arrow-btn row-right-button'}
            onClick={() => clickOnBlackingBlock('right')}
          >
            <Icon type="arrowVertical" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryRow;
