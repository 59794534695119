import React, { FC, SetStateAction } from 'react';
import { BurgerMenuButton } from '../../../default_components';
import { t } from 'i18next';
import './catalogButton.scss';

interface ICatalogButton {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

const CatalogButton: FC<ICatalogButton> = ({ open, setOpen }) => {
  return (
    <div className="catalog__root-button_container" onClick={e => e.stopPropagation()}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={`catalog__root-button_${open ? 'active' : 'default'}`}
      >
        <BurgerMenuButton open={open} />

        <span className="catalog__root-button_title">
          {t('translation:header.catalog.message')}
        </span>
      </button>
    </div>
  );
};

export default CatalogButton;
