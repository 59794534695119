import React, { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import './imagesBar.scss';
import { PostZoomSwiper } from '../../../post/postContent/postSwiper';

const CloseButtonStyle = {
  color: '#3C3C3B',
  fontSize: '14px',
};

interface ImagesBarProps {
  images: Blob[];
  removeImage: (index: number) => void;
}

export const ImagesBar = ({ images, removeImage }: ImagesBarProps) => {
  const [isZoomSwiperOpened, setIsZoomSwiperOpened] = useState<boolean>(false);

  const imagesUri = useMemo(() => {
    return images.map(image => URL.createObjectURL(image));
  }, [images]);

  return (
    <>
      <div className="images-bar__container">
        {imagesUri?.map((image, index) => {
          return (
            <div
              key={index}
              onClick={() => setIsZoomSwiperOpened(true)}
              className="images-bar__image-container"
            >
              <img src={image} alt={`${index}`} />
              <div onClick={() => removeImage(index)} className="cross-button">
                <CloseIcon sx={CloseButtonStyle} />
              </div>
            </div>
          );
        })}
      </div>

      <PostZoomSwiper
        images={imagesUri}
        isShow={isZoomSwiperOpened}
        setIsShow={setIsZoomSwiperOpened}
      />
    </>
  );
};
