import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './postActionsComplete.scss';
import { t } from 'i18next';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

const PostActionsComplete: FC = () => {
  const navigateWithLocale = useNavigateWithLocale();
  const moveToMainPage = () => navigateWithLocale('/');
  const moveToAgainCreation = () => window.location.replace('/creation');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="creation-complete-container">
      <p>
        {t('translation:creation.creation_complete.thank_you.message')} <br />{' '}
        {t('translation:creation.creation_complete.moderation_info.message')}
      </p>

      <div className="creation-complete_button-container">
        <button className="button-creation-page to-main-page" onClick={moveToMainPage}>
          {t('translation:creation.creation_complete.main_page.message')}
        </button>

        <button className="button-creation-page to-another-ad" onClick={moveToAgainCreation}>
          {t('translation:creation.creation_complete.another_ad.message')}
        </button>
      </div>
    </div>
  );
};

export default PostActionsComplete;
