import React, { useState } from 'react';
import './topSellers.scss';
import TopSellersInfoIcon from '../../../img/dashboard-img/top-users__info-icon.svg';
import TopSellersBannerIcon from '../../../img/dashboard-img/topSellersBanner.svg';
import { RatingStars } from '../../default_components/reviews';
import ViewReviewsModal from '../../reviews/modals/view/ViewReviewsModal';
import { api } from '../../../services/Api';
import { t } from 'i18next';
import { createRoot } from 'react-dom/client';
import { IUser } from '../../../types';
import { ReviewType, ReviewUserType } from '../../../types/reviewsTypes';
import { useLocaleFromUrl } from '../../../utils/localeFromUrl';

const TopSellers = React.memo(() => {
  const [sellerToCheck, setSellerToCheck] = useState<IUser | false>(false);

  const { data: topSellersData } = api.useGetTopSellersQuery();

  const localeStr = useLocaleFromUrl();

  const handleOpenSellerReviews = (seller: IUser): void => {
    setSellerToCheck(seller);
  };

  async function getSellerReviews(seller: IUser, index: number) {
    const response = await fetch(
      `https://gamarmarket-api-production.onrender.com/api/v1/reviews/?seller_id=${seller.id}`,
    );
    const reviewsData: ReviewType[] = await response.json();

    let count = reviewsData.length;
    let rating = reviewsData.reduce((acc, review) => acc + review.rating, 0) / count;

    const row = document.querySelector(`.seller-row[data-place="${index}"]`);
    const sellerReviewStatistic = (
      <div className="seller-row__review-statistic">
        <RatingStars rating={rating} starsSize="medium-large" />
        <span
          className="seller-row__open-reviews-span"
          onClick={() => handleOpenSellerReviews(seller)}
        >
          {count} {t(`translation:view_reviews.reviews_link.0`)}
        </span>
      </div>
    );

    let existingReviewStatistic = row?.querySelector('.seller-row__review-statistic');

    if (row) {
      if (existingReviewStatistic) {
        createRoot(existingReviewStatistic).render(sellerReviewStatistic);
      } else {
        const div = document.createElement('div');
        div.id = `seller-${index}-review-statistic-wrapper`;
        div.style.display = 'contents';
        row.appendChild(div);
        createRoot(div).render(sellerReviewStatistic);
      }
    }
  }

  return (
    <div className="top-sellers__background">
      <div className="top-sellers__wrapper">
        <div className="top-sellers-card">
          <h2 className="top-sellers-card__title">
            {t(`translation:top_sellers.list_title.message`)}
          </h2>
          <img
            src={TopSellersInfoIcon}
            alt="top-sellers-info"
            className="top-sellers-card__info-icon"
          />

          {topSellersData?.map((seller, index) => {
            let sellerReviewStatisticWrapper = document.getElementById(
              `seller-${index}-review-statistic-wrapper`,
            );

            if (!sellerReviewStatisticWrapper) {
              getSellerReviews(seller, index);
            }

            return (
              <div className="seller-row" data-place={index}>
                <div className="seller-row__place">#{index + 1}</div>

                <a
                  href={localeStr ? `${localeStr}/user/${seller.id}` : `/user/${seller.id}`}
                  className="seller-row__avatar"
                >
                  {seller.avatar ? (
                    <img src={seller.avatar} alt={`${seller.username} avatar`} />
                  ) : (
                    <p>{seller.username[0].toUpperCase()}</p>
                  )}
                </a>

                <div className="seller-row__name">{seller.username}</div>
              </div>
            );
          })}
        </div>

        <div className="top-sellers-card">
          <h2 className="top-sellers-card__title">
            {t(`translation:top_sellers.banner_title.message`)}
          </h2>

          <p className="top-sellers-card__text">
            <img
              src={TopSellersBannerIcon}
              alt="top-seller-banner"
              className="top-sellers-banner__image"
            />
            {t(`translation:top_sellers.banner_content_text.message`)}
          </p>
        </div>
      </div>

      {sellerToCheck && (
        <ViewReviewsModal
          open={Boolean(sellerToCheck)}
          setOpen={() => setSellerToCheck(false)}
          user={sellerToCheck as ReviewUserType}
        />
      )}
    </div>
  );
});

export default TopSellers;
