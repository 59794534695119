import { apiProfile } from "../services/ApiProfile";
import { useEffect } from "react";
import { IUser } from "../types";
import { useActions } from "./redux";

export const useGetProfileInfo = (): { profileInfo: IUser | undefined, isLoading: boolean, isError: boolean } => {
  const { setProfileInfo } = useActions();
  const [getProfileInfo, { data: profileInfo, isLoading, isError, isSuccess }] = apiProfile.useLazyGetProfileInfoQuery();

  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    setProfileInfo(profileInfo);
  }, [isSuccess]);
  
  return {
    profileInfo,
    isLoading,
    isError,
  }
};

//*** example for copy to use -> const { profileInfo, isLoading, isError } = useGetProfileInfo();
