import React, { CSSProperties, FC, useRef, useMemo } from 'react';
import './postsRow.scss';
import { IPost } from '../../../types';
import { Icon, PostCard } from '../index';
import LargeArrow from '../assets/icons/LargeArrow.svg';
import SkeletonProductCard from '../postCard/SkeletonProductCard';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { FreeMode, Keyboard, Mousewheel, Navigation } from 'swiper';

interface IPostsRow {
  title: string;
  posts: IPost[] | undefined;
  isLoading: boolean;
  style?: CSSProperties;
  loop?: boolean;
  onTitleClick?: () => void;
}

const PostsRow: FC<IPostsRow> = ({ title, posts, isLoading, style, loop = false, onTitleClick }) => {
  const swiperRef = useRef<SwiperRef | null>(null);

  const reversedPosts = useMemo(() => posts ? [...posts].reverse() : [], [posts]);

  const clickSwipeRight = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const clickSwipeLeft = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="posts-row__container" style={style}>
      <div className="posts-row">
        <div className="posts-row__title-container" onClick={onTitleClick}>
          <h3 className="posts-row__title">{title}</h3>

          <Icon type="arrowHorizontal" size={'medium-large'} />
        </div>

        <div className="left-button" onClick={clickSwipeLeft}>
          <img src={LargeArrow} alt="icon" />
        </div>

        <Swiper
          loop={loop}
          freeMode={{
            enabled: true,
          }}
          ref={swiperRef}
          spaceBetween={20}
          slidesPerView={'auto'}
          centeredSlides={false}
          keyboard={true}
          modules={[Navigation, Mousewheel, Keyboard, FreeMode]}
          className={'posts-row__content'}
        >
          {isLoading &&
            [1, 2, 3, 4, 5, 6].map(key => (
              <SwiperSlide className={'post-card_frame'}>
                <SkeletonProductCard key={key} />
              </SwiperSlide>
            ))}

          {posts && reversedPosts.map(post => (
              <SwiperSlide key={post.id}>
                <PostCard post={post} />
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="right-button" onClick={clickSwipeRight}>
          <img src={LargeArrow} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default PostsRow;
