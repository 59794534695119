import { IOptions } from '../store/reducers/auth.slice';
import { apiProfile } from '../services/ApiProfile';
import { setCurrency, setLocale } from '../utils/set';

interface OBJ {
  option: IOptions | { value: string };
  isLocale?: boolean;
  isCurrency?: boolean;
}

export const useSaveLocaleCurrency = () => {
  const [updateProfileLocale] = apiProfile.useUpdateProfileLocaleMutation();
  const [updateProfileCurrency] = apiProfile.useUpdateProfileCurrencyMutation();

  const saveOption = ({ option, isLocale, isCurrency }: OBJ): void => {
    isLocale && setLocale(option.value, updateProfileLocale);
    isCurrency && setCurrency(option.value, updateProfileCurrency);
  };

  return {
    saveOption,
  };
};
