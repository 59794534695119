import React, { FC, useState } from 'react';
import FilterOptions from './filterOptions/FilterOptions';
import FilterPosts from './filterPosts/FilterPosts';
import { ISearchRequest } from '../../services/types';
import { Drawer } from '@mui/material';

interface IFilterRoute {
  category_id: string | undefined;
  isLoading: boolean;
  search: ({}: ISearchRequest) => void;
}

const FilterDrawerStyle = {
  '.MuiPaper-elevation': {
    background: '#f2f2f2',
    borderRadius: '10px 0 0 10px',
  },
};

const FilterContent: FC<IFilterRoute> = ({ search, category_id, isLoading }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpenDrawer(open);
  };

  return (
    <div className="filter-page__container">
      <div className="filter-page">

        <div className="filter-page__content">
          <FilterOptions category_id={category_id} search={search} />
          <FilterPosts
            isLoading={isLoading}
            search={search}
            category_id={category_id}
            toggleDrawer={toggleDrawer}
          />
        </div>
      </div>

      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={toggleDrawer(false)}
        sx={FilterDrawerStyle}
        children={
          <FilterOptions
            isSwiper
            search={search}
            category_id={category_id}
            closeDrawer={() => setIsOpenDrawer(false)}
          />
        }
      ></Drawer>
    </div>
  );
};

export default FilterContent;
