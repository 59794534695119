import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './failedPages.scss';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const NoMatch = () => {
  const query = window.location.pathname;

  useEffect(() => {
    const setStatus = (status: number) => {
      if (window && window.history) {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname + '?status=' + status,
        );
      }
    };

    if (query === '/501') {
      setStatus(501);
    } else {
      setStatus(404);
    }
  }, [query]);

  if (query == '/501') {
    return (
      <div className="error-page-container">
        <div className="error-container_frame">
          <p>{t('translation:no_match.501.fail_to_fetch.message')}</p>

          <Link className="redirect-container" to="/dashboard">
            <div>{t('translation:no_match.501.re_fetch.message')}</div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Header />

      <div className="error-page-container">
        <div className="error-container_frame">
          <p>{t('translation:no_match.404.title.message')}</p>
          <p>
            {t('translation:no_match.404.text.message')} {`${query}`}{' '}
            {t('translation:no_match.404.text_2.message')}
          </p>

          <Link className="redirect-container" to="/dashboard">
            <div>{t('translation:no_match.404.redirect.message')}</div>
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NoMatch;
