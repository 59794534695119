import React, { useState } from 'react';
import './myFavoritePosts.scss';
import '../myPosts/noPosts/noPosts.scss';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { api } from '../../services/Api';
import { PaginationStyle } from './PaginationStyle';
import { Pagination } from '@mui/material';
import { PostCard, Skeletons } from '../default_components';
import { usePagination } from '../../hooks/usePagination';
import { t } from 'i18next';
import FavoritesBlank from "../default_components/assets/icons/FavoritesBlank.svg";

const idsSkeletonPostCards = [1, 2, 3, 4, 5, 6];

const MyFavoritePosts = () => {
  const { data: likedPosts, isSuccess } = api.useGetLikedPostsQuery();

  const PER_PAGE = 18;
  const count = likedPosts ? Math.ceil(likedPosts.length / PER_PAGE) : 0;
  const _DATA = usePagination({ data: likedPosts, itemsPerPage: PER_PAGE });

  let [page, setPage] = useState(1);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA?.jump(p);
  };

  return (
    <React.Fragment>
      <Header />

      <div className="my-favorite-posts__container">
        <div className="my-favorite-posts">
          <div className="my-favorite-posts__text-row">
            <p className="my-favorite-posts__title">
              {t('translation:my_favorite_posts.title.message')}
            </p>

            <p className="my-favorite-posts__count">
              {!likedPosts ? '0' : likedPosts?.length}{' '}
              {t('translation:my_favorite_posts.posts.message')}
            </p>
          </div>

          {likedPosts?.length === 0 && isSuccess && (
            <div className="no-posts__container">
              <img src={FavoritesBlank} className="favorites-blank-slate" />
              <p className="favorites-no-posts-text">{t('translation:my_favorite_posts.no_posts.message')}</p>
            </div>
          )}

          <div className="product-cards-area">
            {!isSuccess
              ? idsSkeletonPostCards.map(id => <Skeletons.SkeletonPostCard key={id} myPosts />)
              : likedPosts &&
                likedPosts?.length > 0 &&
                _DATA
                  ?.currentData()
                  .map((product: any) => <PostCard key={product.id} post={product} myPosts />)}
          </div>
        </div>
      </div>

      {count > 1 && (
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="rounded"
          onChange={handleChange}
          sx={PaginationStyle}
        />
      )}

      <Footer />
    </React.Fragment>
  );
};

export default MyFavoritePosts;
