import React, { useEffect } from 'react';
import Logo from '../default_components/assets/icons/Logo.svg';
import Facebook from '../default_components/assets/icons/Facebook.svg';
import Instagram from '../default_components/assets/icons/Instagram.svg';
import Telegram from '../default_components/assets/icons/Telegram.svg';
import Mail from '../default_components/assets/icons/Mail.svg';
import FooterContent from './FooterContent';
import './footer.scss';
import { useLocaleFromUrl } from '../../utils/localeFromUrl';

const socialNetworksLinks = [
  { link: 'https://www.facebook.com/profile.php?id=100093651364787', icon: Facebook },
  { link: 'https://www.instagram.com/gamarmarket.ge/', icon: Instagram },
  { link: 'https://t.me/gamarmarket', icon: Telegram },
  { link: 'mailto: contact@gamarmarket.ge', icon: Mail },
];

const Footer = () => {
  const localeStr = useLocaleFromUrl();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//counter.top.ge/counter.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="footer__container">
      <div className="footer">
        <div className="footer__logo">
          <a href={localeStr ? `${localeStr}/dashboard` : `/dashboard`}>
            <img src={Logo} alt="Gamarmarket.ge logo" />
          </a>
        </div>

        <FooterContent />

        <div className="footer__icons-col">
          <div className="footer__icons-container">
            {socialNetworksLinks.map((network, index) => (
              <a href={network.link} target="_blank" rel="noopener noreferrer" key={index}>
                <img src={network.icon} alt={network.link} />
              </a>
            ))}
          </div>
          <div
            style={{ alignSelf: 'end' }}
            id="top-ge-counter-container"
            data-site-id="117290"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
