import React, { FC, CSSProperties } from 'react';
import './chip.scss';

interface ChipProps {
  label: string;
  color?: string;
  onClick?: (label: string) => void;
  className?: string;
  isActive?: boolean;
}

const Chip: FC<ChipProps> = ({ label, color, onClick, className, isActive }) => {
  const chipStyle: CSSProperties = color ? { backgroundColor: color } : {};

  const handleClick = () => {
    if (onClick) {
      onClick(label);
    }
  };

  const chipClasses = `default__component-chip ${className} ${isActive ? 'active' : ''}`;

  return (
    <div className={chipClasses} style={chipStyle} onClick={handleClick}>
      {label}
    </div>
  );
};

export default Chip;
