import React, { FC } from 'react';
import '../filterOptions.scss';
import { t } from 'i18next';
import { Checkbox, FormControlLabel, RadioGroup } from '@mui/material';
import { categoryFields, CheckedElementStyle, IStateAssets, RadioGroupStyle } from '../index';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ISearchRequest } from '../../../../services/types';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';

interface IStateSelect {
  search: ({}: ISearchRequest) => void;
}

const StateSelect: FC<IStateSelect> = ({ search }) => {
  const { setPostsStates, setPosts } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(state => state.filterReducer);

  const stateAssets: IStateAssets[] = [
    { value: 'new', label: t('translation:filter.new.message') },
    { value: 'used', label: t('translation:filter.used.message') },
  ];

  const { updateQueryParams } = useUpdateQueryParams();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    setPosts(undefined);

    const newPostsStates = {
      ...postsStates,
      [name]: checked,
    };

    setPostsStates(newPostsStates);

    const regions = Object.values(locationsStates);
    const used: boolean | undefined =
      (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
        ? event.target.name === 'used' && event.target.checked
        : undefined;

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    updateQueryParams({
      page: currentPage,
      used: newPostsStates.used,
      new: newPostsStates.new,
      sort_by: sortBy,
      handmade: isHandmade,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
      locations: regions,
      fields: encodeURIComponent(JSON.stringify(categoryFields(fieldsStates))),
    });

    search(requestObject);
  };

  return (
    <div>
      <p className="filter-menu__title">{t('translation:filter.state.message')}</p>

      <RadioGroup sx={RadioGroupStyle}>
        {stateAssets.map(state => (
          <FormControlLabel
            key={state.value}
            control={
              <Checkbox
                checked={postsStates[state.value] || false}
                onChange={handleChange}
                name={state.value}
                sx={CheckedElementStyle}
              />
            }
            label={state.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default StateSelect;
