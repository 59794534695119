import React, { FC, useEffect, useState } from 'react';
import '../filterOptions.scss';
import { Slider } from '@mui/material';
import { PriceSliderStyle } from './PriceSliderStyle';
import { t } from 'i18next';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ISearchRequest } from '../../../../services/types';
import { categoryFields } from '../index';
import { setQueryParams } from '../../../../utils/queryUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';

interface PriceSlider {
  search: ({}: ISearchRequest) => void;
}

const RangeSlider: FC<PriceSlider> = ({ search }) => {
  const { setPriceValue, setPosts } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    priceRange,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(state => state.filterReducer);

  const [value, setValue] = useState<number | number[]>([]);

  const { updateQueryParams } = useUpdateQueryParams();

  useEffect(() => {
    priceRange.min_price != null &&
      priceRange.max_price &&
      setValue([priceRange.min_price, priceRange.max_price]);
  }, [priceRange]);

  useEffect(() => {
    if (Array.isArray(priceValue) && priceValue.length === 0) return;

    setPriceValue(isPrimaryPriceRange(priceValue) ? [] : priceValue);
    setValue(priceValue);
  }, [priceValue]);

  const onChangeCommitted = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ): void => {
    const regions = Object.values(locationsStates);
    setPriceValue(newValue);
    setPosts(undefined);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: isPrimaryPriceRange(newValue) ? [] : newValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    updateQueryParams({
      page: currentPage,
      used: postsStates.used,
      new: postsStates.new,
      sort_by: sortBy,
      handmade: isHandmade,
      price_min: (newValue as number[])[0],
      price_max: (newValue as number[])[1],
      location: regions,
      fields: encodeURIComponent(JSON.stringify(categoryFields(fieldsStates))),
    });

    search(requestObject);
  };

  const isPrimaryPriceRange = (value: number | number[]) => {
    const priceValueArray = Array.isArray(value) ? (value as number[]) : [];

    return (
      priceValueArray.length === 2 &&
      priceValueArray[0] === priceRange.min_price &&
      priceValueArray[1] === priceRange.max_price
    );
  };

  return (
    <div>
      <p className="filter-menu__title">{t('translation:filter.price.message')}</p>

      {priceRange.min_price != null && priceRange.max_price && (
        <Slider
          value={value}
          onChangeCommitted={onChangeCommitted}
          onChange={(event, newValue) => setValue(newValue as number[])}
          valueLabelDisplay="on"
          getAriaValueText={range => range.toString()}
          min={priceRange.min_price}
          max={priceRange.max_price}
          sx={PriceSliderStyle}
        />
      )}
    </div>
  );
};

export default RangeSlider;
