import React, { FC, useState } from 'react';
import './catalogCategory.scss';
import { ICategory } from '../../../../../types';
import { t } from 'i18next';
import { useLocaleFromUrl } from '../../../../../utils/localeFromUrl';

interface ICatalogCategory {
  category: ICategory;
  onClick: (category: ICategory) => void;
}

const CatalogCategory: FC<ICatalogCategory> = ({ category, onClick }) => {
  const localeStr = useLocaleFromUrl();

  const childCategories = category.children;
  const [isShowList, setIsShowList] = useState<boolean>(false);
  const showListButtonText = isShowList
    ? t('translation:header.show_less.message')
    : t('translation:header.show_more.message');

  function itemList(sliceCount: number[]) {
    return childCategories.slice(...sliceCount).map(category => (
      <a
        href={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
        onClick={() => onClick(category)}
        style={{ textDecoration: 'none' }}
      >
        <li key={category.id} className="catalog-navigation-category__item">
          {category.name} {category.posts_count !== 0 && `(${category.posts_count})`}
        </li>
      </a>
    ));
  }

  return (
    <div className="catalog-navigation-category">
      <div>
        <a
          className="catalog-navigation-category__title"
          onClick={() => onClick(category)}
          href={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
          style={{ textDecoration: 'none' }}
        >
          {category.name}
        </a>

        {childCategories && (
          <ul className="catalog-navigation-category__list">
            {itemList([0, 5])}

            {childCategories.length > 5 && (
              <React.Fragment>
                <div className={`catalog-inner ${isShowList && 'show'}`}>{itemList([5])}</div>

                <div onClick={() => setIsShowList(!isShowList)} className="show-all">
                  {showListButtonText}
                </div>
              </React.Fragment>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CatalogCategory;
