import React, { SetStateAction, useEffect, useState } from 'react';
import { IChatRoom, IPost } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import { Icon } from '../../../default_components';
import { RadioGroupStyle } from '../../../filter/filterOptions';
import { FormControlLabel, RadioGroup } from '@mui/material/';
import { t } from 'i18next';
import { BuyerRadio } from '../../../default_components/buyerSelect';

interface DealsSelectBuyerProps {
  value?: any;
  setValue: React.Dispatch<SetStateAction<string> | any>;
  post?: IPost;
}

export const DealsSelectBuyer = ({ value, setValue, post }: DealsSelectBuyerProps) => {
  const { chats } = useAppSelector(state => state.chatReducer);
  const [postChats, setPostChats] = useState<IChatRoom[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    const appliedChats = chats.filter(chat => chat.post_id === post?.id);

    const filteredPostChats = appliedChats.filter(chat =>
      chat.interlocutor.username.toLowerCase().includes(searchValue.toLowerCase()),
    );

    setPostChats(filteredPostChats);
  }, [chats, searchValue]);

  return (
    <div className="deal-modal__select-buyer-container">
      <p>{t('translation:deals.select_chat.message')}</p>

      <div className="deal-modal__input-container">
        <input
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={`${t('translation:deals.input_placeholder.message')}`}
        />

        <div className="search-icon">
          <Icon type={'search'} size={'large'} />
        </div>
      </div>

      <RadioGroup
        value={value}
        onChange={e => setValue(e.target.value)}
        sx={RadioGroupStyle}
        className="deal-modal__select-radio-group"
      >
        {postChats.map(chat => {
          return (
            <FormControlLabel
              style={{ gap: 0 }}
              label={''}
              value={chat.id}
              control={
                <BuyerRadio
                  buyer={chat.interlocutor}
                  message={chat.messages[chat.messages.length - 1]}
                />
              }
            />
          );
        })}

        {!postChats.length && (
          <p style={{ alignSelf: 'center' }}>{t('translation:deals.no_chats.message')}</p>
        )}
      </RadioGroup>
    </div>
  );
};
