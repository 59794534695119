import React, { FC, useEffect, useState } from 'react';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import arrowBackModal from '../../default_components/assets/icons/ArrowBackModal.svg';
import { useActions } from '../../../hooks/redux';
import { useSelector } from 'react-redux';
import '../authorization.scss';
import { t } from 'i18next';

interface ILoginProps {
  handleClose: () => void;
  setAuthType: (authType: string) => void;
}

const CheckEmail: FC<ILoginProps> = ({ handleClose, setAuthType }) => {
  const authData = useSelector((state: any) => state.authorizationReducer);
  const [email] = useState(authData.email);
  const { saveEmail } = useActions();

  const moveTo = (page: string) => {
    setAuthType(page);
  };

  useEffect(() => {
    saveEmail(email);
  }, [email]);

  return (
    <React.Fragment>
      <div className="authorization__header pb-30">
        <div className="authorization__icon__back">
          <img alt="back" src={arrowBackModal} onClick={() => moveTo('FORGOT_PASSWORD')} />
        </div>

        <div className="authorization__title">{t('translation:auth.check_email.message')}</div>

        <div className="authorization__icon__close">
          <img alt="close" src={crossSmallForModal} onClick={handleClose} />
        </div>
      </div>

      <div className="authorization__body with-back-icon">
        <form className="authorization__form">
          <div className="authorization__small-text pb-50">
            {t('translation:auth.check_email_text.message')}
            <a className="colored">&nbsp;{authData.email}</a>
          </div>

          <div className="authorization__button mt-30">
            {t('translation:auth.reset_password.message')}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CheckEmail;
