import React, { FC } from 'react';
import './UserList.scss';
import { IChatRoom } from '../../../types';
import SearchChatInput from '../search_chat_input/SearchChatInput';
import LastMessage from '../LastMessages';
import { t } from 'i18next';
import { useAppSelector } from '../../../hooks/redux';
import { DateTime } from 'luxon';

const UserList: FC = () => {
  const { chats, searchQuery, selectedChat } = useAppSelector(state => state.chatReducer);

  const sortByTime = (array: IChatRoom[]) => {
    return array.slice().sort(function (a: any, b: any) {
      return (
        // @ts-ignore
        (a.last_message_at == null) - (b.last_message_at == null) ||
        DateTime.fromISO(b.last_message_at).toMillis() -
          DateTime.fromISO(a.last_message_at).toMillis()
      );
    });
  };

  return (
    <div className={`chat__user-list-container ${selectedChat && 'hide'}`}>
      <div className="chat__search-bar">
        <SearchChatInput />
      </div>

      <div className="chat__users-list">
        {chats.length > 0
          ? (sortByTime(chats)
            .filter(chat => chat.interlocutor.username.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((chat: IChatRoom) => <LastMessage key={chat.id} chat={chat} />)
          ) : (
          <div className="chat__users-list-loading">
            <p>{t('translation:chat.no_chats.message')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
