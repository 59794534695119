import React, { DOMAttributes, FC } from 'react';
import './icon.scss';
import { IconType, iconTypes } from './IconType';

export type IconSizeType =
  | 'extra-small'
  | 'small'
  | 'medium-small'
  | 'medium'
  | 'medium-large'
  | 'large'
  | 'extra-large'
  | '2x-large'
  | '3x-large'
  | '4x-large'
  | '5x-large'
  | '6x-large'
  | '7x-large'
  | '8x-large'
  | '9x-large'
  | '10x-large'
  | '11x-large'
  | '12x-large'
  | '13x-large'
  | '14x-large'
  | '15x-large'
  | '16x-large'
  | '17x-large'
  | '18x-large'
  | '19x-large'
  | '20x-large';

export interface Icon extends DOMAttributes<HTMLSpanElement> {
  size?: IconSizeType;
  type: IconType | string;
  useHover?: boolean;
  onClick?: any;
  id?: string;
  className?: unknown;
}

const getIcon = (type: IconType | string): JSX.Element => iconTypes.get(type) as JSX.Element;

const IconComponent: FC<Icon> = ({
  size = 'medium-large',
  type,
  onClick,
  useHover,
  id,
  className,
}) => {
  const [hover, setHover] = React.useState(false);

  return (
    <div
      className={`Icon Icon-IconSize__${size} ${
        useHover && hover ? 'accent-red' : ''
      } ${className}`}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      id={id}
    >
      {getIcon(type)}
    </div>
  );
};

export default IconComponent;
