import React, { FC, useState } from 'react';
import './postSwiper.scss';
import { IPostImageVariants } from '../../../../types';
import Skeleton from '@mui/material/Skeleton';

interface IPostImageSlide {
  image: IPostImageVariants;
  id: number;
}

const SkeletonStyle = {
  minWidth: '300px',
  width: '100%',
  height: '100%',
};

const PostImageSlide: FC<IPostImageSlide> = ({ image, id }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  return (
    <div className="swiper-slide post-image-slide">
      {!isLoaded && <Skeleton sx={SkeletonStyle} animation="wave" variant="rounded" />}

      <img
        alt="post image"
        className="post-swiper-slide"
        src={image.large}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default PostImageSlide;
