export const defaultRadioGroupStyle = (type?: string) => ({
  display: "flex",
  flexDirection: "column",
  gap: type === "filter" ? "20px !important" : "10px",

  ".MuiFormGroup-root": {
    display: "flex",
    flexDirection: "column",
    gap: "6 !important",
    width: "fit-content",

    ".MuiFormControlLabel-labelPlacementEnd": {
      maxHeight: 20,
      height: "100%",
      marginBottom: "10px",

      ".MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
        fontFamily: "'Noto Sans', serif !important"
      }
    }
  },

  fontFamily: "'Noto Sans', serif !important",
  fontSize: "16px !important",
  lineHeight: "22px !important",
  color: "#110B11 !important",
})
