export const PaginationStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '50px',
  marginBottom: '50px',

  '.MuiPagination-ul': {
    gap: '4px',
  },

  '.css-yx0nvq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
    background: '#dd2006',
    border: 'none',
    color: '#ffffff',
    borderRadius: '10px',

    '&:hover': {
      background: '#dd2006',
      border: 'none',
      color: '#ffffff',
    },

    '&:focus': {
      background: '#dd2006',
      border: 'none',
      color: '#ffffff',
    },
  },

  '.MuiPaginationItem-page, .MuiPaginationItem-previousNext': {
    background: '#FFF',
    borderRadius: '10px',
    transition: 'all .3s',

    '&:hover': {
      background: '#F2F2F2',
    },
  },

  '@media(max-width: 480px)': {
    '.MuiPaginationItem-root': {
      height: '30px',
      minWidth: '30px',
      padding: '0 5px',
    }
  },

  '@media(max-width: 380px)': {
    '.MuiPaginationItem-root': {
      height: '20px',
      minWidth: '20px'
    }
  }
};
