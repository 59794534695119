import React, { FC, useState } from 'react';
import './myPostCard.scss';
import { IPost } from '../../../types';
import { t } from 'i18next';
import { getPostPrice } from '../../../utils/get';
import { useAppSelector } from '../../../hooks/redux';
import { isoDateToHumanDate } from '../../../lib/text';
import { postsStatus } from '../../myPosts';
import { Skeleton } from '@mui/material';
import { MyPostsCardActionsButtons } from './myPostsCardActionsButtons/MyPostsCardActionsButtons';
import { MyPostsCardViews } from './MyPostsCardViews';
import MyPostCardHorizontal from './myPostCardHorizontal/MyPostCardHorizontal';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface MyPostCardProps {
  post: IPost;
  currentPostsStatus: postsStatus;
  postsView: 'list' | 'grid';
  clickOnDelete: (post: IPost) => void;
}

const MyPostCard: FC<MyPostCardProps> = ({
  post,
  currentPostsStatus,
  postsView,
  clickOnDelete,
}) => {
  const { activeCurrency } = useAppSelector(state => state.authReducer);

  const [loadedImage, setLoadedImage] = useState<boolean>(false);

  const navigateWithLocale = useNavigateWithLocale();

  const handleClickOnMyPostCard = () => {
    navigateWithLocale(`/item/${post.id}`);
  };

  const getCorrectText = (view: string): string => {
    switch (view) {
      case 'active':
        return t('translation:my_posts.active_from.message');
      case 'review':
        return t('translation:my_posts.on_moderation_from.message');
      case 'rejected':
        return t('translation:my_posts.rejected_on.message');
      case 'closed':
        return t('translation:my_posts.deactivated_on.message');
      default:
        return '';
    }
  };

  if (postsView === 'list') {
    return <MyPostCardHorizontal post={post} clickOnDelete={clickOnDelete} />;
  }

  return (
    <div className="my-posts-card__frame" data-cy="my-posts-card">
      <div className="my-posts-card__header">
        <div className="my-posts-card__date-info">
          <b className="my-posts-card__date-info-text">
            {getCorrectText(currentPostsStatus.value)}
          </b>
          &nbsp;
          <p className="my-posts-card__text date">{isoDateToHumanDate(post.created_at)}</p>
        </div>

        <MyPostsCardActionsButtons post={post} clickOnDelete={clickOnDelete} />
      </div>

      <div className="my-posts-card__root-info" onClick={handleClickOnMyPostCard}>
        <div className="my-posts-card__image-views__container">
          <div className="my-posts-card__image">
            {!loadedImage && <Skeleton sx={{ width: '100%', height: '100%' }} variant="rounded" />}
            <img
              src={[...post.images_variants].reverse()[0]?.large}
              onLoad={() => setLoadedImage(true)}
              alt="Product Image"
            />
          </div>

          {MyPostsCardViews(post)}
        </div>

        <p className="my-posts-card__price">
          {post.price === null
            ? t('translation:product_page.discussed.message')
            : post.price == 0.0
            ? t('translation:product_page.free.message')
            : getPostPrice(post, activeCurrency)}
        </p>

        <div className="my-posts-card__text title">{post.title}</div>
      </div>
    </div>
  );
};

export default MyPostCard;
