import React, { FC, useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { IUser } from '../../../types';
import { Skeletons } from '../../default_components';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

interface IGoogleMaps {
  profileInfo: IUser;
  showLocation: boolean;
}

const KEY: string | undefined = process.env.REACT_APP_MAPS_KEY;

const GoogleMaps: FC<IGoogleMaps> = React.memo(({ profileInfo, showLocation }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: KEY ? KEY : 'not-loaded-key',
  });

  const [selected, setSelected] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    const address = profileInfo.location.name;
    isLoaded && handleSelect(address);
  }, [isLoaded]);

  const handleSelect = (address: string) => {
    getGeocode({ address })
      .then(r => {
        const { lat, lng } = getLatLng(r[0]);
        setSelected({ lat, lng });
      })
      .catch(e => console.log(e));
  };

  if (!isLoaded || !KEY || !selected) return <Skeletons.SkeletonGoogleMaps />;
  return (
    <React.Fragment>
      {selected && KEY && (
        <GoogleMap
          zoom={10}
          center={selected}
          mapContainerStyle={containerStyle}
          options={defaultOptions}
        >
          {showLocation && selected && <Marker position={selected} />}
        </GoogleMap>
      )}
    </React.Fragment>
  );
});

export default GoogleMaps;
