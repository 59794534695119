import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Paths, localStorageKeys, routes } from './index';
import { Helmet } from 'react-helmet';
import { useGetHeadAttributes } from '../hooks/useGetHeadAttributes';
import i18next from 'i18next';
import { apiProfile } from '../services/ApiProfile';
import { useActions } from '../hooks/redux';
import { useDispatch } from 'react-redux';
import { api } from '../services/Api';

const AppRoutes = () => {
  const location = useLocation();
  const { title, description } = useGetHeadAttributes(location.pathname);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Routes>
        <Route path="/:locale" element={<MainLayout />}>
          <Route index element={<Navigate to={Paths.Dashboard} />} />
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path.replace(/^\//, '')}
              element={<route.component />}
            />
          ))}
        </Route>

        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate to={Paths.Dashboard} />} />
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path.replace(/^\//, '')}
              element={<route.component />}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;

const MainLayout = () => {
  const location = useLocation();
  const [updateProfileLocale] = apiProfile.useUpdateProfileLocaleMutation();
  const { setActiveLocale } = useActions();

  const localeFromUrl = location.pathname.match(/^\/(en|ka|ru)/);
  const locale = localeFromUrl ? localeFromUrl[1] : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (locale && i18next.language !== locale) {
      setActiveLocale(locale);
      localStorage.setItem(localStorageKeys.locale, locale);
      updateProfileLocale(locale).then(res => {
        if (res) {
          i18next.changeLanguage(locale);
          dispatch(api.util.resetApiState());
        }
      });
    }
  }, [locale]);

  return <Outlet />;
};
