import React, { FC } from 'react';
import { Slider } from '@mui/material';
import { Crop } from 'react-image-crop';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useActions, useAppSelector } from '../../../../hooks/redux';

interface ICropSlider {
  imageRef: React.RefObject<HTMLImageElement>;
  crop: Crop;
  setCrop: ActionCreatorWithPayload<Crop, 'avatar/setCrop'>;
}

const SliderStyle = {
  width: 330,
  color: '#FFFFFF',
  background: 'rgba(129,126,129,0.5)',

  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,

    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(255,255,255,0.16)',
    },
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 14px rgba(255,255,255,0.16)',
    },
  },
};

const CropSlider: FC<ICropSlider> = ({ crop, setCrop, imageRef }) => {
  const { selectedSize } = useAppSelector(state => state.avatarReducer);
  const { setSelectedSize } = useActions();

  const changeCropSizePerX = (): void => {
    if (imageRef.current) {
      const newWidth = imageRef.current.width - crop.x - 1;
      const c: Crop = {
        ...crop,
        width: newWidth,
        unit: 'px',
      };
      setSelectedSize(newWidth);
      setCrop(c);
    }
  };

  const changeCropSizePerY = (): void => {
    if (imageRef.current) {
      const newHeight = imageRef.current.height - crop.y - 1;
      const c: Crop = {
        ...crop,
        height: newHeight,
        unit: 'px',
      };
      setSelectedSize(newHeight);
      setCrop(c);
    }
  };

  const handleChange = (event: Event, newValue: number | number[]): void => {
    if (typeof newValue === 'number' && imageRef.current) {
      const defW = imageRef.current?.width - (crop.x + newValue);
      const defH = imageRef.current?.height - (crop.y + newValue);

      if (defW > 0 && defH > 0) {
        const c: Crop = {
          width: newValue,
          height: newValue,
          x: crop.x,
          y: crop.y,
          unit: 'px',
        };
        setSelectedSize(newValue);
        setCrop(c);
        return;
      }

      if (defW < 0 && defH < 0) {
        if (defW > defH) {
          changeCropSizePerY();
          return;
        } else {
          changeCropSizePerX();
          return;
        }
      }

      if (defW < 0 && defH >= 0) {
        changeCropSizePerX();
        return;
      }

      if (defH < 0 && defW >= 0) {
        changeCropSizePerY();
        return;
      }

      setSelectedSize(newValue);
    }
  };

  return (
    <Slider
      value={selectedSize}
      min={10}
      step={1}
      max={250}
      onChange={handleChange}
      size="small"
      defaultValue={50}
      aria-label="Small"
      sx={SliderStyle}
    />
  );
};

export default CropSlider;
