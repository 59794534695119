import { useState } from "react";
import { ISeveritySnackbar } from "../components/default_components/snackbar/DefaultSnackbar";

export const useMethodsSnackbar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<ISeveritySnackbar>('info');

  return {
    open,
    message,
    severity,
    getActiveSnackbar: (message: string, severity: ISeveritySnackbar) => {
      setMessage(message);
      setOpen(true);
      severity && setSeverity(severity);
    },
    closeSnackbar: () => setOpen(false)
  }
}
