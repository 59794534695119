import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BlogFeedCard from './BlogFeedCard/BlogFeedCard';
import './blogFeed.scss';
import { t } from 'i18next';
import { Icon } from '../default_components';

const BlogFeed = () => {
  const [posts, setPosts] = useState<any>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.rss2json.com/v1/api.json', {
          params: {
            rss_url: 'https://blog.gamarmarket.ge/feed/',
          },
        });
        setPosts(response.data.items);
      } catch (error) {
        console.error('Failed to fetch the feed', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="blog-feed-container">
      <div className="blog-feed-content">
        <a
          href="https://blog.gamarmarket.ge/"
          target="_blank" rel="noopener noreferrer"
          className="blog-feed-title-container"
        >
          <h3 className="blog-title">{t('translation:dashboard.blog.message')}</h3>

          <Icon type="arrowHorizontal" size={'medium-large'} />
        </a>

        <div className="blog-feed-row">
          {posts.slice(0, 4).map((post: any) => (
            <BlogFeedCard key={post.guid} item={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogFeed;
