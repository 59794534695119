import React, { FC } from 'react';
import "./postTitle.scss";
import { IPost } from "../../../../../types";
import { Skeletons } from '../../../../default_components';
import { DateTime } from "luxon";

interface IPostTitle {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostTitle: FC<IPostTitle> = ({ post, isPostFetching }) => {
  const formattedDate = (): string | undefined => {
    if (post) {
      return DateTime.fromISO(`${post.created_at}`).year === DateTime.now().year
        ? DateTime.fromISO(`${post.created_at}`).toFormat('dd LLL HH:mm')
        : DateTime.fromISO(`${post.created_at}`).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
  };

  return (
    <div className="post-title-data_frame">
      {post?.title && !isPostFetching
        ? <h1 className="post-title">{post?.title}</h1>
        : <Skeletons.SkeletonPostTitle />
      }

      {post && !isPostFetching
        ? <p className="post-creation-data">{formattedDate()}</p>
        : <Skeletons.SkeletonPostCategory />
      }
    </div>
  );
};

export default PostTitle;
