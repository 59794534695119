import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatRoom, IMessage } from '../../types';

interface ChatIdState {
  chat_id: string | undefined;
  selectedChat: IChatRoom | undefined;
  inputValue: string | undefined;
  searchQuery: string;
  cable: any;
  editMessage: IMessage | undefined;
  replyMessage: null | undefined | IMessage;
  deleteMessage: IMessage | undefined;
  chats: IChatRoom[];
}

const initialState: ChatIdState = {
  chat_id: '',
  selectedChat: undefined,
  searchQuery: '',
  editMessage: undefined,
  cable: undefined,
  replyMessage: undefined,
  deleteMessage: undefined,
  inputValue: '',
  chats: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    changeChat(state, action: PayloadAction<string | undefined>) {
      state.chat_id = action.payload;
    },
    setSelectedChat(state, action: PayloadAction<IChatRoom | undefined>) {
      state.selectedChat = action.payload;
    },
    filterUsers(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    inputAction(state, action: PayloadAction<string>) {
      state.inputValue = action.payload;
    },
    editAMessage(state, action: PayloadAction<IMessage>) {
      state.editMessage = action.payload;
    },
    replyToMessage(state: any, action: PayloadAction<IMessage>) {
      state.replyMessage = action.payload;
    },
    deleteAMessage(state, action: PayloadAction<IMessage>) {
      state.deleteMessage = action.payload;
    },
    resetContextMenuState(state) {
      state.replyMessage = initialState.replyMessage;
      state.editMessage = initialState.editMessage;
      state.deleteMessage = initialState.deleteMessage;
    },
    setCable(state, action: PayloadAction<any>) {
      state.cable = action.payload;
    },
    setChats(state, action: PayloadAction<IChatRoom[]>) {
      state.chats = action.payload;

      if (state.selectedChat) {
        const updatedChatForSelecting = action.payload.find(
          (chatRoom) => chatRoom.id === state.selectedChat?.id
        );
        if (updatedChatForSelecting) {
          state.selectedChat = updatedChatForSelecting;
        }
      }
    },
  },
});

export const chatReducer = chatSlice.reducer;
export const chatAction = chatSlice.actions;
