import { ReviewType } from '../types/reviewsTypes';

const rating_distribution = (reviews: ReviewType[]): Record<number, number> => {
  const distribution: Record<number, number> = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };

  reviews.forEach(review => {
    const roundedRating = Math.floor(review.rating);
    if (roundedRating >= 1 && roundedRating <= 5) {
      distribution[roundedRating]++;
    }
  });

  return distribution;
};

export { rating_distribution };
