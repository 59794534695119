import React, { useEffect, useState } from 'react';
import AppRoutes from './routes/AppRoutes';
import { useActions } from './hooks/redux';
import { useInitializationLocale } from './hooks/useInitializationLocale';
import { localStorageKeys } from './routes';
import * as Sentry from '@sentry/react';
import useActivateChats from './hooks/useActivateChats';

function App() {
  const { setIsAuthorized } = useActions();
  const [fontFamily, setFontFamily] = useState<string>('Noto Sans');

  useInitializationLocale();
  useActivateChats();

  useEffect(() => {
    const locale: string | 'en' | null = localStorage.getItem(localStorageKeys.locale);
    setFontFamily(locale === 'ka' ? 'BPG LE Studio Regular' : 'Noto Sans');

    const token: string | null = localStorage.getItem(localStorageKeys.token);
    setIsAuthorized(!!token);
  }, []);

  return (
    <div
      className="App"
      style={{
        fontFamily: `${fontFamily}, sans-serif`,
      }}
    >
      <AppRoutes />
    </div>
  );
}

export default Sentry.withProfiler(App);
