import React, { FC, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../default_components';
import { t } from 'i18next';
import GoogleMaps from './GoogleMaps';
import { apiProfile } from '../../../services/ApiProfile';

interface IThirdStepContent {
  showMobileNumber: boolean;
  showLocation: boolean;
  setShowMobileNumber: React.Dispatch<SetStateAction<boolean>>;
  setShowLocation: React.Dispatch<SetStateAction<boolean>>;
}

const ThirdStepContent: FC<IThirdStepContent> = ({
  showMobileNumber,
  showLocation,
  setShowMobileNumber,
  setShowLocation,
}) => {
  const { data: profileInfo } = apiProfile.useGetProfileInfoQuery();

  return (
    <div className="third-step-content">
      <div className={`third-step-card ${!showMobileNumber && 'hide-phone'}`}>
        <p>{t('translation:creation.third_step.phone_label.message')}</p>

        <p>{profileInfo?.phone}</p>

        <CheckBox
          color="black"
          value={showMobileNumber}
          setValue={setShowMobileNumber}
          label={t('translation:creation.third_step.phone_show.message')}
          dataCy="creation-third-step-phone-checkbox"
        />
      </div>

      <div className="third-step-card">
        <p>{t('translation:creation.third_step.location_label.message')}</p>

        {profileInfo?.location ? (
          <React.Fragment>
            <div className="google-maps-container">
              <GoogleMaps profileInfo={profileInfo} showLocation={showLocation} />
            </div>

            <CheckBox
              color="black"
              value={showLocation}
              setValue={setShowLocation}
              label={t('translation:creation.third_step.location_show.message')}
              dataCy="creation-third-step-location-checkbox"
            />
          </React.Fragment>
        ) : (
          <div className="without-location-info-container">
            <p>{t('translation:creation.third_step.select_location.message')}</p>
            <Link to="/my-profile">
              {t('translation:creation.third_step.move_to_profile.message')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThirdStepContent;
