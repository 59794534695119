import React, { FC } from 'react';
import { Icon } from '../index';
import './inputGamar.scss';

interface IRule {
  successfully: boolean;
  error: boolean;
  label: string;
}

const Rule: FC<IRule> = ({ successfully, error, label }) => {
  const ruleClass = `rule ${successfully && 'successful'} ${error && 'error'}`;
  const iconType = successfully ? 'tickSmall' : 'crossSmall';

  return (
    <div className={ruleClass}>
      {!successfully && !error ? <li>.</li> : <Icon type={iconType} />}

      <p>{label}</p>
    </div>
  );
};

export default Rule;
