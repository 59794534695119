import React, { FC } from 'react';
import "./filterNavigation.scss";
import FilterBreadcrumbs from './filterBreadcrumbs/FilterBreadcrumbs';
import FilterSort from './filterSort/FilterSort';
import { ISearchRequest } from '../../../../services/types';

interface IFilterHeader {
  category_id: string | undefined;
  search: ({}: ISearchRequest) => void;
  toggleDrawer: (open: boolean) => (event: (React.KeyboardEvent | React.MouseEvent)) => void
}

const FilterNavigation: FC<IFilterHeader> = ({ category_id, search, toggleDrawer }) => {
  return (
    <div className="filter-header__container">
      <FilterBreadcrumbs category_id={category_id} />
      <FilterSort search={search} toggleDrawer={toggleDrawer} />
    </div>
  );
};

export default FilterNavigation;
