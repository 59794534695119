import React, { FC, useEffect } from 'react';
import Header from '../header/Header';
import './Chat.scss';
import UserList from './user_list/UserList';
import ChatRoom from './ChatRoom';
import { useActions, useAppSelector } from '../../hooks/redux';
import { localStorageKeys } from '../../routes';

const Chat: FC = () => {
  const { selectedChat } = useAppSelector(state => state.chatReducer);
  const { setSelectedChat } = useActions();

  useEffect(() => {
    const storedChatData = localStorage.getItem(localStorageKeys.chat_storage_key);

    if (storedChatData !== 'undefined' && storedChatData) {
      const parsedChatData = JSON.parse(storedChatData);
      setSelectedChat(parsedChatData);
    }
  }, []);

  useEffect(() => {
    selectedChat &&
      localStorage.setItem(localStorageKeys.chat_storage_key, JSON.stringify(selectedChat));
  }, [selectedChat]);

  return (
    <React.Fragment>
      <Header selectedChat={selectedChat} isChat />

      <div className={`chat__container ${selectedChat && 'selected-chat'}`}>
        <UserList />
        <ChatRoom />
      </div>
    </React.Fragment>
  );
};

export default Chat;
