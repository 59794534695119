import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useActions } from '../../hooks/redux';
import { Fade, Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import './authorization.scss';

import ForgotPassword from './forgotPassword/ForgotPassword';
import Registration from './registration/Registration';
import VerifyPhone from './confirmPhone/VerifyPhone';
import CheckEmail from './checkEmail/CheckEmail';
import Login from './login/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginWrapper from './googleLoginWrapper/GoogleLoginWrapper';

interface IAuthorizationProps {
  activePopup: boolean;
  setActivePopup: React.Dispatch<SetStateAction<boolean>>;
  redirectUrl: string;
}

const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || 'key';

const Authorization: FC<IAuthorizationProps> = ({ activePopup, setActivePopup, redirectUrl }) => {
  const path = window.location.pathname;

  const authData = useSelector((state: any) => state.authorizationReducer);
  const [authType, setAuthType] = useState('LOGIN');
  const { updateOtpTimer } = useActions();

  const handleClose = () => {
    setActivePopup(false);
    authType !== 'VERIFICATION' && setAuthType('LOGIN');
  };

  useEffect(() => {
    const timeLeft = authData.otpTimeLeft;

    if (timeLeft === null) {
      localStorage.setItem('otpTimeLeft', '60');
      updateOtpTimer(localStorage.getItem('otpTimeLeft'));
    }

    if (timeLeft <= 0) {
      localStorage.setItem('otpTimeLeft', '0');
    } else {
      setTimeout(() => {
        localStorage.setItem('otpTimeLeft', `${timeLeft - 1}`);
        updateOtpTimer(localStorage.getItem('otpTimeLeft'));
      }, 1000);
    }
  }, [authData.otpTimeLeft, authType]);

  useEffect(() => {
    if (path == "/register") {
      setAuthType("REGISTRATION");
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Modal
        open={activePopup}
        onClose={handleClose}
        closeAfterTransition
        className="center"
        disableScrollLock={false}
      >
        <Fade in={activePopup}>
          <div
            className={`authorization__container ${
              authType === 'LOGIN' || authType === 'REGISTRATION' ? '' : 'small'
            }`}
          >
            {authType === 'LOGIN' && (
              <GoogleLoginWrapper>
                <Login
                  setAuthType={setAuthType}
                  handleClose={handleClose}
                  redirectUrl={redirectUrl}
                />
              </GoogleLoginWrapper>
            )}
            {authType === 'REGISTRATION' && (
              <GoogleLoginWrapper>
                <Registration
                  setAuthType={setAuthType}
                  handleClose={handleClose}
                  redirectUrl={redirectUrl}
                />
              </GoogleLoginWrapper>
            )}
            {authType === 'FORGOT_PASSWORD' && (
              <ForgotPassword setAuthType={setAuthType} handleClose={handleClose} />
            )}
            {authType === 'VERIFICATION' && (
              <VerifyPhone
                setAuthType={setAuthType}
                handleClose={handleClose}
                redirectUrl={redirectUrl}
              />
            )}
            {authType === 'CHECK_EMAIL' && (
              <CheckEmail setAuthType={setAuthType} handleClose={handleClose} />
            )}
          </div>
        </Fade>
      </Modal>
    </GoogleOAuthProvider>
  );
};

export default Authorization;
