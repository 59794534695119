import React, { useState } from 'react';
import DefaultButton from '../../../default_components/button/DefaultButton';
import { REDIRECT_URLS } from '../../../../routes';
import Authorization from '../../../auth/Authorization';
import { useAppSelector } from '../../../../hooks/redux';
import { useParams } from 'react-router-dom';
import { apiProfile } from '../../../../services/ApiProfile';
import { Icon } from '../../../default_components';
import { useGetProfileInfo } from '../../../../hooks/useGetProfileInfo';

export const SubscriptionButton = ({ isSubscribed }: { isSubscribed?: boolean }) => {
  const { userId } = useParams();

  const { isAuthorized } = useAppSelector(state => state.authReducer);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(false);
  const [deleteSubscription] = apiProfile.useDeleteSubscriptionMutation();
  const [subscribeOnUser] = apiProfile.useSubscribeOnUserMutation();

  const handleSubscribe = () => {
    if (!isAuthorized) {
      setOpenAuthPopup(true);
      return;
    }

    if (isSubscribed && userId) {
      deleteSubscription(userId);
      return;
    }

    if (!isSubscribed && userId) {
      subscribeOnUser(userId);
      return;
    }
  };

  return (
    <React.Fragment>
      <DefaultButton
        onClickAction={handleSubscribe}
        translateKey={
          isSubscribed ? 'user_profile.unsubscribe.message' : 'user_profile.subscribe.message'
        }
        styleType="accent-accent-accent-accent-gray"
        sizeType="profile-subscription"
        isToggle={isSubscribed}
      >
        <Icon type="bell" />
      </DefaultButton>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={REDIRECT_URLS.USER_PROFILE_PAGE + `${userId}`}
      />
    </React.Fragment>
  );
};
