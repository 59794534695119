import React, { Dispatch, FC, SetStateAction } from 'react';
import './thumbsSwiper.scss';
import { IPost } from '../../../../../types';
import swiper, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface IThumbsSwiper {
  post: IPost;
  setThumbsSwiper: Dispatch<SetStateAction<swiper | null | undefined>>;
}

const ThumbsSwiper: FC<IThumbsSwiper> = ({ post, setThumbsSwiper }) => {
  return (
    <div className="thumbs__wrapper">
      <div className="thumbs__container">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView={'auto'}
          watchSlidesProgress={true}
          modules={[Navigation, Thumbs]}
          className="mySwiper"
        >
          {[...post.images_variants].reverse().map((image, key) => (
            <SwiperSlide key={key} className="thumbs__swiper-slide">
              <img src={image.medium} alt={'post image preview'}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ThumbsSwiper;
