import React, {FC} from 'react';
import { Menu } from '@mui/material';
import { contextMenuStyle } from './ContextMenuStyle';

interface IContextMenu {
  children?: JSX.Element | JSX.Element[],
  openMenu: any,
  handleClose: () => void,
  anchorEl: null | HTMLElement
}

const ContextMenu:FC<IContextMenu> = ({children, openMenu, anchorEl, handleClose}) => {

  return (
    <Menu
      sx={contextMenuStyle}
      className='chat__msg-edit-menu'
      id="basic-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
};

export default ContextMenu;