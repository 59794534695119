import React, { FC, useEffect, useRef, useState } from 'react';
import './Chat.scss';
import MyMessageBox from './message_box/MyMessageBox';
import OpponentMessageBox from './message_box/OpponentMessageBox';
import MessageInput from './message_input/MessageInput';
import PostInfoBar from './product_info_bar/PostInfoBar';
import { useActions, useAppSelector } from '../../hooks/redux';
import ReplyBar from './reply_bar/ReplyBar';
import { DateTime } from 'luxon';
import { t } from 'i18next';
import { Icon } from '../default_components';
import { IMessage } from '../../types';
import { chatApi } from '../../services/chatApi';
import { useGetProfileInfo } from '../../hooks/useGetProfileInfo';

const ChatRoom: FC = () => {
  const [seenUnreadMessages] = chatApi.useSeenMessagesMutation();

  const [isReplyBarActive, setIsReplyBarActive] = useState<boolean>(false);
  const [showArrowUp, setShowArrowUp] = useState<boolean>(false);
  const [messagesContainerLoaded, setMessagesContainerLoaded] = useState(false);

  const { setChats } = useActions();
  const { selectedChat, chats } = useAppSelector(state => state.chatReducer);
  const { profileInfo } = useGetProfileInfo();

  const messagesContainer = document.getElementById('messages');
  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesContainer) {
      setMessagesContainerLoaded(true);
    }
  }, [messagesContainer]);

  useEffect(() => {
    if (messagesContainerLoaded && selectedChat && messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      resetScroll();
    }
  }, [messagesContainerLoaded, selectedChat]);

  useEffect(() => {
    if (selectedChat?.unread_messages && selectedChat.unread_messages > 0) {
      seenUnreadMessages(selectedChat.id)
        .then(result => {
          if ('data' in result && result.data.status === 'updated') {
            const updatedChats = chats.map(chat =>
              chat.id === selectedChat.id
                ? {
                    ...chat,
                    unread_messages: 0,
                    messages: chat.messages.map(message => ({
                      ...message,
                      seen: true,
                    })),
                  }
                : chat,
            );
            setChats(updatedChats);
          } else {
            console.error('Error occurred:', result);
          }
        })
        .catch(error => {
          console.error('Error occurred:', error);
        });
    }
  }, [selectedChat]);

  /*
    TODO: right now if user delete own message, both member of chat will recieve a snackbar - fix later
    const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

    useEffect(() => {
      if (logData && JSON.parse(logData)?.action === 'delete') {
        getActiveSnackbar('Message deleted', 'success');
      }
    }, [logData]);
  */

  const onScrollMessages = () => {
    const box = messagesContainer;

    if (box?.scrollHeight && box?.scrollTop && box?.offsetHeight) {
      const different = box.scrollHeight - (box.scrollTop + box.offsetHeight);
      setShowArrowUp(different > 100);
    }
  };

  const resetScroll = (): void => {
    if (!messagesContainer) return;
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };

  return (
    <div className={`chat__chat-room-container ${selectedChat && 'show'}`}>
      {selectedChat && profileInfo ? (
        <>
          <PostInfoBar selectedRoom={selectedChat} />

          <div className="chat__chat-room-messages" id="messages" onScroll={onScrollMessages}>
            <div className="chat__chat-room-message">
              {selectedChat.messages.length > 0 ? (
                <>
                  {selectedChat.messages.map((message: IMessage, index: number) => {
                    const showFullDate =
                      message.created_at &&
                      DateTime.fromISO(message.created_at).toFormat('dd LLL yyyy') !==
                        DateTime.fromISO(
                          selectedChat.messages[
                            selectedChat.messages.findIndex(
                              (messageToFind: IMessage) => messageToFind.id === message.id,
                            ) - 1
                          ]?.created_at,
                        ).toFormat('dd LLL yyyy') ? (
                        <div className="chat__chat-room-message-divider">
                          <p>
                            {DateTime.fromISO(message.created_at).toFormat('dd LLL yyyy') ===
                            DateTime.local().toFormat('dd LLL yyyy')
                              ? 'Today'
                              : DateTime.fromISO(message.created_at).toFormat('dd LLL yyyy') ===
                                DateTime.local().minus({ days: 1 }).toFormat('dd LLL yyyy')
                              ? 'Yesterday'
                              : DateTime.fromISO(message.created_at).toFormat('dd LLL')}
                          </p>
                        </div>
                      ) : null;

                    const hideAvatar =
                      index < selectedChat.messages.length - 1 &&
                      selectedChat.messages[index + 1].user_id === message.user_id;

                    return (
                      <div key={message.id}>
                        {showFullDate}
                        {message.user_id === profileInfo.id ? (
                          <MyMessageBox
                            replied_message={selectedChat.messages.find(
                              messageToFind => messageToFind.id === message.original_message_id,
                            )}
                            message={message}
                            userId={profileInfo.id}
                            interlocutor={selectedChat.interlocutor}
                            hideAvatar={hideAvatar}
                          />
                        ) : (
                          <OpponentMessageBox
                            opponent_info={selectedChat.interlocutor}
                            message={message}
                            replied_message={selectedChat.messages.find(
                              messageToFind => messageToFind.id === message.original_message_id,
                            )}
                            hideAvatar={hideAvatar}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <p style={{ margin: '200px auto' }}>
                    {t('translation:chat.write_message.message')}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div
            className={`chat__button-arrow-to-up ${showArrowUp && 'show'}`}
            onClick={resetScroll}
          >
            <Icon type="arrowVertical" />
          </div>
        </>
      ) : (
        <div className="chat__choose-chat-text">
          <p style={{ margin: '300px auto' }}>{t('translation:chat.choose_chat.message')}</p>
        </div>
      )}

      {profileInfo && selectedChat && (
        <div className="chat__chat-room-send-message__frame">
          <ReplyBar
            profileInfo={profileInfo}
            interlocutor={selectedChat.interlocutor}
            setIsReplyBarActive={setIsReplyBarActive}
          />

          <MessageInput chat_id={selectedChat.id} isReplyBarActive={isReplyBarActive} />
        </div>
      )}

      {/* <Snackbar open={open} message={message} close={closeSnackbar} severity={severity} /> */}
    </div>
  );
};

export default ChatRoom;
