import React, { ReactNode, useState, useCallback } from 'react';
import './userInfo.scss';
import { Icon, Skeletons } from '../../default_components';
import { useLocation, useParams } from 'react-router-dom';
import { apiProfile } from '../../../services/ApiProfile';
import { t } from 'i18next';
import DotsMenu from './dotsMenu/DotsMenu';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';
import DefaultSnackbar from '../../default_components/snackbar/DefaultSnackbar';
import { SubscriptionButton } from './SubscriptionButton/SubscriptionButton';
import Reviews from '../../reviews/Reviews';
import { to_date } from '../../../utils/convert';
import { ReviewUserType } from '../../../types/reviewsTypes';
import ViewReviewsModal from '../../reviews/modals/view/ViewReviewsModal';
import Skeleton from '@mui/material/Skeleton';
import { Helmet } from 'react-helmet';
import { useGetProfileInfo } from '../../../hooks/useGetProfileInfo';

interface SmallButtonWithIconProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement | null>) => void;
}

const SmallButtonWithIcon = ({ children, onClick }: SmallButtonWithIconProps) => {
  return (
    <button className={'small-btn-with-icon'} onClick={onClick}>
      {children}
    </button>
  );
};

const UserInfo = () => {
  const { userId } = useParams();
  const { state } = useLocation();
  const { profileInfo } = useGetProfileInfo();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openViewReviewsModal, setOpenViewReviewsModal] = useState(state || false);

  const { data, isLoading } = apiProfile.useGetUserProfileQuery(userId ?? '');
  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement | null>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const copyUrlToClipboard = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      getActiveSnackbar(t('user_profile.copy_link.message'), 'success');
    });
  }, [getActiveSnackbar]);

  return (
    <div className={'user-info__wrapper'}>
      <Helmet>
        <title>
          {data && !isLoading
            ? `${data?.username} ${t('translation:seo.user_page.description.message')}`
            : `${t('translation:seo.user_page.loading.message')}`}
        </title>
        <meta
          name="description"
          content={`${t('translation:seo.user_page.buy.message')} ${data?.username} ${t(
            'translation:seo.user_page.on_gamar_market.message',
          )}`}
        />
        <meta
          property="og:title"
          content={`${data?.username} ${t('translation:seo.user_page.description.message')}`}
        />
        <meta
          property="og:description"
          content={`${t('translation:seo.user_page.buy.message')} ${data?.username} ${t(
            'translation:seo.user_page.on_gamar_market.message',
          )}`}
        />
        <meta property="og:type" content="profile" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={data?.avatar || ''} />
        <meta property="profile:username" content={data?.username} />
      </Helmet>

      <div className={'user-info__container'}>
        {data?.avatar ? (
          <img src={data.avatar} alt="avatar" className="user-info__avatar" />
        ) : (
          <div className="user-info__avatar">
            <h1>{data?.username?.[0]?.toUpperCase()}</h1>
          </div>
        )}

        <div className="user-info__root-info-container">
          <div className="user-info__username-container">
            <h1 className="username">
              {!isLoading ? data?.username : <Skeleton width={100} height={26} />}
            </h1>

            <p className="location-container">
              <Icon size={'medium-small'} type={'locationMarker'} />{' '}
              {!isLoading ? (
                data?.location ? (
                  `Georgia, ${data.location.name}`
                ) : (
                  `Georgia`
                )
              ) : (
                <Skeleton width={100} height={26} />
              )}
            </p>
          </div>

          <Reviews
            user={data as ReviewUserType}
            open={openViewReviewsModal}
            setOpen={setOpenViewReviewsModal}
          />

          <ViewReviewsModal
            open={openViewReviewsModal}
            setOpen={setOpenViewReviewsModal}
            user={data as ReviewUserType}
          />
        </div>

        <div className="user-info__buttons-container">
          {!isLoading ? (
            <div className={'user-info__interaction-container'}>
              {userId !== profileInfo?.id && (
                <React.Fragment>
                  <SubscriptionButton isSubscribed={data?.subscribed} />

                  <SmallButtonWithIcon onClick={copyUrlToClipboard}>
                    <Icon type={'share'} size={'medium-small'} />
                  </SmallButtonWithIcon>
                </React.Fragment>
              )}
            </div>
          ) : (
            <Skeletons.SkeletonProfileBtns />
          )}

          {isLoading ? (
            <Skeletons.SkeletonDate />
          ) : (
            <p>
              {t('reviews.member.message')} {to_date(data?.created_at, 'inverse short')}
            </p>
          )}
        </div>
      </div>

      <DotsMenu anchorEl={anchorEl} handleClose={handleClose} />
      <DefaultSnackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </div>
  );
};

export default UserInfo;
