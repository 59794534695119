import React, { FC, useEffect, useState } from 'react';
import './filterSort.scss';
import { useActions, useAppSelector } from '../../../../../hooks/redux';
import { categoryFields } from '../../../filterOptions';
import { t, TFunction } from 'i18next';
import { ISearchRequest } from '../../../../../services/types';
import { CommonSortItemType, SortingMenu } from '../../../../myPosts';
import { Icon } from '../../../../default_components';
import { FilterSortByValueType, FilterSortByVariants } from '../../../../../types';

interface IFilterSort {
  search: ({}: ISearchRequest) => void;
  toggleDrawer: (open: boolean) => (event: (React.KeyboardEvent | React.MouseEvent)) => void;
}

const sortAssets = (t: TFunction<string[], undefined, string[]>) => [
  { value: FilterSortByVariants.POPULAR, label: t('translation:filter.popular.message'), index: 0 },
  { value: FilterSortByVariants.NEWEST, label: t('translation:filter.newest.message'), index: 1 },
  { value: FilterSortByVariants.LOW_PRICE, label: t('translation:filter.low_price.message'), index: 2 },
  { value: FilterSortByVariants.HIGH_PRICE, label: t('translation:filter.high_price.message'), index: 3 },
];

const FilterSort: FC<IFilterSort> = ({ search, toggleDrawer }) => {
  const [sort, setSort] = useState<CommonSortItemType<FilterSortByValueType>>(sortAssets(t)[0]);

  const { setSortBy, setPosts } = useActions();
  const {
    isHandmade,
    query,
    sortBy,
    categoryIds,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(
    state => state.filterReducer,
  );

  useEffect(() => {
    if (sortBy !== sort.value) {
      setSort(sortAssets(t).find(item => item.value === sortBy) || sort)
    }
  }, [sortBy]);

  const handleChange = (item: CommonSortItemType<FilterSortByValueType>) => {
    setSortBy(item.value);
    setPosts(undefined);

    const regions = Object.values(locationsStates);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: item.value,
      used: (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
        ? undefined
        : postsStates.used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    search(requestObject);
  };

  return (
    <div className="filter-sort-container">
      <p className="sort-by-text">{t('translation:filter.sort_by.message')}:</p>

      <SortingMenu assets={sortAssets(t)} sort={sort} setSort={setSort} onChange={handleChange} />

      <div className="mobile-filter-button" onClick={toggleDrawer(true)}>
        <Icon type="filter" />
      </div>
    </div>
  );
};

export default FilterSort;
