import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import UserInfo from './userInfo/UserInfo';
import './userProfile.scss';
import UserPosts from './userPosts/UserPosts';

const UserProfile = () => {
  const [click, setClick] = React.useState(0);
  const [toggle, setToggle] = React.useState(false);

  const handleClick = () => {
    setClick(click + 1);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  interface ButtonStyleState {
    state: 'default' | 'hover' | 'disabled' | 'loading' | 'toggle';
    styleClass: string;
  }

  return (
    <React.Fragment>
      <Header />

      <div className="user-profile__wrapper">
        <div className="user-profile-container">
          <UserInfo />

          <UserPosts />
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default UserProfile;
