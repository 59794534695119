import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { messageInputStyle } from './MessageInputStyle';
import sendImg from '../../../img/chat-img/send.svg';
import applyImg from '../../../img/chat-img/apply.svg';
import { chatApi } from '../../../services/chatApi';
import { useActions, useAppSelector } from '../../../hooks/redux';
import { t } from 'i18next';
import Paperclip from '../../default_components/assets/icons/Paperclip.svg';
import { Snackbar } from '../../default_components';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';
import { ImagesBar } from './ImagesBar/ImagesBar';

interface IMessageInput {
  chat_id: string;
  isReplyBarActive: boolean;
}

const max: number = 10;
const maxSize: number = 50000000;

const MessageInput: FC<IMessageInput> = ({ chat_id, isReplyBarActive }) => {
  const { replyMessage, editMessage } = useAppSelector(state => state.chatReducer);
  const { resetContextMenuState } = useActions();

  const [sendMessageToChat] = chatApi.useSendMessageMutation();
  const [editMessageRequest] = chatApi.useEditMessageMutation();
  const [replyMessageRequest] = chatApi.useReplyMessageMutation();

  const [messageText, setMessageText] = useState<string>('');
  const [images, setImages] = useState<Blob[]>([]);
  const [isApplyImage, setIsApplyImage] = useState(false);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    if (editMessage) {
      setMessageText(editMessage.text);
      setIsApplyImage(true);
    }

    if (replyMessage) {
      setIsApplyImage(true);
    }

    if (!editMessage) {
      setMessageText('');
      setIsApplyImage(false);
    }
  }, [editMessage, replyMessage]);

  const handleSendMessage = (e: React.FormEvent<HTMLInputElement>, message: string) => {
    e.preventDefault();
    const formData = new FormData();

    if (!message || !message.trim()) return;

    const commonFormData = () => {
      formData.append('text', `${message}`);
      images.map((file: Blob) => formData.append('images[]', file, 'images.jpg'));
    };

    if (editMessage) {
      commonFormData();
      editMessageRequest({ body: formData, id: editMessage.id });
    } else if (replyMessage) {
      commonFormData();
      formData.append('chat_id', `${chat_id}`);
      formData.append('original_message_id', `${replyMessage.id}`);
      replyMessageRequest({ body: formData });
    } else {
      commonFormData();
      formData.append('chat_id', `${chat_id}`);
      sendMessageToChat({ body: formData });
    }

    setImages([]);
    setIsApplyImage(false);
    setMessageText('');
    resetContextMenuState();
  };

  const uploadImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (e.target.files?.length > max)
      return getActiveSnackbar(
        t('translation:creation.second_step.overloaded_select.message'),
        'info',
      );

    const isValidImage = (file: File) =>
      ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type) && file.size < maxSize;

    const files = Object.values(e.target.files).filter(isValidImage);

    setImages([...images, ...files]);
  };

  const removeImage = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <div className="chat__chat-room-input-container">
      {images.length > 0 && <ImagesBar images={images} removeImage={removeImage} />}

      <div className={`chat__chat-room-send-message ${isReplyBarActive && 'reply-bar-active'}`}>
        <div className="upload-images-button">
          <input
            accept=".jpg,.jpeg,.png"
            id="upload_images"
            type="file"
            style={{ display: 'none' }}
            onChange={uploadImages}
          />

          <label htmlFor="upload_images">
            <img src={Paperclip} alt="paperclip" />
          </label>
        </div>

        <TextField
          value={messageText}
          sx={messageInputStyle}
          multiline
          maxRows={4}
          minRows={0}
          onChange={e => setMessageText(e.target.value)}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              handleSendMessage(e, messageText);
            }
          }}
          placeholder={`${t('translation:chat.type_message.message')}`}
          data-cy="message-with-user"
        />

        <button
          disabled={!messageText || !messageText.replace(/\s/g, '').length}
          onClick={(e: any) => {
            handleSendMessage(e, messageText);
          }}
          type="submit"
          className="chat__chat-room-send__input-submit"
          data-cy="button-submit"
        >
          <img src={isApplyImage ? applyImg : sendImg} alt="send" />
        </button>

        <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
      </div>
    </div>
  );
};

export default MessageInput;
