import { useEffect, useState } from "react";
import { t } from "i18next";

export const useGetHeadAttributes = (location: string): { title: string, description: string } => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");



  useEffect(() => {
    switch (location) {
      case ("/dashboard") : {
        setTitle(`${t('translation:seo.title.message')}`);
        setDescription(`${t('translation:seo.description.message')}`)
        return
      }

      default: {
        setTitle(`${t('translation:seo.title.message')}`);
        setDescription(`${t('translation:seo.description.message')}`)
        return
      }
    }
  }, [location])

  return {
    title,
    description
  }
};
