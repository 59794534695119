export const searchInputStyle = {
  width: '100%',

  '.MuiInputBase-root': {
    height: 40,
    borderRadius: '10px !important',
    border: 'transparent !important',
    fontFamily: 'Noto Sans, sans serif !important',
    fontSize: '14px !important',
    lineHeight: '22 !important',

    color: '#110B11 !important',
    opacity: '0.8 !important',
    background: ' #ffffff',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #F0F0F0 !important',
    borderRadius: '10px !important',
    transition: 'all .2s ease-in-out',
  },

  '.Icon': {
    opacity: '0.3'
  }
};
