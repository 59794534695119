import React, { FC } from 'react';
import './categoriesListFilter.scss';
import { useTranslation } from 'react-i18next';
import { ICategory } from '../../../../types';
import { api } from '../../../../services/Api';
import { useNavigate } from 'react-router-dom';
import { getPostCountInCategory } from '../../../../utils/get';
import { useActions } from '../../../../hooks/redux';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';
import { useLocaleFromUrl } from '../../../../utils/localeFromUrl';

interface ICategoriesListFilter {
  selectedCategory: ICategory | undefined;
}

const CategoriesListFilter: FC<ICategoriesListFilter> = ({ selectedCategory }) => {
  const { data: rootCategories } = api.useGetCategoriesQuery();
  const localeStr = useLocaleFromUrl();

  const { t } = useTranslation();
  const { setQuery, setIsHandmade } = useActions();
  const navigateWithLocale = useNavigateWithLocale();

  const handleClickOnCategory = (e: React.MouseEvent, clickedCategory: ICategory) => {
    e.preventDefault();
    setQuery('');
    setIsHandmade(false);
    navigateWithLocale(`/category/${clickedCategory.id}`);
  };

  const handleMoveToAllPosts = (e: React.MouseEvent): void => {
    e.preventDefault();
    setQuery('');
    setIsHandmade(false);
    navigateWithLocale(`/category/all`);
  };

  const isShowSubCategories = (category: ICategory): boolean => {
    return category.id == selectedCategory?.id || category.id == selectedCategory?.parents[0]?.id;
  };

  if (rootCategories) {
    return (
      <div>
        <h2 className="categories-list_title">{t('translation:filter.categories.message')}</h2>

        <a
          href={localeStr ? `${localeStr}/category/all` : `/category/all`}
          onClick={handleMoveToAllPosts}
          className={`categories-list_item ${!selectedCategory && 'active'}`}
          style={{ textDecoration: 'none' }}
        >
          {t('translation:breadcrumbs.all_products.message')}
        </a>

        <div className="categories-list">
          {rootCategories.map((category: ICategory) => (
            <div key={category.id}>
              <a
                href={
                  localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`
                }
                onClick={e => handleClickOnCategory(e, category)}
                className={`categories-list_item ${
                  category.id === selectedCategory?.id && 'active'
                }`}
                style={{ textDecoration: 'none' }}
              >
                {category.name}&nbsp;
                {getPostCountInCategory(category) > 0 && (
                  <span>({getPostCountInCategory(category)})</span>
                )}
              </a>

              {category.children && isShowSubCategories(category) && (
                <div className="categories-list">
                  {category.children.map(subCategory => (
                    <a
                      key={subCategory.id}
                      href={
                        localeStr
                          ? `${localeStr}/category/${category.id}`
                          : `/category/${category.id}`
                      }
                      onClick={e => handleClickOnCategory(e, subCategory)}
                      className={`categories-list_item ${
                        subCategory.id === selectedCategory?.id && 'active'
                      }`}
                      style={{ textDecoration: 'none' }}
                    >
                      {subCategory.name}&nbsp;
                      {getPostCountInCategory(subCategory) > 0 && (
                        <span>({getPostCountInCategory(subCategory)})</span>
                      )}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return <div />;
};

export default CategoriesListFilter;
