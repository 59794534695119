import React, { FC } from 'react';
import './saveButton.scss';
import { t } from 'i18next';
import { Loader } from '../../../default_components';

interface ISaveButton {
  isLoading: boolean;
  isCanSave: boolean;
  handleOnSave: () => void;
}

const SaveButton: FC<ISaveButton> = ({ isLoading, isCanSave, handleOnSave }) => {
  return (
    <div className="button-save_frame">
      <div className="button-save_container">
        <button className={`button-save ${isCanSave && 'active'}`} onClick={handleOnSave}>
          {isLoading ? <Loader isWhite /> : t('translation:profile.save.message')}
        </button>
      </div>
    </div>
  );
};

export default SaveButton;
