import React, { FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import * as Style from './SkeletonsStyle';
import './skeletons.scss';

export const SkeletonCategoryButton = () => {
  return <Skeleton sx={Style.SkeletonCategoryButtons} animation="wave" variant="rectangular" />;
};

export const SkeletonCategoryCard = () => {
  return (
    <div className="category-card_box">
      <div className="category-card">
        <div className="category-title">
          <Skeleton sx={Style.SkeletonCategoryCardText} animation="wave" variant="text" />
        </div>

        <div className="image_frame">
          <Skeleton sx={Style.SkeletonCategoryCardImage} animation="wave" variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export const SkeletonCategoryCardImage = () => (
  <Skeleton sx={Style.SkeletonCategoryCardImage} animation="wave" variant="rectangular" />
);

export const SkeletonPostCard: FC<{ filter?: boolean; myPosts?: boolean }> = ({
  filter,
  myPosts,
}) => {
  return (
    <div className={`post-card_frame ${filter && 'is-filter'} ${myPosts && 'is-my-posts'}`}>
      <div className="post-card-image_container">
        <div className="post-card-image_wrapper">
          <Skeleton sx={Style.SkeletonPostCardImage} animation="wave" variant="rounded" />
        </div>
      </div>

      <div className="post-card-content_container">
        <Skeleton sx={Style.SkeletonPostCardPrice} animation="wave" variant="text" />
        <Skeleton sx={Style.SkeletonPostCardState} animation="wave" variant="text" />
      </div>

      <div className="post-card-title">
        <Skeleton sx={Style.SkeletonPostCardTitle} animation="wave" variant="text" />
      </div>
    </div>
  );
};

export const SkeletonImagePostCard = () => (
  <Skeleton sx={Style.SkeletonPostCardImage} animation="wave" variant="rectangular" />
);

/*** below skeletons for creation page ***/
export const SkeletonImageCard = () => (
  <Skeleton sx={Style.SkeletonCategoryCardImage} animation="wave" variant="rectangular" />
);

export const SkeletonSmallPicture = () => (
  <Skeleton sx={Style.SkeletonSmallPicture} animation="wave" variant="rectangular" />
);

/*** below skeletons for product page ***/
export const SkeletonPostCategory = () => (
  <Skeleton sx={Style.SkeletonPostCategory} animation="wave" variant="rounded" />
);

export const SkeletonPostTitle = () => (
  <Skeleton sx={Style.SkeletonPostTitle} animation="wave" variant="rounded" />
);

export const SkeletonPostPrice = () => (
  <Skeleton sx={Style.SkeletonPostPrice} animation="wave" variant="rounded" />
);

export const SkeletonPostLocation = () => (
  <Skeleton sx={Style.SkeletonPostLocation} animation="wave" variant="rounded" />
);

export const SkeletonPostAvatar = () => {
  return (
    <div className="skeleton-post-avatar-container">
      <Skeleton sx={Style.SkeletonPostAvatarCircle} animation="wave" variant="rounded" />
      <Skeleton sx={Style.SkeletonPostAvatarName} animation="wave" variant="rounded" />
    </div>
  );
};

export const SkeletonPostCallBtns = () => {
  return (
    <div className="skeleton-call-btns-container">
      <Skeleton sx={Style.SkeletonMessageCallButton} animation="wave" variant="rounded" />
      <Skeleton sx={Style.SkeletonMessageCallButton} animation="wave" variant="rounded" />
    </div>
  );
};

export const SkeletonPostImagesContainer = () => {
  return (
    <div className="skeleton-post-images-container">
      <Skeleton sx={Style.SkeletonPostSlider} animation="wave" variant="rounded" />

      <div style={{ display: 'flex', gap: '20px' }}>
        <Skeleton sx={Style.SkeletonPostThumb} animation="wave" variant="rounded" />
        <Skeleton sx={Style.SkeletonPostThumb} animation="wave" variant="rounded" />
        <Skeleton sx={Style.SkeletonPostThumb} animation="wave" variant="rounded" />
        <Skeleton sx={Style.SkeletonPostThumb} animation="wave" variant="rounded" />
      </div>

      <Skeleton sx={Style.SkeletonPostDots} animation="wave" variant="rounded" />
    </div>
  );
};

export const SkeletonPostRootImage = () => (
  <Skeleton sx={Style.SkeletonPostSlider} animation="wave" variant="rounded" />
);

export const SkeletonPostThumb = () => (
  <Skeleton sx={Style.SkeletonPostThumb} animation="wave" variant="rounded" />
);

export const SkeletonPostDescription = () => (
  <Skeleton sx={Style.SkeletonPostDescription} animation="wave" variant="rounded" />
);

export const SkeletonPhoneNumber = () => (
  <Skeleton sx={Style.SkeletonPhoneNumber} animation="wave" variant="rounded" />
);

export const SkeletonGoogleMaps = () => (
  <Skeleton sx={Style.SkeletonGoogleMaps} animation="wave" variant="rounded" />
);

export const SkeletonUserAvatar = () => (
  <Skeleton sx={Style.SkeletonUserAvatar} animation="wave" variant="rounded" />
);

export const SkeletonUsername = () => (
  <Skeleton sx={Style.Skeleton__PublicProfile__Username} animation="wave" variant="rounded" />
);

export const SkeletonLocation = () => (
  <Skeleton sx={Style.Skeleton__PublicProfile__Location} animation="wave" variant="rounded" />
);

export const SkeletonDate = () => (
  <Skeleton sx={Style.SkeletonDate} animation="wave" variant="rounded" />
);

export const SkeletonProfileBtns = () => (
  <div className={'skeleton-user-interaction-container'}>
    <Skeleton sx={Style.SkeletonSubscribe} animation="wave" variant="rounded" />

    <Skeleton sx={Style.SkeletonSmallBtn} animation="wave" variant="rounded" />
  </div>
);

export const SkeletonRatingDigits = () => (
  <Skeleton sx={Style.SkeletonRatingDigits} animation="wave" variant="rounded" />
);

export const SkeletonRatingStars = () => (
  <Skeleton sx={Style.SkeletonRatingStars} animation="wave" variant="rounded" />
);

export const SkeletonReviewLink = () => (
  <Skeleton sx={Style.SkeletonReviewLink} animation="wave" variant="rounded" />
);
