import React, { useEffect, useState } from 'react';
import { MyPostsTabStyle } from '../default_components/tabPanel/TabPanelStyle';
import { MyPostCard } from '../default_components';
import { t } from 'i18next';
import { Badge, Tab, Tabs } from '@mui/material';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import {
  CommonSortItemType,
  CustomBadgeStyles,
  MyPostCardHorizontalSkeletonStyle,
  MyPostCardSkeletonStyle,
  NoPosts,
  postsStatus,
  postsStatuses,
  SortingMenu,
  sortVariants,
} from '.';
import './myPosts.scss';
import { sortModule } from '../../utils/sort';
import { api } from '../../services/Api';
import Skeleton from '@mui/material/Skeleton';
import { IPost } from '../../types';
import { localStorageKeys } from '../../routes';

import ListViewBlack from '../default_components/assets/icons/ListViewBlack.svg'
import ListViewRed from '../default_components/assets/icons/ListViewRed.svg'
import GridViewBlack from '../default_components/assets/icons/GridViewBlack.svg'
import GridViewRed from '../default_components/assets/icons/GridViewRed.svg'
import { DealModal } from './DealsModal/DealModal';
import NoticeBlock from '../default_components/noticeBlock/NoticeBlock';

type postsViewLayout = "list" | "grid";

const MyPosts = () => {
  const { data: posts, isLoading } = api.useGetMyPostsQuery();

  const [sort, setSort] = useState<CommonSortItemType<string>>(sortVariants[0]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentPostsStatus, setCurrentPostsStatus] = useState<postsStatus>(postsStatuses[0]);
  const [postsView, setPostsView] = useState<postsViewLayout>('list');

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedPostForDeleting, setSelectedPostForDeleting] = useState<IPost | undefined>();

  useEffect(() => {
    window.scrollTo(0, 0);

    const storedPostsView = localStorage.getItem(localStorageKeys.my_posts_view);
    if (storedPostsView && ['list', 'grid'].includes(storedPostsView)) {
      setPostsView(storedPostsView as postsViewLayout);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.my_posts_view, postsView);
  }, [postsView]);

  const postsToShow = posts?.filter(post => post.status == currentPostsStatus?.value);
  const isListView = postsView === 'list';
  const isGridView = postsView === 'grid';

  const handleChangePostsStatus = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const postsStatus = postsStatuses.find(view => view.index === newValue);
    postsStatus && setCurrentPostsStatus(postsStatus);
  };

  const handleOpenDeleteModal = (post: IPost): void => {
    setOpenDeleteModal(true);
    setSelectedPostForDeleting(post);
  };

  const getPostsCount = (status: postsStatus): number => {
    return posts?.filter(post => post.status === status?.value).length ?? 0;
  };

  return (
    <React.Fragment>
      <Header />

      <div className="my-posts__container">
        <Tabs
          value={tabValue}
          onChange={handleChangePostsStatus}
          sx={MyPostsTabStyle}
          variant="scrollable"
        >
          {postsToShow && postsStatuses.map(item => (
            <Tab
              key={item.index}
              label={
                <React.Fragment>
                  <p>{t(`translation:my_posts.${item.label}.message`)}</p>
                  {getPostsCount(item) > 0 && (
                    <Badge badgeContent={getPostsCount(item)} sx={CustomBadgeStyles} />
                  )}
                </React.Fragment>
              }
            />
          ))}
        </Tabs>

        <div className="my-posts__sort-tools__container">
          <SortingMenu assets={sortVariants} sort={sort} setSort={setSort} />

          <div className="toggle-view-container">
            <div
              className={`list-view-button ${isListView && 'active'}`}
              onClick={() => setPostsView('list')}
            >
              <img src={isListView ? ListViewRed : ListViewBlack} alt="List View" />
            </div>

            <div
              className={`grid-view-button ${isGridView && 'active'}`}
              onClick={() => setPostsView('grid')}
            >
              <img src={isGridView ? GridViewRed : GridViewBlack} alt="Grid View" />
            </div>
          </div>
        </div>

        {tabValue === 2 && postsToShow && postsToShow?.length > 0 && (
          <NoticeBlock
            typeIcon='warning'
            textElement={
              <div>
                <p>{t('translation:my_posts.rejected-info.before_link.message')}</p>
                <a href="">{t('translation:my_posts.rejected-info.link.message')}</a>
                <p>{t('translation:my_posts.rejected-info.after_link.message')}</p>
              </div>
            }
          />
        )}

        <div className="my-posts__cards-container">
          {isLoading &&
            [1, 2, 3, 4, 5, 6].map(id => (
              <Skeleton
                key={id}
                sx={postsView === 'grid' ? MyPostCardSkeletonStyle : MyPostCardHorizontalSkeletonStyle}
                variant={'rounded'}
              />
            ))}

          {postsToShow && !isLoading && sortModule(postsToShow, sort.value).map(post => (
            <MyPostCard
              key={post.id}
              post={post}
              currentPostsStatus={currentPostsStatus}
              postsView={postsView}
              clickOnDelete={handleOpenDeleteModal}
            />
          ))}

          {postsToShow?.length === 0 && <NoPosts index={tabValue} currentPostsStatus={currentPostsStatus} />}
        </div>
      </div>

      <DealModal
        activeModal={openDeleteModal}
        setActiveModal={setOpenDeleteModal}
        post={selectedPostForDeleting}
      />

      <Footer />
    </React.Fragment>
  );
};

export default MyPosts;
