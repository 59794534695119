import React, { FC, useState } from 'react';
import '../filterOptions.scss';
import { ILocation } from '../../../../types';
import { t } from 'i18next';
import { Checkbox, Collapse, FormControlLabel, RadioGroup } from '@mui/material';
import {
  categoryFields,
  CheckedElementStyle,
  FilterCollapseStyle,
  getShowMoreOptionsTest,
  RadioGroupStyle,
} from '../index';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ISearchRequest } from '../../../../services/types';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';

interface ILocationSelect {
  locations: ILocation[];
  search: ({}: ISearchRequest) => void;
}

const LocationSelect: FC<ILocationSelect> = ({ locations, search }) => {
  const { setLocationsStates, setPosts } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(state => state.filterReducer);

  const { updateQueryParams } = useUpdateQueryParams();

  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regions = { ...locationsStates };

    if (event.target.checked) {
      setPosts(undefined);
      regions[event.target.name] = event.target.id;
    } else {
      delete regions[event.target.name];
    }
    setLocationsStates(regions);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    requestObject.location_id = Object.values(regions).length ? Object.values(regions) : undefined;

    updateQueryParams({
      page: currentPage,
      used: postsStates.used,
      new: postsStates.new,
      sort_by: sortBy,
      handmade: isHandmade,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
      locations: Object.values(regions),
      fields: encodeURIComponent(JSON.stringify(categoryFields(fieldsStates))),
    });

    search(requestObject);
  };

  const getLocationsPortion = (range: number[]) => {
    return locations
      .slice(...range)
      .map(location => (
        <FormControlLabel
          key={location.id}
          control={
            <Checkbox
              checked={Boolean(locationsStates[location.name]) || false}
              onChange={handleChange}
              name={location.name}
              id={location.id}
              sx={CheckedElementStyle}
            />
          }
          label={location.name}
        />
      ));
  };

  return (
    <div>
      <p className="filter-menu__title">{t('translation:filter.region.message')}</p>

      <RadioGroup sx={RadioGroupStyle}>{getLocationsPortion([0, 4])}</RadioGroup>

      <Collapse in={showMoreOptions} timeout="auto" sx={FilterCollapseStyle}>
        {getLocationsPortion([4])}
      </Collapse>

      <p className="show-more-options" onClick={() => setShowMoreOptions(!showMoreOptions)}>
        {t(getShowMoreOptionsTest(showMoreOptions))}
      </p>
    </div>
  );
};

export default LocationSelect;
