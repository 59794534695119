import React from 'react';
import { ReactComponent as ArrowBackModal } from '../assets/icons/ArrowBackModal.svg';
import { ReactComponent as ArrowHorizontal } from '../assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowVertical } from '../assets/icons/ArrowVertical.svg';
import { ReactComponent as Bell } from '../assets/icons/Bell.svg';
import { ReactComponent as BlackLogOut } from '../assets/icons/BlackLogOut.svg';
import { ReactComponent as BlackPosts } from '../assets/icons/BlackPosts.svg';
import { ReactComponent as BlackPurchases } from '../assets/icons/BlackPurchases.svg';
import { ReactComponent as Catalogue } from '../assets/icons/Catalogue.svg';
import { ReactComponent as Chat } from '../assets/icons/Chat.svg';
import { ReactComponent as ChatActive } from '../assets/icons/ChatActive.svg';
import { ReactComponent as ChatActiveHaveMessage } from '../assets/icons/ChatActiveHaveMessage.svg';
import { ReactComponent as ChatCount } from '../assets/icons/ChatCount.svg';
import { ReactComponent as ChatHaveMessage } from '../assets/icons/ChatHaveMessage.svg';
import { ReactComponent as ChatWhiteIcon } from '../assets/icons/ChatWhiteIcon.svg';
import { ReactComponent as Cross } from '../assets/icons/Cross.svg';
import { ReactComponent as CrossDefault } from '../assets/icons/CrossDefault.svg';
import { ReactComponent as CrossDefaultRed } from '../assets/icons/CrossDefaultRed.svg';
import { ReactComponent as CrossForModal } from '../assets/icons/CrossForModal.svg';
import { ReactComponent as CrossSmall } from '../assets/icons/CrossSmall.svg';
import { ReactComponent as CrossSmallForModal } from '../assets/icons/CrossSmallForModal.svg';
import { ReactComponent as Delete } from '../assets/icons/Delete.svg';
import { ReactComponent as DeleteRedIcon } from '../assets/icons/DeleteRedIcon.svg';
import { ReactComponent as DotsMenu } from '../assets/icons/DotsMenu.svg';
import { ReactComponent as Email } from '../assets/icons/Email.svg';
import { ReactComponent as EN } from '../assets/icons/EN.svg';
import { ReactComponent as EyeHide } from '../assets/icons/EyeHide.svg';
import { ReactComponent as EyeShow } from '../assets/icons/EyeShow.svg';
import { ReactComponent as Facebook } from '../assets/icons/Facebook.svg';
import { ReactComponent as FacebookCircle } from '../assets/icons/FacebookCircle.svg';
import { ReactComponent as FileUploaded } from '../assets/icons/FileUploaded.svg';
import { ReactComponent as FileUploading } from '../assets/icons/FileUploading.svg';
import { ReactComponent as Filter } from '../assets/icons/Filter.svg';
import { ReactComponent as GEL } from '../assets/icons/GEL.svg';
import { ReactComponent as GE } from '../assets/icons/GE.svg';
import { ReactComponent as GlobeIcon } from '../assets/icons/Globe.svg';
import { ReactComponent as GoogleCircle } from '../assets/icons/GoogleCircle.svg';
import { ReactComponent as ImageNotFound } from '../assets/icons/ImageNotFound.svg';
import { ReactComponent as Instagram } from '../assets/icons/Instagram.svg';
import { ReactComponent as Like } from '../assets/icons/Like.svg';
import { ReactComponent as LikeCount } from '../assets/icons/LikeCount.svg';
import { ReactComponent as LocationMarker } from '../assets/icons/LocationMarker.svg';
import { ReactComponent as Logo } from '../assets/icons/Logo.svg';
import { ReactComponent as Menu } from '../assets/icons/Menu.svg';
import { ReactComponent as Pencil } from '../assets/icons/Pencil.svg';
import { ReactComponent as PencilBlackColor } from '../assets/icons/PencilBlackColor.svg';
import { ReactComponent as Phone } from '../assets/icons/Phone.svg';
import { ReactComponent as PhoneCount } from '../assets/icons/PhoneCount.svg';
import { ReactComponent as Plus } from '../assets/icons/Plus.svg';
import { ReactComponent as RedGlobe } from '../assets/icons/RedGlobe.svg';
import { ReactComponent as RedHeart } from '../assets/icons/RedHeart.svg';
import { ReactComponent as Report } from '../assets/icons/Report.svg';
import { ReactComponent as RU } from '../assets/icons/RU.svg';
import { ReactComponent as Search } from '../assets/icons/Search.svg';
import { ReactComponent as Settings } from '../assets/icons/Settings.svg';
import { ReactComponent as Share } from '../assets/icons/Share.svg';
import { ReactComponent as SortMenuBlack } from '../assets/icons/SortMenuBlack.svg';
import { ReactComponent as SortMenuRed } from '../assets/icons/SortMenuRed.svg';
import { ReactComponent as StarGrayEmpty } from '../assets/icons/StarGrayEmpty.svg';
import { ReactComponent as StarBlackOutline } from '../assets/icons/StarBlackOutline.svg';
import { ReactComponent as StarRedFull } from '../assets/icons/StarRedFull.svg';
import { ReactComponent as StarRedHalf } from '../assets/icons/StarRedHalf.svg';
import { ReactComponent as StarRedOutline } from '../assets/icons/StarRedOutline.svg';
import { ReactComponent as Tiktok } from '../assets/icons/Tiktok.svg';
import { ReactComponent as Tick } from '../assets/icons/Tick.svg';
import { ReactComponent as TickSmall } from '../assets/icons/TickSmall.svg';
import { ReactComponent as Upload } from '../assets/icons/Upload.svg';
import { ReactComponent as UploadArrow } from '../assets/icons/UploadArrow.svg';
import { ReactComponent as USD } from '../assets/icons/USD.svg';
import { ReactComponent as User } from '../assets/icons/User.svg';
import { ReactComponent as UserActive } from '../assets/icons/UserActive.svg';
import { ReactComponent as ViewCount } from '../assets/icons/ViewCount.svg';
import { ReactComponent as Warning } from '../assets/icons/Warning.svg';
import { ReactComponent as WhiteChat } from '../assets/icons/WhiteChat.svg';
import { ReactComponent as WhiteCoinGel } from '../assets/icons/WhiteCoinGel.svg';
import { ReactComponent as WhiteCoinUsd } from '../assets/icons/WhiteCoinUsd.svg';
import { ReactComponent as WhiteCrossSmall } from '../assets/icons/WhiteCrossSmall.svg';
import { ReactComponent as WhiteGel } from '../assets/icons/WhiteGel.svg';
import { ReactComponent as WhiteGlobe } from '../assets/icons/WhiteGlobe.svg';
import { ReactComponent as WhiteLike } from '../assets/icons/WhiteLike.svg';
import { ReactComponent as WhiteLogIn } from '../assets/icons/WhiteLogIn.svg';
import { ReactComponent as WhiteLogOut } from '../assets/icons/WhiteLogOut.svg';
import { ReactComponent as WhiteMiniArrow } from '../assets/icons/WhiteMiniArrow.svg';
import { ReactComponent as WhitePosts } from '../assets/icons/WhitePosts.svg';
import { ReactComponent as WhitePurchases } from '../assets/icons/WhitePurchases.svg';
import { ReactComponent as WhiteUser } from '../assets/icons/WhiteUser.svg';
import { ReactComponent as WhiteUsd } from '../assets/icons/WhiteUsd.svg';
import { ReactComponent as Zoom } from '../assets/icons/Zoom.svg';
import { ReactComponent as ZoomArrow } from '../assets/icons/ZoomArrow.svg';

export type IconType =
  | 'arrowBackModal'
  | 'arrowHorizontal'
  | 'arrowVertical'
  | 'bell'
  | 'blackLogOut'
  | 'blackPosts'
  | 'blackPurchases'
  | 'catalogue'
  | 'chat'
  | 'chatActive'
  | 'chatActiveHaveMessage'
  | 'chatCount'
  | 'chatHaveMessage'
  | 'chatWhiteIcon'
  | 'cross'
  | 'crossDefault'
  | 'crossDefaultRed'
  | 'crossForModal'
  | 'crossSmall'
  | 'crossSmallForModal'
  | 'deleteRedIcon'
  | 'deleteType'
  | 'dotsMenu'
  | 'email'
  | 'en'
  | 'eyeHide'
  | 'eyeShow'
  | 'facebook'
  | 'facebookCircle'
  | 'fileUploaded'
  | 'fileUploading'
  | 'filter'
  | 'gel'
  | 'globeIcon'
  | 'googleCircle'
  | 'imageNotFound'
  | 'instagram'
  | 'ka'
  | 'like'
  | 'likeCount'
  | 'locationMarker'
  | 'logo'
  | 'menu'
  | 'phone'
  | 'phoneCount'
  | 'pencil'
  | 'pencilBlackColor'
  | 'plus'
  | 'redGlobe'
  | 'redHeart'
  | 'report'
  | 'ru'
  | 'search'
  | 'settings'
  | 'share'
  | 'sortMenuBlack'
  | 'sortMenuRed'
  | 'starBlackOutline'
  | 'starGrayEmpty'
  | 'starRedFull'
  | 'starRedHalf'
  | 'starRedOutline'
  | 'tiktok'
  | 'tick'
  | 'tickSmall'
  | 'upload'
  | 'uploadArrow'
  | 'usd'
  | 'user'
  | 'userActive'
  | 'viewCount'
  | 'warning'
  | 'whiteChat'
  | 'whiteCoinGel'
  | 'whiteCoinUsd'
  | 'whiteCrossSmall'
  | 'whiteGel'
  | 'whiteUsd'
  | 'whiteGlobe'
  | 'whiteLike'
  | 'whiteLogIn'
  | 'whiteLogOut'
  | 'whiteMiniArrow'
  | 'whitePosts'
  | 'whitePurchases'
  | 'whiteUser'
  | 'zoom'
  | 'zoomArrow';

export const iconTypes = new Map([
  ['arrowBackModal', <ArrowBackModal />],
  ['arrowHorizontal', <ArrowHorizontal />],
  ['arrowVertical', <ArrowVertical />],
  ['bell', <Bell />],
  ['blackLogOut', <BlackLogOut />],
  ['blackPosts', <BlackPosts />],
  ['blackPurchases', <BlackPurchases />],
  ['catalogue', <Catalogue />],
  ['chat', <Chat />],
  ['chatActive', <ChatActive />],
  ['chatActiveHaveMessage', <ChatActiveHaveMessage />],
  ['chatCount', <ChatCount />],
  ['chatHaveMessage', <ChatHaveMessage />],
  ['chatWhiteIcon', <ChatWhiteIcon />],
  ['cross', <Cross />],
  ['crossDefault', <CrossDefault />],
  ['crossDefaultRed', <CrossDefaultRed />],
  ['crossForModal', <CrossForModal />],
  ['crossSmall', <CrossSmall />],
  ['crossSmallForModal', <CrossSmallForModal />],
  ['deleteRedIcon', <DeleteRedIcon />],
  ['deleteType', <Delete />],
  ['dotsMenu', <DotsMenu />],
  ['email', <Email />],
  ['en', <EN />],
  ['eyeHide', <EyeHide />],
  ['eyeShow', <EyeShow />],
  ['facebook', <Facebook />],
  ['facebookCircle', <FacebookCircle />],
  ['fileUploaded', <FileUploaded />],
  ['fileUploading', <FileUploading />],
  ['filter', <Filter />],
  ['gel', <GEL />],
  ['globeIcon', <GlobeIcon />],
  ['googleCircle', <GoogleCircle />],
  ['imageNotFound', <ImageNotFound />],
  ['instagram', <Instagram />],
  ['ka', <GE />],
  ['like', <Like />],
  ['likeCount', <LikeCount />],
  ['locationMarker', <LocationMarker />],
  ['logo', <Logo />],
  ['menu', <Menu />],
  ['pencil', <Pencil />],
  ['pencilBlackColor', <PencilBlackColor />],
  ['phone', <Phone />],
  ['phoneCount', <PhoneCount />],
  ['plus', <Plus />],
  ['redGlobe', <RedGlobe />],
  ['redHeart', <RedHeart />],
  ['report', <Report />],
  ['ru', <RU />],
  ['search', <Search />],
  ['settings', <Settings />],
  ['share', <Share />],
  ['sortMenuBlack', <SortMenuBlack />],
  ['sortMenuRed', <SortMenuRed />],
  ['starGrayEmpty', <StarGrayEmpty />],
  ['starBlackOutline', <StarBlackOutline />],
  ['starRedFull', <StarRedFull />],
  ['starRedHalf', <StarRedHalf />],
  ['starRedOutline', <StarRedOutline />],
  ['tick', <Tick />],
  ['tickSmall', <TickSmall />],
  ['tiktok', <Tiktok />],
  ['upload', <Upload />],
  ['uploadArrow', <UploadArrow />],
  ['usd', <USD />],
  ['user', <User />],
  ['userActive', <UserActive />],
  ['viewCount', <ViewCount />],
  ['warning', <Warning />],
  ['whiteChat', <WhiteChat />],
  ['whiteCoinGel', <WhiteCoinGel />],
  ['whiteCoinUsd', <WhiteCoinUsd />],
  ['whiteCrossSmall', <WhiteCrossSmall />],
  ['whiteGel', <WhiteGel />],
  ['whiteGlobe', <WhiteGlobe />],
  ['whiteLike', <WhiteLike />],
  ['whiteLogIn', <WhiteLogIn />],
  ['whiteLogOut', <WhiteLogOut />],
  ['whiteMiniArrow', <WhiteMiniArrow />],
  ['whitePosts', <WhitePosts />],
  ['whitePurchases', <WhitePurchases />],
  ['whiteUsd', <WhiteUsd />],
  ['whiteUser', <WhiteUser />],
  ['zoom', <Zoom />],
  ['zoomArrow', <ZoomArrow />],
]);
