import React, { FC, Dispatch, SetStateAction } from 'react';
import './catalogContent.scss';
import CatalogRoute from './CatalogRoute';

interface ICatalogContent {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CatalogContent: FC<ICatalogContent> = ({ open, setOpen }) => {
  return (
    <div className="panel-catalog" onClick={() => setOpen(false)}>
      <div className={`overlap_${open ? 'active' : 'disabled'}`}>
        <div className={`catalog-content_frame_${open ? 'active' : 'disabled'}`}>
          <div className="catalog-content_container" onClick={e => e.stopPropagation()}>
            <CatalogRoute open={open} setOpen={setOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogContent;
