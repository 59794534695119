import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ConfirmNewPassObject, SendObjectWithToken, UpdateProfileInfoObject } from './types';
import { IUser } from '../types';
import basicAuthHeaders, { ApiUrl } from '.';

export const apiProfile = createApi({
  reducerPath: 'apiProfile',
  baseQuery: fetchBaseQuery({ baseUrl: ApiUrl }),
  tagTypes: ['Profile', 'PasswordStore', 'UserProfile'],
  endpoints: build => ({
    getProfileInfo: build.query<IUser, void>({
      query: () => ({
        url: `/profile`,
        headers: basicAuthHeaders(),
      }),
      providesTags: ['Profile'],
    }),

    updateProfileInfo: build.mutation<IUser, UpdateProfileInfoObject>({
      query: data => ({
        url: `/profile`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body: {
          username: data.username,
          phone: data.phone,
          location_id: data.location_id,
        },
      }),
      invalidatesTags: ['Profile'],
    }),

    updateProfileLocale: build.mutation<IUser, string>({
      query: locale => ({
        url: `/profile`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body: {
          locale,
        },
      }),
    }),

    updateProfileCurrency: build.mutation<IUser, string>({
      query: currency => ({
        url: `/profile`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body: {
          currency,
        },
      }),
    }),

    updateEmail: build.mutation<void, string>({
      query: email => ({
        url: `/profile/email`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body: {
          email,
        },
      }),
    }),

    updateEmailConfirmation: build.mutation<void, SendObjectWithToken>({
      query: data => ({
        url: `/profile/email_verification`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          email: data.email,
          reset_email_token: data.reset_token,
        },
      }),
      invalidatesTags: ['Profile'],
    }),

    resetPassword: build.mutation<void, string>({
      query: email => ({
        url: `/reset_password`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          email,
        },
      }),
    }),

    resetPasswordVerification: build.mutation<void, SendObjectWithToken>({
      query: data => ({
        url: `/reset_password_verification`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          email: data.email,
          reset_password_token: data.reset_token,
        },
      }),
    }),

    resetPasswordConfirmation: build.mutation<any, ConfirmNewPassObject>({
      query: data => ({
        url: `/reset_password_confirmation`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          email: data.email,
          reset_password_token: data.reset_token,
          password: data.password,
          password_confirmation: data.password_confirmation,
        },
        providesTags: ['PasswordStore'],
        invalidatesTags: ['PasswordStore'],
      }),
    }),

    updateAvatar: build.mutation<void, FormData>({
      query: body => ({
        url: `/avatar`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body,
      }),
      invalidatesTags: ['Profile'],
    }),

    deleteAvatar: build.mutation<void, void>({
      query: () => ({
        url: `/avatar`,
        method: 'DELETE',
        headers: basicAuthHeaders(),
      }),
      invalidatesTags: ['Profile'],
    }),

    getUserProfile: build.query<IUser, string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/users/${id}`,
        method: 'GET',
      }),
      providesTags: ['UserProfile'],
    }),

    subscribeOnUser: build.mutation<void, string>({
      query: publisher_id => ({
        url: `/subscriptions`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          publisher_id,
        },
      }),
      invalidatesTags: ['UserProfile'],
    }),

    deleteSubscription: build.mutation<void, string>({
      query: publisher_id => ({
        url: `/subscriptions`,
        method: 'DELETE',
        headers: basicAuthHeaders(),
        body: {
          publisher_id,
        },
      }),
      invalidatesTags: ['UserProfile'],
    }),
  }),
});
