import React, { FC } from 'react';
import { ReviewType } from '../../../../types/reviewsTypes';
import { to_date } from '../../../../utils/convert';
import { RatingStars } from '../index';
import './reviewPost.scss';
import { useLocaleFromUrl } from '../../../../utils/localeFromUrl';

const ReviewPost: FC<{ review: ReviewType; index: number }> = ({ review, index }) => {
  const localeStr = useLocaleFromUrl();

  return (
    <div className="review__post" key={index}>
      <a
        href={localeStr ? `${localeStr}/user/${review.user.id}` : `/user/${review.user.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="review__user"
      >
        {review.user.avatar ? (
          <img src={review.user.avatar} className="review__image" alt="User image" />
        ) : (
          <p className="review-user-info__avatar">{review.user?.username?.[0]?.toUpperCase()}</p>
        )}

        <div className="review__info">
          <div className="reviewer__name">{review.user.username}</div>

          <div className="review__date">{to_date(review.created_at)}</div>
        </div>
      </a>

      <div className="review__stars">
        <RatingStars rating={review.rating} noDigit={true} starsSize="medium" />
      </div>

      <div className="reviewer__comment">{review.comment}</div>
    </div>
  );
};

export default ReviewPost;
