import { localStorageKeys } from "../../routes";

export const setLocale = (
  locale: string, updateProfileLocale: (locale: string) => Promise<unknown>
): void => {
  const token: string | null = localStorage.getItem(localStorageKeys.token);
  localStorage.setItem(localStorageKeys.locale, locale);

  token
    ? updateProfileLocale(locale).then((res) => res && window.location.reload())
    : window.location.reload();
};

/* Docs:
 const [updateProfileLocale] = apiProfile.useUpdateProfileLocaleMutation();

 This part using for update profile locale.
 Insert code that wrote above and call the method setLocale with following arguments => locale, FUNCTION.
 Where function if "updateProfileLocale" from methods of above called line
*/
