import React, { FC, useEffect, useState } from 'react';
import './likeComponent.scss';
import { Icon } from '../index';
import { api } from '../../../services/Api';
import { useAppSelector } from '../../../hooks/redux';
import Authorization from '../../auth/Authorization';
import { IPost } from '../../../types';

interface ILikeComponent {
  post: IPost;
  viewLikeAlways?: boolean;
}

const LikeComponent: FC<ILikeComponent> = ({ post, viewLikeAlways }) => {
  const { isAuthorized } = useAppSelector(state => state.authReducer);

  const [getLikedPosts, { data: likedPosts }] = api.useLazyGetLikedPostsQuery();
  const [likePost, { isLoading: likeLoading, isSuccess: likedSuccess, reset: resetLike }] =
    api.useToLikePostMutation();
  const [unlikePost, { isLoading: unlikeLoading, isSuccess: unlikedSuccess, reset: resetUnlike }] =
    api.useToUnlikePostMutation();

  const [liked, setLiked] = useState<boolean>(false);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('/');
  const [oneTimeAction, setOneTimeAction] = useState<boolean>(true);

  useEffect(() => {
    isAuthorized && getLikedPosts();
  }, []);

  useEffect(() => {
    resetLike();
    resetUnlike();
    likedSuccess && setLiked(true);
    unlikedSuccess && setLiked(false);
  }, [likedSuccess, unlikedSuccess]);

  useEffect(() => {
    if (likedPosts) {
      const likedPost = likedPosts?.filter(p => p.id === post.id);
      setLiked((likedPost.length >= 1)!!);
    }
  }, [likedPosts, post]);

  const likeClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    isAuthorized && setLiked(!liked);

    setOneTimeAction(true);

    if (likeLoading || unlikeLoading) return;
    if (isAuthorized) {
      if (liked && post.id) {
        post.id &&
          unlikePost({
            post_id: post.id,
          });
      } else {
        post.id &&
          likePost({
            post_id: post.id,
          });
      }
    } else if (oneTimeAction) {
      setRedirectUrl(window.location.pathname);
      setOpenAuthPopup(true);
      setOneTimeAction(false);
    }
  };

  return (
    <div
      onClick={likeClickHandler}
      className={`like-icon_frame ${viewLikeAlways ? 'liked' : liked && 'liked'}`}
    >
      <div className={`like-icon ${liked && 'show'}`}>
        <Icon type="redHeart" />
      </div>

      <div className={`unlike-icon ${!liked && 'show'}`}>
        <Icon type="like" />
      </div>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={redirectUrl}
      />
    </div>
  );
};

export default LikeComponent;
