import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Crop } from "react-image-crop";

const initialState: { crop: Crop, selectedSize: number } = {
  crop: {
    width: 80,
    height: 80,
    x: 80,
    y: 80,
    unit: "px",
  },
  selectedSize: 100,
};

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    setCrop: (state, action: PayloadAction<Crop>) => {
      state.crop = action.payload
    },
    setSelectedSize: (state, action: PayloadAction<number>) => {
      state.selectedSize = action.payload
    },
  }
});

export const avatarReducer = avatarSlice.reducer;
export const avatarAction = avatarSlice.actions;
