import React, { FC, SetStateAction } from 'react';
import { Icon } from '../../../default_components';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Popover } from '@mui/material';
import { useAppSelector } from '../../../../hooks/redux';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface IUsersMenu {
  anchorEl: HTMLLIElement | null;
  setAnchorEl: React.Dispatch<SetStateAction<HTMLLIElement | null>>;
  handleLogOut: () => void;
}

const UsersMenu: FC<IUsersMenu> = ({ anchorEl, setAnchorEl, handleLogOut }) => {
  const { chats } = useAppSelector(state => state.chatReducer);
  const navigateWithLocale = useNavigateWithLocale();

  const clickOnProfileIcon = (event: React.MouseEvent<HTMLLIElement>) =>
    setAnchorEl(event.currentTarget);
  const closeDropUsersMenu = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const areUnreadMassages: boolean =
    chats.filter(c => c.unread_messages && c.unread_messages > 0).length > 0;

  const typeChatIcon = window.location.pathname.includes('/chat') ? 'chatActive' : 'chat';
  const typeLikeIcon = window.location.pathname.includes('/my-favorite-posts')
    ? 'redHeart'
    : 'like';
  const typeProfileIcon = window.location.pathname.includes('/my-profile')
    ? 'userActive'
    : anchorEl
    ? 'userActive'
    : 'user';

  return (
    <ul className="header__user-menu">
      <li onClick={() => navigateWithLocale('/chat')} data-cy="message-button">
        <Icon type={typeChatIcon} />
        {areUnreadMassages && <div className="identificator-unread-message" />}
      </li>

      <li onClick={() => navigateWithLocale('/my-favorite-posts')}>
        <Icon type={typeLikeIcon} />
      </li>

      <li aria-describedby={id} onClick={clickOnProfileIcon} data-cy="settings-dropdown">
        <Icon type={typeProfileIcon} />
      </li>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDropUsersMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '.MuiPaper-root': {
            borderRadius: '10px ',
            boxShadow: 'none',
          },
        }}
      >
        <div className="profile-select-container">
          <p className="profile-select-item" onClick={() => navigateWithLocale('/my-profile')}>
            {t('translation:header.profile_select_item_1.message')}
          </p>

          <p
            className="profile-select-item"
            onClick={() => navigateWithLocale('/my-posts')}
            data-cy="my-posts"
          >
            {t('translation:header.profile_select_item_2.message')}
          </p>

          <p className="profile-select-item" onClick={() => navigateWithLocale('/my-purchases')}>
            {t('translation:header.profile_select_item_5.message')}
          </p>

          <p className="profile-select-item" onClick={handleLogOut}>
            {t('translation:header.logout.message')}
          </p>
        </div>
      </Popover>
    </ul>
  );
};

export default UsersMenu;
