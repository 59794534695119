import React from 'react';
import { t } from 'i18next';
import NoPostsFoundImage from '../../default_components/assets/icons/NoPostsFound.svg'

const NoPostsFound = () => {
  return (
    <div className="no-posts__container no-posts-found">
      <img src={NoPostsFoundImage} />
      
      <p className="filter-no-posts bold">{t('translation:filter.no_posts_found.message')}</p>
      <p className="filter-no-posts">{t('translation:filter.try_change_query.message')}</p>
    </div>
  );
};

export default NoPostsFound;
