import React, { FC } from 'react';
import { t } from 'i18next';
import { api } from '../../../services/Api';
import { localStorageKeys } from '../../../routes';
import { GoogleToken } from '../../../services/types';
import { useGoogleLogin } from '@react-oauth/google';
import { Snackbar } from '../../default_components';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';

interface IGoogleLoginWrapper {
  children: React.ReactElement
}

const GoogleLoginWrapper: FC<IGoogleLoginWrapper> = ({ children }) => {
  const [googleLogin] = api.useGoogleLoginMutation();
  const [getGoogleAccessToken] = api.useGetGoogleAccessTokenMutation();

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      try {
        const token = await getGoogleAccessToken(codeResponse.code).unwrap();
        handleGoogleAccessToken(token);
      } catch (error) {
        handleError('Error from handleGoogleLogin', error);
      }
    },
    onError: errorResponse => {
      handleError('Error when receiving the code', errorResponse);
    },
  });

  const handleGoogleAccessToken = async (token: GoogleToken) => {
    try {
      const tokenJWT = await googleLogin(token.token).unwrap();

      localStorage.setItem(localStorageKeys.token, tokenJWT.token);
      getActiveSnackbar(t('translation:auth.auth_success.message'), 'success');
      window.location.replace('/');
    } catch (error) {
      handleError('Error when receiving the code', error);
    }
  };

  const handleError = (message: string, error: unknown) => {
    console.error(message, error);
    getActiveSnackbar(t('translation:auth.smth_wrong.message'), 'error');
  };

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { handleGoogleLogin })
  );

  return (
    <React.Fragment>
      {childrenWithProps}
      <Snackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </React.Fragment>
  );
};

export default GoogleLoginWrapper;
