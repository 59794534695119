import React, { FC, SetStateAction, useEffect, useState } from 'react';
import './email.scss';
import { t } from 'i18next';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import { apiProfile } from '../../../../services/ApiProfile';
import { ConfirmModal, SaveButton } from '..';
import { InputGamar, Snackbar } from '../../../default_components';
import { IUser } from '../../../../types';

interface IEmail {
  profileInfo: IUser | undefined;
  timeBeforeResendingCodeForEmail: number;
  setTimeBeforeResendingCodeForEmail: React.Dispatch<SetStateAction<number>>;
}

enum EMAIL_ACTIONS {
  EMAIL_SEND_CODE = 'EMAIL_SEND_CODE',
  EMAIL_CONFIRM = 'EMAIL_CONFIRM',
}

const Email: FC<IEmail> = ({
  profileInfo,
  timeBeforeResendingCodeForEmail,
  setTimeBeforeResendingCodeForEmail,
}) => {
  const [updateEmail] = apiProfile.useUpdateEmailMutation();
  const [updateEmailConfirmation, { isLoading, isError, error, isSuccess }] =
    apiProfile.useUpdateEmailConfirmationMutation();

  const [newEmail, setNewEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    newEmailChanged() ? setCanSave(true) : setCanSave(false);
  }, [newEmail]);

  useEffect(() => {
    timeBeforeResendingCodeForEmail > 0 &&
      setTimeout(setTimeBeforeResendingCodeForEmail, 1000, timeBeforeResendingCodeForEmail - 1);
  }, [timeBeforeResendingCodeForEmail]);

  useEffect(() => {
    if (isSuccess) {
      setShowPopup(false);
      setNewEmail('');
      setCode('');
    }
  }, [isSuccess]);

  useEffect(() => {
    !showPopup && setCode('');
  }, [showPopup, code]);

  useEffect(() => {
    if (isSuccess) {
      getActiveSnackbar(
        t('translation:profile.email_change.email_confirmed_success.message'),
        'success',
      );
    } else if (error && 'status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);

      getActiveSnackbar(JSON.parse(errMsg)?.message + ' ' + error.status, 'error');
      setErrorMessage(JSON.parse(errMsg).errors[0]);
    }
  }, [isSuccess, isError]);

  const validationEmail = (email: string) => {
    const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return EMAIL_REGEXP.test(email);
  };

  const newEmailChanged = (): boolean =>
    newEmail !== profileInfo?.email && validationEmail(newEmail);

  const actionWithEmail = (typeAction: string, newSend?: boolean) => {
    profileInfo && newEmailChanged() && setShowPopup(true);

    switch (typeAction) {
      case EMAIL_ACTIONS.EMAIL_SEND_CODE: {
        if (timeBeforeResendingCodeForEmail === 0 || newSend) {
          updateEmail(newEmail);
        }
        return;
      }
      case EMAIL_ACTIONS.EMAIL_CONFIRM: {
        if (code?.length) {
          updateEmailConfirmation({
            email: newEmail,
            reset_token: code,
          });
        }
        return;
      }
      default:
        return false;
    }
  };

  const clickOnSave = (): void => {
    if (canSave) {
      if (validationEmail(submittedEmail)) {
        if (submittedEmail === newEmail) {
          setShowPopup(true);
        } else {
          timeBeforeResendingCodeForEmail === 0 && setTimeBeforeResendingCodeForEmail(60);
          setSubmittedEmail(newEmail);
          actionWithEmail(EMAIL_ACTIONS.EMAIL_SEND_CODE, true);
        }
      } else {
        if (timeBeforeResendingCodeForEmail === 0) {
          setTimeBeforeResendingCodeForEmail(60);
        }
        setSubmittedEmail(newEmail);
        actionWithEmail(EMAIL_ACTIONS.EMAIL_SEND_CODE);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="email_frame">
        <div className="current-email_container">
          <p>{t('translation:profile.email_change.current_email.message')}</p>

          <div>{profileInfo?.email}</div>
        </div>

        <InputGamar
          value={newEmail}
          setValue={setNewEmail}
          label={t('translation:profile.email_change.new_email_label.message')}
          placeholder={
            t('translation:profile.email_change.email_placeholder.message') || 'new@mail.com'
          }
          type="email"
          autoComplete="email"
        />
      </div>

      <SaveButton isLoading={isLoading} isCanSave={canSave} handleOnSave={clickOnSave} />

      <ConfirmModal
        open={showPopup}
        setOpen={setShowPopup}
        code={code}
        setCode={setCode}
        title={t('translation:profile.email_change.email_confirmation.message')}
        newValue={newEmail}
        time={timeBeforeResendingCodeForEmail}
        loading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
        sendAgain={() => {
          actionWithEmail(EMAIL_ACTIONS.EMAIL_SEND_CODE);
          setTimeBeforeResendingCodeForEmail(60);
        }}
        confirmAction={() => actionWithEmail(EMAIL_ACTIONS.EMAIL_CONFIRM)}
      />

      <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
    </React.Fragment>
  );
};

export default Email;
