import { IChatUser, IMessage } from '../../../types';
import { Radio, RadioProps } from '@mui/material';
import { CheckedElementStyle } from '../../filter/filterOptions';
import { DateTime } from 'luxon';
import React from 'react';
import './buyerSelect.scss';

export const BuyerRadio = ({
  buyer,
  message,
  ...props
}: { buyer: IChatUser; message?: IMessage } & RadioProps) => {
  return (
    <div className="buyer-radio-box">
      <Radio {...props} sx={CheckedElementStyle} />

      {buyer.avatar ? (
        <>
          <img src={buyer.avatar} alt="avatar" className="user-avatar" />
        </>
      ) : (
        <div className="user-avatar">
          <h1>{buyer.username[0].toUpperCase()}</h1>
        </div>
      )}

      <div className="username-container">
        <p className="username">{buyer.username}</p>

        <p className="message">{`${message?.text.slice(0, 25)}...`}</p>
      </div>

      <p className="date">
        {message && DateTime.fromISO(message?.created_at).toFormat('dd MMM yyyy')}
      </p>
    </div>
  );
};
