import React, { FC, useState } from 'react';
import './myPostsCardActionsButtons.scss';
import { IPost } from '../../../../types';
import { Icon } from '../../index';
import { Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface MyPostsCardActionsButtons {
  post: IPost;
  clickOnDelete: (post: IPost) => void;
}

const dotsMenuStyle = {
  width: '90%',
  position: 'none',
  maxWidth: '340px',

  '.MuiPaper-root': {
    borderRadius: '10px',
    boxShadow: '2px 2px 15px -2px rgba(0, 0, 0, 0.12)',
  },

  '.MuiList-root': {
    height: '100%',
    maxWidth: '200px',

    padding: '10px',
    position: 'none',
    border: 'transparent !important',
    fontFamily: 'Noto Sans, sans serif !important',
    fontSize: '14px !important',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    gap: '5px',

    color: '#110B11 !important',
    opacity: '1 !important',
    background: '#F2F2F2',
    transition: 'all .2s ease-in-out',
  },
};

const MyPostsCardActionsButtons: FC<MyPostsCardActionsButtons> = ({ post, clickOnDelete }) => {
  const navigateWithLocale = useNavigateWithLocale();
  const isShowDeleteButton = post.status === 'active';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickOnEditPost = () => {
    navigateWithLocale(`/editing/${post.id}`);
  };

  const handleClickOnDeletePost = () => {
    clickOnDelete(post);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | null>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="my-posts-card__action-buttons">
      <button onClick={e => handleClick(e)} className={`dots-button ${anchorEl && 'clicked'}`}>
        <Icon type="dotsMenu" size="medium" />
      </button>

      <Menu
        anchorEl={anchorEl}
        sx={dotsMenuStyle}
        id="basic-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <button className="edit-my-post-button" onClick={handleClickOnEditPost}>
          <Icon type="pencilBlackColor" />
          <p>{t('translation:my_posts.edit_info.message')}</p>
        </button>

        {isShowDeleteButton && (
          <button className="delete-my-post-button" onClick={handleClickOnDeletePost}>
            <Icon type="deleteType" />
            <p>{t('translation:my_posts.deactivate.message')}</p>
          </button>
        )}
      </Menu>
    </div>
  );
};

export { MyPostsCardActionsButtons };
