import React, { FC, useEffect, useState } from 'react';
import { RatingDigits, RatingBars, RatingStars } from '../../../default_components/reviews';
import { ViewReviewsModalType } from '../../../../types/reviewsTypes';
import { Icon } from '../../../default_components';
import { Fade, Modal } from '@mui/material';
import './viewReviewsModal.scss';
import ReviewPost from '../../../default_components/reviews/ReviewPost/ReviewPost';
import DefaultButton from '../../../default_components/button/DefaultButton';
import { api } from '../../../../services/Api';
import { to_rating_digit } from '../../../../utils/convert';
import { ReviewType } from '../../../../types/reviewsTypes';
import { t } from 'i18next';

const ReviewsModal: FC<ViewReviewsModalType> = ({ open, setOpen, user, reviews: propsReviews }) => {
  const [fetchReviews, { data }] = api.useLazyGetReviewsBySellerQuery();

  const [rating, setRating] = useState<number | undefined>(undefined);
  const [reviews, setReviews] = useState<ReviewType[] | undefined>(propsReviews || undefined);

  useEffect(() => {
    if (user) {
      fetchReviews(user.id);
    }
  }, [user]);

  useEffect(() => {
    const reviews = data || propsReviews;

    if (reviews) {
      setRating(to_rating_digit(reviews));
      setReviews(reviews);
    }
  }, [data, open]);

  return (
    <Modal
      open={open}
      className="view__reviews__container"
      disableScrollLock={false}
      onClose={(event, reason: 'backdropClick' | 'escapeKeyDown') => setOpen(!open)}
    >
      <Fade in={open}>
        <div className="view__reviews__modal">
          <div className="view__reviews__modal-header">
            <p className="view__reviews__modal-header__title">
              {t('translation:view_reviews.view_reviews.message')}
            </p>

            <Icon
              type="crossDefault"
              className="view__reviews__modal-header__close"
              onClick={() => setOpen(!open)}
            />
          </div>

          <div className="view__reviews__modal-body">
            <div className="view__reviews__modal-body__stats">
              <span>
                <RatingDigits rating={rating} />

                <RatingStars rating={rating} noDigit={true} mode="view" />
              </span>

              <RatingBars reviews={reviews} />
            </div>

            <div className="view__reviews__modal-body__posts">
              {reviews &&
                reviews.map((review: any, index: any) => {
                  return <ReviewPost review={review} index={index} key={index} />;
                })}
            </div>
          </div>

          <div className="view__reviews__modal-footer">
            <DefaultButton
              onClickAction={() => setOpen(!open)}
              styleType="gray-gray_outline-gray-gray-gray"
              sizeType="cancel-responsive"
              translateKey="view_reviews.close.message"
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ReviewsModal;
