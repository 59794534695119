import React from 'react';
import { t } from 'i18next';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Facebook from '../../default_components/assets/icons/Facebook.svg';
import Mail from '../../default_components/assets/icons/Mail.svg';
import Instagram from '../../default_components/assets/icons/Instagram.svg';
import Telegram from '../../default_components/assets/icons/Telegram.svg';
import { Helmet } from 'react-helmet';

const socialNetworksLinks = [
  { link: 'https://www.facebook.com/profile.php?id=100093651364787', icon: Facebook },
  { link: 'mailto: contact@gamarmarket.ge', icon: Mail },
  { link: 'https://www.instagram.com/gamarmarket.ge/', icon: Instagram },
  { link: 'https://t.me/gamarmarket', icon: Telegram },
  { link: 'tel:+995579149729', icon: LocalPhoneOutlinedIcon },
];

const ContactUs = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta property="og:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta property="og:image" content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"/>
        <meta property="og:url" content="https://gamarmarket.ge"/>

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta name="twitter:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta name="twitter:url" content="https://gamarmarket.ge"/>
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია."/>
      </Helmet>
      <Header />

      <div className="static-page__frame">
        <div className="static-page__title-container">
          <h1>{t('translation:footer.support.message')}</h1>
        </div>

        <hr className="divider" />

        <p className="static-page__content">
          {t('translation:contact.text_1.message')}
          <hr className="hr" />
          <p className="contact_block">
            <MailOutlineIcon />
            {t('translation:contact.email.message')}{' '}
            <a href={`mailto:${socialNetworksLinks[1].link}`} target="_blank" rel="noopener noreferrer">
              contact@gamarmarket.ge
            </a>
          </p>
          {t('translation:contact.email_text.message')}
          <hr className="hr" />
          {t('translation:contact.social_text_1.message')}
          <hr className="hr" />
          <div className="social-icons">
            {socialNetworksLinks.slice(0, 4).map((network: any) => (
              <a href={network.link} target="_blank" rel="noopener noreferrer">
                <img src={network.icon} alt={'Social Network Icon'} />
              </a>
            ))}
          </div>
          <hr className="hr" />
          {t('translation:contact.social_text_2.message')}
        </p>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
