import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store';
import { authAction } from '../store/reducers/auth.slice';
import { chatAction } from '../store/reducers/chat.slice';
import { authorizationAction } from '../store/reducers/authorization.slice';
import { searchAction } from '../store/reducers/search.slice';
import { avatarAction } from "../store/reducers/avatar.slice";
import { filterAction } from "../store/reducers/filter.slice";

const actions = {
  ...authAction,
  ...chatAction,
  ...authorizationAction,
  ...avatarAction,
  ...searchAction,
  ...filterAction,
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
