import { ISearchResponse } from '../../../services/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ICategoryFieldFilter, IFilterOption, IPost } from '../../../types';

export { default as CategoriesListFilter } from './categoriesListFilter/CategoriesListFilter';
export { default as FieldsSelect } from './fieldsSelect/FieldsSelect';
export { default as PriceSlider } from './priceSlider/PriceSlider';
export { default as SearchInput } from './searchInput/SearchInput';
export { default as LocationSelect } from './locationSelect/LocationSelect';
export { default as StateSelect } from './stateSelect/StateSelect';

const CheckedElementStyle = {
  padding: '0',
  color: '#110B11',
  '&:hover': {
    background: 'transparent !important',
  },
  '&.Mui-checked': {
    color: '#DD2006',
  },
};

const RadioGroupStyle = {
  gap: '10px',

  '.MuiTypography-root': {
    fontSize: '16px',
    fontFamily: 'Noto Sans',
  },

  '.MuiFormControlLabel-root': {
    gap: '10px',
    margin: '0',
  },
};

const FilterCollapseStyle = {
  '.MuiCollapse-wrapperInner': {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '10px',
  },

  '.MuiFormControlLabel-root': {
    gap: '10px',
    margin: '0',
  },
};

const getShowMoreOptionsTest = (flag: boolean) => {
  return flag ? 'filter.show_less.message' : 'filter.show_all.message';
};

const setReceivedPosts = (
  isSuccess: boolean,
  data: ISearchResponse | undefined,
  setPosts: ActionCreatorWithPayload<IPost[] | undefined>,
): void => {
  if (isSuccess && data) {
    setPosts(data.posts);
  }
};

const categoryFields = (fieldsStates: IFilterOption): ICategoryFieldFilter[] => {
  return Object.values(fieldsStates)
    .reduce((acc: string[], value) => {
      !acc.includes(value) && acc.push(value);
      return acc;
    }, [])
    .map(id => {
      const obj: ICategoryFieldFilter = {
        id,
        values: [],
      };

      Object.entries(fieldsStates).map(field => field[1] === id && obj.values.push(field[0]));
      return obj;
    });
};

//*** interfaces ***//
interface IFilterAssets {
  value: string;
  label: string;
}

interface IStateAssets extends IFilterAssets {}

export {
  CheckedElementStyle,
  RadioGroupStyle,
  FilterCollapseStyle,
  getShowMoreOptionsTest,
  setReceivedPosts,
  categoryFields,
  IStateAssets,
};
