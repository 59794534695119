import React, { FC } from 'react';
import './postUsedPrice.scss';
import { IPost } from '../../../../../types';
import { Skeletons } from '../../../../default_components';
import { getPostPrice } from '../../../../../utils/get';
import { t } from 'i18next';
import { useAppSelector } from '../../../../../hooks/redux';
import { Tooltip } from '@mui/material';

interface IPostUsedPrice {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostUsedPrice: FC<IPostUsedPrice> = ({ post, isPostFetching }) => {
  const { activeCurrency } = useAppSelector(state => state.authReducer);
  const isUsedClass = post?.used ? 'used' : 'not-used';

  // @ts-ignore
  const originalCurrency =
    post &&
    post.price != null &&
    post.price > 0.0 &&
    // @ts-ignore
    post.prices[post.currency.toLowerCase()] + ' ' + post.currency + ' Original currency';

  if (post && !isPostFetching) {
    return (
      <div className="post-used-price_frame">
        <Tooltip title={originalCurrency}>
          <div className="post-price">
            {post.price === null
              ? t('translation:product_page.discussed.message')
              : post.price == 0.0
              ? t('translation:product_page.free.message')
              : getPostPrice(post, activeCurrency)}
          </div>
        </Tooltip>

        <div className={`post-used ${isUsedClass}`}>
          {post.used
            ? t('translation:product_page.used.message')
            : t('translation:product_page.new.message')}
        </div>
      </div>
    );
  }

  return <Skeletons.SkeletonPostPrice />;
};

export default PostUsedPrice;
