import { useEffect, useState } from 'react';
import { useActions, useAppSelector } from './redux';
import { chatApi } from '../services/chatApi';
import { IChatRoom, IMessage } from '../types';
import { UserToken } from '../utils/get';
import { useGetProfileInfo } from './useGetProfileInfo';
import { consumer, CHAT_CHANNEL } from '../socketUtils/chatSocket';

const useActivateChats = () => {
  const { profileInfo } = useGetProfileInfo();
  const { data: receivedChats, isSuccess } = chatApi.useGetAllChatsQuery();
  const { chats } = useAppSelector(state => state.chatReducer);
  const { setChats } = useActions();

  const [lastReceivedMessage, setLastReceivedMessage] = useState<IMessage | undefined>();

  const subscribeOnChat = (chat: IChatRoom) => {
    consumer.chatChannel = consumer.subscriptions.create(
      {
        channel: CHAT_CHANNEL,
        chat_id: chat.id,
        authorization: UserToken,
      },
      {
        received: (e: string) => {
          const data = JSON.parse(e);
          if (['ping', 'welcome', 'confirm_subscription'].includes(data.type)) return;

          const { type, ...message } = data;
          setLastReceivedMessage(message);
        },
      },
    );
  };

  useEffect(() => {
    if (isSuccess && profileInfo) {
      const updateChats = receivedChats.map(chat => {
        const unreadMessagesCount = chat.messages.reduce((count, message) => {
          return !message.seen && message.user_id !== profileInfo.id ? count + 1 : count;
        }, 0);

        subscribeOnChat(chat);
        return { ...chat, unread_messages: unreadMessagesCount };
      });

      setChats(updateChats);
    }
  }, [isSuccess, profileInfo]);

  useEffect(() => {
    if (chats && lastReceivedMessage && profileInfo) {
      const { chat_id, action, created_at, user_id, id } = lastReceivedMessage;

      const updatedChats = chats.map(chat => {
        if (chat.id === chat_id) {
          let updatedMessages = [...chat.messages];
          let unreadDiff = 0;

          if (action === 'update') {
            updatedMessages = updatedMessages.map(msg =>
              msg.id === id ? { ...msg, ...lastReceivedMessage } : msg,
            );
          } else if (action === 'delete') {
            const index = updatedMessages.findIndex(msg => msg.id === id);
            if (index !== -1) {
              updatedMessages[index] = {
                ...updatedMessages[index],
                text: '',
                deleted_at: created_at,
              };
              unreadDiff = user_id !== profileInfo.id ? -1 : 0;
            }
          } else {
            updatedMessages = updatedMessages.concat(lastReceivedMessage);
            unreadDiff = user_id !== profileInfo.id ? 1 : 0;
          }

          const unreadIncrement = action === 'delete' ? 0 : unreadDiff;

          return {
            ...chat,
            last_message_at: created_at,
            messages: updatedMessages,
            unread_messages: (chat.unread_messages || 0) + unreadIncrement,
          };
        }
        return chat;
      });

      setChats(updatedChats);
    }
  }, [lastReceivedMessage]);
};

export default useActivateChats;
