import React, { FC, SetStateAction } from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import { RadioBox } from '../index';
import { defaultRadioGroupStyle } from './defaultRadioGroupStyle';
import './defaultRadioGroup.scss';
import './../../filter/filterOptions/filterOptions.scss';
import { t } from 'i18next';

interface IRadioBoxGroupProps {
  value?: string;
  setValue: React.Dispatch<SetStateAction<string>> | any;
  name: string;
  assets: string[];
  color?: string;
  type?: string;
  size?: 'small' | 'medium' | undefined;
  uq?: boolean;
}

const DefaultRadioGroup: FC<IRadioBoxGroupProps> = ({
  name,
  color,
  type,
  value,
  setValue,
  assets,
  size,
  uq,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uq) {
      setValue((event.target as HTMLInputElement).value.toLowerCase().replace(/ /g, '_'));
    } else {
      setValue((event.target as HTMLInputElement).value);
    }
  };

  return (
    <div data-cy="radio-group">
      <FormControl sx={defaultRadioGroupStyle(type)}>
        {type === 'filter' ? (
          <div className="filter__sort">
            <p>{name}</p>
          </div>
        ) : (
          <p className="radio-group_name">{name}</p>
        )}

        <RadioGroup value={value} onChange={handleChange}>
          {assets.map(item => (
            <RadioBox
              key={item}
              value={item.toLowerCase().split(',')[0].replace(/ /g, '_')}
              label={uq ? item : t(`filter.${item.toLowerCase().replace(/ /g, '_')}.message`)}
              new_color={color}
              size={size}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default DefaultRadioGroup;
