import React, { FC, useEffect, useState } from 'react';
import './breadcrumbs.scss';
import { api } from '../../../services/Api';
import { getBreadcrumbsPath } from '../../../utils/get';
import { ICategory } from '../../../types';
import { Skeletons } from '..';
import { t } from 'i18next';
import { useLocaleFromUrl } from '../../../utils/localeFromUrl';

interface IBreadcrumbs {
  category_id: string | undefined;
  isFetching?: boolean;
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ category_id, isFetching }) => {
  const { data: categories } = api.useGetAllCategoriesQuery();
  const localeStr = useLocaleFromUrl();

  const [breadcrumbsPath, setBreadcrumbsPath] = useState<ICategory[]>([]);

  useEffect(() => {
    if (categories && category_id) {
      setBreadcrumbsPath(getBreadcrumbsPath(categories, category_id));
    }
  }, [categories, category_id]);

  if (!isFetching && breadcrumbsPath.length) {
    return (
      <div className="breadcrumbs_frame">
        {breadcrumbsPath.map((category, key) => (
          <div className="breadcrumbs_category-container" key={key}>
            <a
              className={`breadcrumbs_category-name ${key == breadcrumbsPath.length - 1 && 'bold'}`}
              href={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
              style={{ textDecoration: 'none' }}
            >
              {category.name}
            </a>

            {key != breadcrumbsPath.length - 1 && <span className="breadcrumbs_symbol">/</span>}
          </div>
        ))}
      </div>
    );
  }

  if (window.location.pathname.includes('/category/all')) {
    return (
      <div className="breadcrumbs_frame">
        <span className="breadcrumbs_bold">
          {t('translation:breadcrumbs.all_products.message')}
        </span>
      </div>
    );
  }

  if (window.location.pathname.includes('/category/fresh')) {
    return (
      <div className="breadcrumbs_frame">
        <span className="breadcrumbs_bold">
          {t('translation:breadcrumbs.fresh_products.message')}
        </span>
      </div>
    );
  }

  return (
    <div className="breadcrumbs_frame">
      <Skeletons.SkeletonPostCategory />
    </div>
  );
};

export default Breadcrumbs;
