import React from 'react';

export const renderTextAsParagraphs = (text: string): React.ReactNodeArray => {
  const lines = text.split(/\r?\n/);
  let emptyLinesCount = 0;

  return lines
    .flatMap((line, index) => {
      if (line.trim() === '') {
        if (emptyLinesCount < 5) {
          emptyLinesCount++;
          return [<p key={index}>&nbsp;</p>];
        }
        return null;
      } else {
        return [<p key={index}>{line}</p>];
      }
    })
    .filter(Boolean) as React.ReactNodeArray;
};
