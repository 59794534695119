import React, { FC, useState } from 'react';
import './myPurchaseCard.scss';
import { IExpandedPurchase } from '../../../services/types';
import { t } from 'i18next';
import { getPostPrice } from '../../../utils/get';
import { useAppSelector } from '../../../hooks/redux';
import DefaultButton from '../../default_components/button/DefaultButton';
import AddReviewModal from '../../reviews/modals/add/AddReviewModal';
import { getShortProductName, isoDateToHumanDate } from '../../../lib/text';
import ViewReviewsModal from '../../reviews/modals/view/ViewReviewsModal';
import { useNavigate } from 'react-router-dom';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface IMyPurchaseCard {
  purchase: IExpandedPurchase;
}

const MyPurchaseCard: FC<IMyPurchaseCard> = ({ purchase }) => {
  const navigateWithLocale = useNavigateWithLocale();

  const [openViewReviewsModal, setOpenViewReviewsModal] = useState(false);
  const [openAddReviewModal, setOpenAddReviewModal] = useState(false);
  const [isHideAddReviewButton, setIsHideAddReviewButton] = useState<boolean>(
    purchase.isReviewExist,
  );
  const { activeCurrency } = useAppSelector(state => state.authReducer);

  const hideAddReviewButton = (): void => {
    setIsHideAddReviewButton(true);
  };

  const price =
    purchase.post.price === null
      ? t('translation:product_page.discussed.message')
      : purchase.post.price == 0.0
      ? t('translation:product_page.free.message')
      : getPostPrice(purchase.post, activeCurrency);

  return (
    <article className="my-purchase-card">
      <div
        className="my-purchase-card-content"
        onClick={() => navigateWithLocale(`/item/${purchase.post_id}`)}
      >
        <img src={purchase.post.images_variants[0].large} />
        <div className="image"></div>
        <p className="price">{price}</p>
        <p className="title">{getShortProductName(45, purchase.post.title)}</p>
      </div>

      <div className="my-purchase-card-footer">
        <p className="purchased-at">
          {t('my_purchases.card.purchased_at.message')}&nbsp;
          {isoDateToHumanDate(purchase.created_at)}
        </p>

        {purchase.isReviewExist || isHideAddReviewButton ? (
          <DefaultButton
            onClickAction={() => setOpenViewReviewsModal(!openViewReviewsModal)}
            styleType="gray-gray-outline-accent-accent-accent"
            sizeType="view-review"
            translateKey="my_purchases.card.view_review.message"
          />
        ) : (
          <DefaultButton
            onClickAction={() => setOpenAddReviewModal(!openAddReviewModal)}
            styleType="outline-accent-accent-accent-accent"
            sizeType="add-review"
            translateKey="my_purchases.card.add_review.message"
          />
        )}

        <AddReviewModal
          open={openAddReviewModal}
          setOpen={setOpenAddReviewModal}
          post={purchase.post}
          hideAddReviewButton={hideAddReviewButton}
        />

        <ViewReviewsModal
          open={openViewReviewsModal}
          setOpen={setOpenViewReviewsModal}
          reviews={purchase.reviews}
        />
      </div>
    </article>
  );
};

export default MyPurchaseCard;
