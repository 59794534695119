import React, { FC, useEffect, useState } from 'react';
import './postOwnerInfo.scss';
import { api } from '../../../../../services/Api';
import { chatApi } from '../../../../../services/chatApi';
import { useActions, useAppSelector } from '../../../../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useGetProfileInfo } from '../../../../../hooks/useGetProfileInfo';
import { Icon, Skeletons } from '../../../../default_components';
import { IChatRoom, IPost, IUser } from '../../../../../types';
import { localStorageKeys, REDIRECT_URLS } from '../../../../../routes';
import Authorization from '../../../../auth/Authorization';
import { CHAT_CHANNEL, consumer } from '../../../../../socketUtils/chatSocket';
import { UserToken } from '../../../../../utils/get';
import PhoneCallButton from './PhoneCallButton';
import useNavigateWithLocale from '../../../../../hooks/useNavigateWithLocale';
import { useLocaleFromUrl } from '../../../../../utils/localeFromUrl';

interface IPostOwnerInfo {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostOwnerInfo: FC<IPostOwnerInfo> = ({ post, isPostFetching }) => {
  const [getUser] = api.useLazyGetUserQuery();
  const [createChat] = chatApi.useCreateChatMutation();
  const [getAllChats] = chatApi.useLazyGetAllChatsQuery();

  const { chats } = useAppSelector(state => state.chatReducer);
  const { isAuthorized } = useAppSelector(state => state.authReducer);
  const { setSelectedChat, setChats } = useActions();

  const [author, setAuthor] = useState<IUser>();
  const [loadedAvatar, setLoadedAvatar] = useState<boolean>(false);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(false);
  const [isMyPost, setIsMyPost] = useState<boolean>(false);

  const { profileInfo } = useGetProfileInfo();
  const navigateWithLocale = useNavigateWithLocale();

  useEffect(() => {
    post && getUser(post.user.id).then(response => setAuthor(response.data));
  }, [post]);

  useEffect(() => {
    author && !author.avatar && setLoadedAvatar(true);
  }, [author]);

  useEffect(() => {
    if (isPostFetching) {
      setAuthor(undefined);
      setLoadedAvatar(false);
    }
  }, [isPostFetching]);

  useEffect(() => {
    profileInfo && setIsMyPost(true);

    if (profileInfo && author) {
      setIsMyPost(profileInfo.id === post?.user.id);
    }
  }, [author, profileInfo]);

  const clickOnMessageButton = (): void => {
    if (isAuthorized && post) {
      localStorage.removeItem(localStorageKeys.chat_storage_key);
      redirectToChat(post.id);
    } else {
      setOpenAuthPopup(true);
    }
  };

  const localeStr = useLocaleFromUrl();

  const redirectToChat = (postId: string): void => {
    const chat = chats.find(chat => chat.post_id === postId);

    if (chat) {
      setSelectedChat(chat);
      navigateWithLocale('/chat');
    } else {
      getCreatedChat(postId).then(response => {
        if (response) {
          setSelectedChat(response);
          navigateWithLocale('/chat');
        }
      });
    }
  };

  const subscribeOnCreatedChat = (chat: IChatRoom): void => {
    if (!profileInfo) return;

    consumer.chatChannel = consumer.subscriptions.create(
      {
        channel: CHAT_CHANNEL,
        chat_id: chat.id,
        authorization: UserToken,
      },
      {
        received: async (e: string) => {
          const data = JSON.parse(e);

          if (!['ping', 'welcome', 'confirm_subscription'].includes(data.type)) {
            try {
              const result = await getAllChats();
              if ('data' in result) {
                const updateChats = result.data?.map(chat => {
                  const unreadMessagesCount = chat.messages.reduce((count, message) => {
                    return !message.seen && message.user_id !== profileInfo.id ? count + 1 : count;
                  }, 0);

                  return { ...chat, unread_messages: unreadMessagesCount };
                });

                updateChats && setChats(updateChats);
              }
            } catch (error) {
              console.error('Error fetching chats:', error);
            }
          }
        },
      },
    );
  };

  async function getCreatedChat(postId: string): Promise<IChatRoom | undefined> {
    try {
      const response = await createChat(postId);

      if ('data' in response) {
        let chat = response.data;

        setChats(chats.concat(chat));
        subscribeOnCreatedChat(chat);

        return chat;
      } else if ('error' in response) {
        console.error('Error occurred:', response.error);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  const PostOwnerInfoContent = () => {
    return (
      <React.Fragment>
        {post && author && !isPostFetching ? (
          <div className="post-owner-avatar_frame">
            <a
              className="post-owner-avatar"
              href={localeStr ? `${localeStr}/user/${post?.user.id}` : `/user/${post?.user.id}`}
              style={{ textDecoration: 'none' }}
            >
              {!loadedAvatar && <Skeletons.SkeletonPostAvatar />}

              {author.avatar ? (
                <img src={author.avatar} alt="icon" onLoad={() => setLoadedAvatar(true)} />
              ) : (
                <p>{post.user.username[0].toUpperCase()}</p>
              )}
            </a>

            <a
              className="post-owner-name"
              href={localeStr ? `${localeStr}/user/${post?.user.id}` : `/user/${post?.user.id}`}
              style={{ textDecoration: 'none' }}
            >
              {post.user.username}
            </a>
          </div>
        ) : (
          <Skeletons.SkeletonPostAvatar />
        )}

        {post && author && !isPostFetching ? (
          isMyPost ? (
            <div style={{ height: '40px' }} />
          ) : (
            <div className="call-message-buttons_frame">
              <div className="call-message-button message" onClick={clickOnMessageButton}>
                <Icon type="chatWhiteIcon" />
                <p>{t('translation:product_page.message.message')}</p>
              </div>

              {!post.hide_number && author.phone && (
                <PhoneCallButton
                  phone={author.phone}
                  isAuthorized={isAuthorized}
                  setOpenAuthPopup={setOpenAuthPopup}
                  postId={post.id}
                  getCreatedChat={getCreatedChat}
                />
              )}
            </div>
          )
        ) : (
          <Skeletons.SkeletonPostCallBtns />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="posts-owner-info_frame">{PostOwnerInfoContent()}</div>

      <div className={`posts-owner-info_bottom-menu ${isMyPost && 'is-my-post'}`}>
        {PostOwnerInfoContent()}
      </div>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={REDIRECT_URLS.PRODUCT_PAGE + `${post?.id}`}
      />
    </React.Fragment>
  );
};

export default PostOwnerInfo;
