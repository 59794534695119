import React, { FC } from 'react';
import "./postLocation.scss";
import { IPost } from "../../../../../types";
import { Skeletons } from '../../../../default_components';

interface IPostLocation {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostLocation: FC<IPostLocation> = ({ post, isPostFetching }) => {
  if (post && !isPostFetching) {
    return (
      post.user_location
        ? <div className="post-location">{post.user_location.name}</div>
        : <div className="empty-block" />
    )
  }

  return <Skeletons.SkeletonPostLocation />;
};

export default PostLocation;
