import React, { FC } from 'react';
import CircleComponent from './CircleComponent';
import { t } from 'i18next';

interface ICircleSteps {
  activeStep: number;
  creationComplete: boolean;
  moveToStep: (step: number) => void;
}

const CircleSteps: FC<ICircleSteps> = ({ activeStep, creationComplete, moveToStep }) => {
  return (
    <div className={`steps-container ${creationComplete && 'hide'}`}>
      <CircleComponent
        name={t('translation:creation.circle_1.message')}
        active={activeStep === 1}
        complete={activeStep === 2 || activeStep === 3}
        count={1}
        onClick={() => moveToStep(1)}
      />

      <div
        className={
          activeStep === 2 || activeStep === 3
            ? 'first-line_steps-container active'
            : 'first-line_steps-container'
        }
      />

      <CircleComponent
        name={t('translation:creation.circle_2.message')}
        active={activeStep === 2}
        complete={activeStep === 3}
        count={2}
        onClick={() => moveToStep(2)}
      />

      <div
        className={
          activeStep === 3 ? 'first-line_steps-container active' : 'first-line_steps-container'
        }
      />

      <CircleComponent
        name={t('translation:creation.circle_3.message')}
        active={activeStep === 3}
        complete={creationComplete}
        count={3}
        onClick={() => moveToStep(3)}
      />
    </div>
  );
};

export default CircleSteps;
