import React, { FC, useEffect, useState } from 'react';
import './filterBreadcrumbs.scss';
import { ICategory } from '../../../../../types';
import { getBreadcrumbsPath } from '../../../../../utils/get';
import { api } from '../../../../../services/Api';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import Breadcrumb from './Breadcrumb';
import { useAppSelector } from '../../../../../hooks/redux';
import useNavigateWithLocale from '../../../../../hooks/useNavigateWithLocale';

interface IFilterBreadcrumbs {
  category_id: string | undefined;
}

const FilterBreadcrumbs: FC<IFilterBreadcrumbs> = ({ category_id }) => {
  const { data: categories } = api.useGetAllCategoriesQuery();
  const navigateWithLocale = useNavigateWithLocale();

  const { posts, amountPostsFound } = useAppSelector(state => state.filterReducer);
  const [breadcrumbsPath, setBreadcrumbsPath] = useState<ICategory[]>([]);

  useEffect(() => {
    if (categories && category_id) {
      setBreadcrumbsPath(getBreadcrumbsPath(categories, category_id));
    }
  }, [categories, category_id]);

  const handleClickOnCategory = (category: ICategory): void => {
    navigateWithLocale(`/category/${category.id}`);
  };

  const lastCategory: ICategory = breadcrumbsPath[breadcrumbsPath.length - 1];

  return (
    <div className="filter-breadcrumbs-container">
      <div className="filter-breadcrumbs-wrapper">
        <span
          className={`breadcrumb-item ${!lastCategory && 'bold last'}`}
          onClick={() => navigateWithLocale(`/category/all`)}
        >
          {t('translation:dashboard.all_categories.message')}
        </span>

        {breadcrumbsPath.length > 1 && <span className="breadcrumb-separator">/</span>}

        {breadcrumbsPath.map((category, index) => (
          <Breadcrumb
            key={category.id}
            category={category}
            isLast={index === breadcrumbsPath.length - 1}
            onClick={() => handleClickOnCategory(category)}
          />
        ))}
      </div>

      {amountPostsFound > 0 && (
        <p className="products-count">
          {t('translation:filter.found.message')}
          {amountPostsFound}
          {t('translation:filter.products.message')}
        </p>
      )}
    </div>
  );
};

export default FilterBreadcrumbs;
