import React, { SetStateAction, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { CheckedElementStyle, RadioGroupStyle } from '../../../filter/filterOptions';
import { t } from 'i18next';

interface DealsRadioGroupProps {
  value?: any;
  setValue: React.Dispatch<SetStateAction<string> | any>;
  postTitle?: string;
}

export const DealsRadioGroup = ({ value, setValue, postTitle }: DealsRadioGroupProps) => {
  const [additionalValue, setAdditionalValue] = useState<string>('');

  const assets = [
    t('translation:deals.assets.sold_on_gm.message'),
    t('translation:deals.assets.sold_on_other.message'),
    t('translation:deals.assets.changed_mind.message'),
    t('translation:deals.assets.sold_along_time.message'),
    t('translation:deals.assets.other.message'),
  ];

  const additionalAssets = [
    t('translation:deals.additional_assets.more_than_week.message'),
    t('translation:deals.additional_assets.more_than_month.message'),
    t('translation:deals.additional_assets.more_than_six_months.message'),
    t('translation:deals.additional_assets.more_than_year.message'),
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value !== '4') {
      setAdditionalValue('');
    }
  };

  const renderAdditionalContent = (currentIndex: number) => {
    const isSelectedValue =
      (value === '4' || value === '5') && parseInt(value) === currentIndex + 1;

    if (isSelectedValue && value === '4') {
      return (
        <RadioGroup
          className="deal-modal__additional-options"
          sx={RadioGroupStyle}
          value={additionalValue}
          onChange={e => setAdditionalValue(e.target.value)}
        >
          {additionalAssets.map((asset, index) => (
            <FormControlLabel
              key={index}
              value={`4.${index + 1}`}
              control={<Radio sx={CheckedElementStyle} />}
              label={asset}
            />
          ))}
        </RadioGroup>
      );
    }

    if (isSelectedValue && value === '5') {
      return (
        <textarea
          rows={10}
          className="deal-modal__textarea"
          onChange={e => setAdditionalValue(e.target.value)}
          value={additionalValue}
          placeholder={`${t('translation:deals.textarea_placeholder.message')}`}
        />
      );
    }

    return null;
  };

  return (
    <div>
      <p className="deal-modal__title">
        {t('translation:deals.deactivate_reason_question.message')} <u>{postTitle}</u>?
      </p>

      <RadioGroup className="deal-modal__main-options" onChange={handleChange} sx={RadioGroupStyle}>
        {assets.map((item, index) => (
          <React.Fragment key={index}>
            <FormControlLabel
              value={index + 1}
              label={item}
              control={<Radio sx={CheckedElementStyle} />}
            />

            {renderAdditionalContent(index)}
          </React.Fragment>
        ))}
      </RadioGroup>
    </div>
  );
};
