import { useCallback } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

const useNavigateWithLocale = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const localeFromUrl = location.pathname.match(/^\/(en|ka|ru)/);
  const locale = localeFromUrl ? localeFromUrl[1] : 'en';

  const navigateWithLocale = useCallback(
    (path: To, options?: NavigateOptions) => {
      const newPath = localeFromUrl ? `/${locale}${path}` : path;
      navigate(newPath, options);
    },
    [locale, navigate],
  );

  return navigateWithLocale;
};

export default useNavigateWithLocale;
