import React, { FC, useState } from 'react';
import '../filterOptions.scss';
import { ICategoryField } from '../../../../types';
import { t } from 'i18next';
import { Checkbox, Collapse, FormControlLabel, RadioGroup } from '@mui/material';
import {
  categoryFields,
  CheckedElementStyle,
  FilterCollapseStyle,
  getShowMoreOptionsTest,
  RadioGroupStyle,
} from '../index';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import { ISearchRequest } from '../../../../services/types';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';

interface IFieldsSelect {
  field: ICategoryField;
  search: ({}: ISearchRequest) => void;
}

const FieldsSelect: FC<IFieldsSelect> = ({ field, search }) => {
  const { setFieldsStates, setPosts } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(state => state.filterReducer);

  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false);

  const { updateQueryParams } = useUpdateQueryParams();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fields = { ...fieldsStates };
    const regions = Object.values(locationsStates);

    if (event.target.checked) {
      setPosts(undefined);
      fields[event.target.name] = event.target.id;
    } else {
      delete fields[event.target.name];
    }
    setFieldsStates(fields);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      location_id: regions.length ? regions : undefined,
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmade) {
      requestObject.handmade = isHandmade;
    }

    requestObject.category_fields = categoryFields(fields);

    updateQueryParams({
      page: currentPage,
      used: postsStates.used,
      new: postsStates.new,
      sort_by: sortBy,
      handmade: isHandmade,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
      locations: regions,
      fields: encodeURIComponent(JSON.stringify(categoryFields(fields))),
    });

    search(requestObject);
  };

  const getFieldOptionsPortion = (range: number[]) => {
    return field.options
      .slice(...range)
      .map(option => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={Boolean(fieldsStates[option]) || false}
              onChange={handleChange}
              name={option}
              id={field.id}
              sx={CheckedElementStyle}
            />
          }
          label={option}
        />
      ));
  };

  return (
    <div>
      <p className="filter-menu__title">{field.title}</p>

      <RadioGroup sx={RadioGroupStyle}>{getFieldOptionsPortion([0, 4])}</RadioGroup>

      {field.options.length > 4 && (
        <div>
          <Collapse in={showMoreOptions} timeout="auto" sx={FilterCollapseStyle}>
            {getFieldOptionsPortion([4])}
          </Collapse>

          <p className="show-more-options" onClick={() => setShowMoreOptions(!showMoreOptions)}>
            {t(getShowMoreOptionsTest(showMoreOptions))}
          </p>
        </div>
      )}
    </div>
  );
};

export default FieldsSelect;
