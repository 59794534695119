import React, { FC, SetStateAction, useState } from 'react';
import { Icon } from '../index';
import './inputGamar.scss';
import { t } from 'i18next';

interface IInput
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string;
  setValue: React.Dispatch<SetStateAction<string>> | ((value: string) => void);
  onChange?: any;
  label: string;
  error?: string;
  passwordType?: boolean;
  isTextarea?: boolean;
  textAreaOnBlur?: () => void;
  showPlaceholder?: boolean;
  hideError?: boolean;
  w?: number;
  setHideError?: React.Dispatch<SetStateAction<boolean>>;
  dataCy?: string;
}

const InputGamar: FC<IInput> = ({
  value,
  setValue,
  label,
  error,
  passwordType,
  isTextarea,
  textAreaOnBlur,
  showPlaceholder,
  hideError,
  setHideError,
  w,
  dataCy,
  ...otherProps
}) => {
  const [showPass, setShowPass] = useState<boolean>(false);
  const isShowError = !hideError && error;
  const inputClass = `${isTextarea ? 'textarea' : 'input'} ${!hideError && error && 'error'} ${
    passwordType && 'passwords'
  } ${showPlaceholder && 'show-placeholder'}`;

  return (
    <div className="input-container" style={{ maxWidth: w }}>
      <p className="input-label">{label}</p>

      {isTextarea ? (
        <textarea
          value={value}
          onChange={(e: any) => {
            setValue(e.target.value);
            setHideError && setHideError(true);
          }}
          className={inputClass}
          onBlur={textAreaOnBlur}
          placeholder={
            t('translation:creation.second_step.description_placeholder.message') ||
            'Describe your product'
          }
          data-cy={dataCy}
        />
      ) : (
        <input
          value={otherProps.disabled ? '' : value}
          onChange={e => {
            setValue(e.target.value);
            setHideError && setHideError(true);
          }}
          className={inputClass}
          type={passwordType && !showPass ? 'password' : 'text'}
          data-cy={dataCy}
          {...otherProps}
        />
      )}

      {!isTextarea && passwordType && (
        <div onClick={() => setShowPass(!showPass)} className="input-icon-container">
          <div className={`eyeHide ${showPass && 'show'}`}>
            <Icon type={'eyeHide'} />
          </div>

          <div className={`eyeShow ${!showPass && 'show'}`}>
            <Icon type={'eyeShow'} />
          </div>
        </div>
      )}

      <div
        className={`error-message-container ${isShowError && 'visible'} ${
          isShowError && isTextarea && 'textareaError'
        }`}
      >
        <div className="error-message">{error}</div>
      </div>
    </div>
  );
};

export default InputGamar;
