import React, { FC } from 'react';
import "./loader.scss";

const Loader: FC<{ isWhite?: boolean }> = ({ isWhite }) => {
  return (
    <div className="gamar-loader-box">
      <div className={ `gamar-loader ${ isWhite && "white" }` }>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
