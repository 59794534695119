import React, { FC } from 'react';
import './noPosts.scss';
import { t } from 'i18next';
import { noPostLabels, postsStatus } from '..';
import MyPostsActiveBlank from '../../default_components/assets/icons/MyPostsActiveBlank.svg';
import MyPostsOnModerationBlank from '../../default_components/assets/icons/MyPostsOnModerationBlank.svg';
import MyPostsRejectedBlank from '../../default_components/assets/icons/MyPostsRejectedBlank.svg';
import MyPostsInactiveBlank from '../../default_components/assets/icons/NotPurchases.svg';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface NoPostsProps {
  index: number;
  currentPostsStatus: postsStatus;
}

const statusToImageMap: Record<string, string> = {
  active: MyPostsActiveBlank,
  review: MyPostsOnModerationBlank,
  rejected: MyPostsRejectedBlank,
  closed: MyPostsInactiveBlank,
};

const NoPosts: FC<NoPostsProps> = ({ index, currentPostsStatus }) => {
  const navigateWithLocale = useNavigateWithLocale();

  const moveToAction = () => navigateWithLocale(`${noPostLabels[index].link}`);

  const statusImage = statusToImageMap[currentPostsStatus.value];

  return (
    <div className="no-posts__container">
      <img src={statusImage} alt="" />

      <p className="no-my-posts-text">
        {t(`translation:my_posts.${noPostLabels[index].value}.message`)}
      </p>
      <button className="no-posts__button" onClick={moveToAction}>
        {t(`translation:my_posts.${noPostLabels[index].buttonValue}.message`)}
      </button>
    </div>
  );
};

export default NoPosts;
