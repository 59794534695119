import React, { FC } from 'react';
import './noticeBlock.scss';
import WarningCircle from '../assets/icons/WarningCircle.svg';
import NoticeCircle from '../assets/icons/NoticeCircle.svg';

interface INoticeBlock {
  typeIcon?: 'notice' | 'warning';
  textElement: React.JSX.Element;
}

const NoticeBlock: FC<INoticeBlock> = ({ typeIcon = 'notice', textElement }) => {
  const iconSrc = typeIcon === 'warning' ? WarningCircle : NoticeCircle;

  return (
    <div className="notice-block">
      <img src={iconSrc} alt="Notice" />
      {textElement}
    </div>
  );
};

export default NoticeBlock;
