import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { api } from '../../../services/Api';
import { ICategory } from '../../../types';
import { AutocompleteSelect } from '../../default_components';
import './firstStep.scss';
import InputGamar from '../../default_components/inputGamar/InputGamar';
import { t } from 'i18next';

interface IFirstStep {
  title: string;
  selectedCategory: ICategory | undefined;
  selectedSubCategory: ICategory | undefined;
  setTitle: React.Dispatch<SetStateAction<string>>;
  setSelectedCategory: React.Dispatch<SetStateAction<ICategory | undefined>>;
  setSelectedSubCategory: React.Dispatch<SetStateAction<ICategory | undefined>>;
  moveToStep: (step: number) => void;
}

const FirstStep: FC<IFirstStep> = ({
  title,
  setTitle,
  selectedCategory,
  selectedSubCategory,
  setSelectedCategory,
  setSelectedSubCategory,
  moveToStep,
}) => {
  const { data: categories } = api.useGetCategoriesQuery();

  const [errorName, setErrorName] = useState<string>('');
  const [showMoveButton, setShowMoveButton] = useState<boolean>(false);
  const [hideErrorName, setHideErrorName] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    title.split(' ').join('').length > 3 && selectedCategory?.id
      ? setShowMoveButton(true)
      : setShowMoveButton(false);
  }, [title, selectedCategory, selectedSubCategory]);

  const checkValidationOfName = (): void => {
    const titleLength = title.split(' ').join('').length;

    if (titleLength < 4 || titleLength > 80) {
      setErrorName(t('translation:creation.first_step.name_error.message') || 'error');
      setHideErrorName(false);
    } else {
      setErrorName('');
    }
  };

  return (
    <React.Fragment>
      <div className="inputs-containers">
        <InputGamar
          value={title}
          setValue={setTitle}
          label={t('translation:creation.first_step.name_label.message') || 'tires'}
          placeholder={t('translation:creation.first_step.name_placeholder.message') || 'tires'}
          type="name"
          onBlur={checkValidationOfName}
          error={errorName}
          hideError={hideErrorName}
          setHideError={setHideErrorName}
          dataCy="creation-first-step-name-input"
        />

        <div className="select-frame-column">
          {categories && (
            <AutocompleteSelect
              assets={categories}
              selectedItem={selectedCategory}
              setSelectedItem={setSelectedCategory}
              title={t('translation:creation.first_step.category.message')}
              placeholder={t('translation:creation.first_step.placeholder.message')}
              dataCy="creation-first-step-category-select"
            />
          )}

          {selectedCategory && (
            <AutocompleteSelect
              assets={selectedCategory.children}
              selectedItem={selectedSubCategory}
              setSelectedItem={setSelectedSubCategory}
              title={t('translation:creation.first_step.subcategory.message')}
              placeholder={t('translation:creation.first_step.placeholder.message')}
              dataCy="creation-first-step-subcategory-select"
            />
          )}
        </div>
      </div>

      <div className={`move-buttons_container to-second ${showMoveButton && 'show'}`}>
        <p data-cy="creation-first-step-move-next" onClick={() => moveToStep(2)}>
          {t('translation:creation.next_step.message')}⠀❯
        </p>
      </div>
    </React.Fragment>
  );
};

export default FirstStep;
