import {localStorageKeys} from "../routes";

type basicAuthHeadersParams = { access_token?: string, authorization_code?: string } | undefined;
type basicAuthHeadersObject = {
  Authorization?: string;
  'X-Locale'?: string,
  'AccessToken'?: string,
  'Authorization-Code'?: string
} | undefined

export const basicAuthHeaders = (params?: basicAuthHeadersParams):basicAuthHeadersObject => {
  const token: string | null = localStorage.getItem(localStorageKeys.token);
  const locale: string | null = localStorage.getItem(localStorageKeys.locale);

  if (token) {
    return {
      Authorization: token,
    };
  } else if (locale) {
    return {
      'X-Locale': locale,
      'AccessToken': params?.access_token,
      'Authorization-Code': params?.authorization_code
    };
  }
};
