import React, { FC } from 'react';
import './postFields.scss';
import { t } from 'i18next';
import { IPost } from '../../../../types';

interface IPostFields {
  post: IPost;
}

const PostFields: FC<IPostFields> = ({ post }) => {
  return (
    <div className="post-fields__frame">
      <h2 className="post-fields__frame-title">{t('translation:product_page.fields.message')}</h2>

      <div className="post-fields__container">
        {post.fields.map((field, id) => (
          <div key={id} className="post-field__field-container">
            <div className="field-title-container">
              <p className="field-title">{field.title}</p>
              <span className="field-title-dots" />
            </div>

            <p className="field-options">{field.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostFields;
