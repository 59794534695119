import React, { FC, useState } from 'react';
import './postCard.scss';
import { getShortProductName } from '../../../lib/text';
import { LikeComponent, Skeletons } from '../../default_components';
import { IPost } from '../../../types';
import { t } from 'i18next';
import { getPostPrice } from '../../../utils/get';
import { useAppSelector } from '../../../hooks/redux';
import { PostStateChip } from './PostStateChip/PostStateChip';
import PostCardCarousel from './PostCardCarousel/PostCardCarousel';
import { useLocaleFromUrl } from '../../../utils/localeFromUrl';

interface IProductCard {
  post: IPost;
  filter?: boolean;
  myPosts?: boolean;
}

const PostCard: FC<IProductCard> = ({ post, filter, myPosts }) => {
  const { activeCurrency } = useAppSelector(state => state.authReducer);
  const [downloadedImage, setDownloadedImage] = useState<boolean>(false);
  const localeStr = useLocaleFromUrl();

  const href = localeStr ? `${localeStr}/item/${post.id}` : `/item/${post.id}`;

  return (
    <a
      href={href}
      className={`post-card_frame liked ${filter ? 'is-filter' : ''} ${
        myPosts ? 'is-my-posts' : ''
      }`}
      data-cy="post-card"
    >
      <div className="post-card-state_container">
        <PostStateChip isUsed={post.used} />
      </div>

      <PostCardCarousel images={[...post.images_variants].reverse()} isFilter={filter} />

      <LikeComponent post={post} />

      <div className="post-card-content_container">
        <div className="post-card-price-location_container">
          {post.price === null ? (
            <div className="post-card-price discussed">
              {t('translation:product_card.discussed.message')}
            </div>
          ) : (
            <div className="post-card-price">
              {post.price == 0.0
                ? t('translation:product_card.free.message')
                : getPostPrice(post, activeCurrency)}
            </div>
          )}

          <span className="post-card-location">{post.user_location?.name}</span>
        </div>

        <h4 className="post-card-title">{getShortProductName(45, post.title)}</h4>

        <div className="post-card-user-info_container">
          {post.user.avatar ? (
            <img className="user-avatar" alt={'User avatar image'} />
          ) : (
            <div className="post-card-user-avatar">
              <span>{post.user.username[0].toUpperCase()}</span>
            </div>
          )}

          <span className="post-card-username">{post.user.username}</span>
        </div>
      </div>
    </a>
  );
};

export default PostCard;
