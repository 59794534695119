import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { Icon, Snackbar } from '../../../default_components';
import { useMethodsSnackbar } from '../../../../hooks/useMethodsSnackbar';
import { t } from 'i18next';
import { ImageCard } from '..';

export interface ImageObj {
  file: Blob;
  url: string;
}

const max: number = 10;
const maxSize = 50000000;

interface IUploadContainer {
  images: Blob[];
  setImages: React.Dispatch<SetStateAction<Blob[]>>;
  dataCy?: string;
}

const ImageUploader: FC<IUploadContainer> = ({ images, setImages, dataCy }) => {
  const [imageCards, setImageCards] = useState<ImageObj[]>([]);
  const [selectedUrl, setSelectedUrl] = useState<string | undefined>();

  useEffect(() => {
    if (images.length) {
      const urls: ImageObj[] = images.map(file => ({
        file,
        url: URL.createObjectURL(new Blob([file])),
      }));
      setImageCards([...urls]);
    }
  }, []);

  const uploadImages = (e: React.ChangeEvent<HTMLInputElement>, edit?: boolean) => {
    if (!edit) {
      if (!e.target.files?.length) return;
      if (e.target.files?.length > max)
        return getActiveSnackbar(
          t('translation:creation.second_step.overloaded_select.message'),
          'info',
        );
      if (e.target.files?.length > max - imageCards.length)
        return getActiveSnackbar(
          t('translation:creation.second_step.invalid_select.message'),
          'info',
        );
    }

    if (!e.target.files?.length) return;

    const files = Object.values(e.target.files)
      .filter(file => {
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
          if (file.size < maxSize) return file;
        }
      })
      .map(file => ({
        file,
        url: URL.createObjectURL(new Blob([file])),
      }));

    const move = edit ? 'edit' : 'add';

    switch (move) {
      case 'edit': {
        const firstFile = files[0];
        const newCards = imageCards.map(img => {
          if (img.url === selectedUrl) {
            img.url = firstFile.url;
            img.file = firstFile.file;
          }
          return img;
        });
        setImageCards(newCards);
        setImages([...newCards.map(file => file.file)]);
        return;
      }
      case 'add': {
        setImages([...images, ...files.map(file => file.file)]);
        setImageCards([...imageCards, ...files]);
        return;
      }
      default:
        return false;
    }
  };

  const removeCard = (url: string) => {
    const newImageCards = imageCards.filter(img => img.url !== url);
    setImages([...newImageCards.map(file => file.file)]);
    setImageCards(newImageCards);
  };

  const editCard = (e: React.ChangeEvent<HTMLInputElement>) => uploadImages(e, true);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  return (
    <div className="upload-container">
      {imageCards[9] ? (
        <ImageCard
          url={imageCards[9].url}
          isLast={true}
          removeCard={() => removeCard(imageCards[9].url)}
          selectUrlToEdit={() => setSelectedUrl(imageCards[9].url)}
          editCard={editCard}
        />
      ) : (
        <label htmlFor="upload_image" className="upload-frame">
          <Icon type="upload" size="11x-large" />
        </label>
      )}

      <input
        accept=".jpg,.jpeg,.png"
        id="upload_image"
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={uploadImages}
        data-cy={dataCy}
      />

      <div className="cards-container" data-cy="creation-second-step-image-cards">
        {imageCards?.slice(0, 9).map(img => (
          <ImageCard
            key={img.url}
            url={img.url}
            removeCard={() => removeCard(img.url)}
            selectUrlToEdit={() => setSelectedUrl(img.url)}
            editCard={editCard}
          />
        ))}
      </div>

      <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
    </div>
  );
};

export default ImageUploader;
