const MyPostsTabStyle = {
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },

  '& .MuiTabs-indicator': {
    backgroundColor: '#DD2006',
  },

  '& .MuiTab-root': {
    borderRadius: '16px 16px 0 0',
    fontFamily: 'Noto Sans, sans-serif',
    textTransform: 'none',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '130%',
    paddingBottom: '0',
    color: '#000',
    flexDirection: 'row',

    '@media (max-width: 760px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },

    '@media (max-width: 480px)': {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },

  '& .MuiTab-root.Mui-selected': {
    fontWeight: '500',
    color: '#DD2006',
  },
};

export { MyPostsTabStyle };
