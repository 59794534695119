import React, { FC, SetStateAction, useState } from 'react';
import './postOwnerInfo.scss';
import { Icon } from '../../../../default_components';
import { t } from 'i18next';
import basicAuthHeaders, { ApiUrl } from '../../../../../services';
import { useAppSelector } from '../../../../../hooks/redux';
import { IChatRoom } from '../../../../../types';

interface IPhoneCallButton {
  phone: string;
  isAuthorized: boolean;
  setOpenAuthPopup: React.Dispatch<SetStateAction<boolean>>;
  postId: string;
  getCreatedChat: (postId: string) => Promise<IChatRoom | undefined>;
}

const PhoneCallButton: FC<IPhoneCallButton> = ({ phone, isAuthorized, setOpenAuthPopup, postId, getCreatedChat }) => {
  const { chats } = useAppSelector(state => state.chatReducer);

  const [showFullNumber, setShowFullNumber] = useState(false);
  const className = `call-message-button call ${showFullNumber && 'show-number'}`;

  const handleClick = () => {
    if (!showFullNumber) {
      isAuthorized ? setShowFullNumber(true) : setOpenAuthPopup(true);
    } else {
      window.location.href = `tel:${phone}`;
      createPhoneCallMessage();
    }
  };

  function getChatIdWithPostAuthor(): string | undefined {
    const chat = chats.find(chat => chat.post_id === postId);
    return chat?.id || undefined;
  }

  function createPhoneCallMessage(): void {
    const requestBody = new FormData();
    const chatId = getChatIdWithPostAuthor();

    requestBody.set('phone_call', 'true');

    if (chatId) {
      requestBody.set('chat_id', chatId);
      sendPhoneCallMassage(requestBody);
    } else {
      const response = getCreatedChat(postId);

      response
        .then(chat => {
          if (chat) {
            requestBody.set('chat_id', chat.id);
            sendPhoneCallMassage(requestBody);
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  async function sendPhoneCallMassage(requestBody: FormData): Promise<void> {
    try {
      const response = await fetch(`${ApiUrl}/messages`, {
        method: 'POST',
        headers: basicAuthHeaders(),
        body: requestBody,
      });

      if (!response.ok) {
        console.error(`Response status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={className} onClick={handleClick}>
      <Icon type="phone" />
      <p>
        {showFullNumber ? phone : `${phone.substring(0, 4)}...`}{' '}
        {!showFullNumber && (
          <span className="show-full-number">
            {t('translation:product_page.view_number.message')}
          </span>
        )}
      </p>
    </div>
  );
};

export default PhoneCallButton;
