import React, { FC, useEffect, useState } from 'react';
import { Icon } from '../../../default_components';
import { OPEN_HEADER } from '../SearchField';
import './headerHistory.scss';
import { t } from 'i18next';
import { localStorageKeys } from '../../../../routes';

interface IHeaderHistory {
  search: (query: string) => void;
  overlay: string | boolean;
  searchErrorMessage: string;
}

const HeaderHistory: FC<IHeaderHistory> = ({ search, overlay, searchErrorMessage }) => {
  const [searchArray, setSearchArray] = useState<string[] | undefined>();

  useEffect(() => {
    setSearchArray(localStorage.getItem(localStorageKeys.search)?.split(','));
  }, [localStorage.getItem(localStorageKeys.search)]);

  const removeSearchItem = (value: string): void => {
    const newSearchArray = searchArray?.filter(item => item != value);
    newSearchArray && localStorage.setItem(localStorageKeys.search, newSearchArray?.join());
    if (!newSearchArray?.length) localStorage.removeItem(localStorageKeys.search);
    setSearchArray(newSearchArray);
  };

  const clearSearchHistory = () => {
    localStorage.removeItem(localStorageKeys.search);
    setSearchArray(undefined);
  };

  useEffect(() => {
    document.body.style.overflow = overlay === OPEN_HEADER.HEADER_HISTORY ? 'hidden' : 'auto';
  }, [overlay]);

  return (
    <div className="header-history-frame">
      <div
        className={`header-history-container ${overlay === OPEN_HEADER.HEADER_HISTORY && 'show'}`}
      >
        {overlay === OPEN_HEADER.HEADER_HISTORY && (
          <div className={`search-container ${searchArray?.length && 'show-history'}`}>
            <div className={`search-error-message ${searchErrorMessage && 'show'}`}>
              {searchErrorMessage}
            </div>

            {searchArray?.length && (
              <React.Fragment>
                <div className="titles">
                  <p>{t('translation:header.search_field_history.message')}</p>

                  <p onClick={clearSearchHistory}>
                    {t('translation:header.search_field_clear.message')}
                  </p>
                </div>

                {searchArray
                  .slice(searchArray.length > 3 ? searchArray.length - 3 : 0, searchArray.length)
                  .map((query, id) => (
                    <div key={id} className="search-item">
                      <p onClick={() => search(query)}>{query}</p>

                      <Icon onClick={() => removeSearchItem(query)} type="cross" />
                    </div>
                  ))}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderHistory;
