import React, { FC, useEffect, useState } from 'react';
import './myProfile.scss';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Tab, Tabs } from '@mui/material';
import { Email, Password, PersonalInfo } from './myProfileContent';
import { MyPostsTabStyle } from '../default_components/tabPanel/TabPanelStyle';
import { apiProfile } from '../../services/ApiProfile';
import useNavigateWithLocale from '../../hooks/useNavigateWithLocale';

const stepsOfProfile = [
  { value: 'personal_info', id: 0 },
  { value: 'email', id: 1 },
  { value: 'password', id: 2 },
];

const MyProfile: FC = () => {
  const { data: profileInfo, isError } = apiProfile.useGetProfileInfoQuery();
  const navigateWithLocale = useNavigateWithLocale();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [timeBeforeResendingCodeForEmail, setTimeBeforeResendingCodeForEmail] = useState<number>(0);
  const [timeBeforeResendingCodeForPass, setTimeBeforeResendingCodeForPass] = useState<number>(0);

  useEffect(() => {
    isError && navigateWithLocale('/501');
  }, [isError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void =>
    setCurrentStep(newValue);

  return (
    <React.Fragment>
      <Header />

      <div className="my-profile-frame">
        <div className="profile-tabs_container">
          <Tabs
            value={currentStep}
            onChange={handleChange}
            sx={MyPostsTabStyle}
            variant="scrollable"
          >
            {stepsOfProfile.map(item => (
              <Tab key={item.id} label={t(`profile.${item.value}.message`)} />
            ))}
          </Tabs>
        </div>

        <div className="my-profile-content-frame">
          {currentStep === 0 && <PersonalInfo profileInfo={profileInfo} />}

          {currentStep === 1 && (
            <Email
              profileInfo={profileInfo}
              timeBeforeResendingCodeForEmail={timeBeforeResendingCodeForEmail}
              setTimeBeforeResendingCodeForEmail={setTimeBeforeResendingCodeForEmail}
            />
          )}

          {currentStep === 2 && (
            <Password
              profileInfo={profileInfo}
              timeBeforeResendingCodeForPass={timeBeforeResendingCodeForPass}
              setTimeBeforeResendingCodeForPass={setTimeBeforeResendingCodeForPass}
              setCurrentStep={setCurrentStep}
            />
          )}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default MyProfile;
