import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './secondStep.scss';
import { CheckBox, InputGamar, RadioGroup } from '../../default_components';
import { FieldInput, ImageUploader } from '.';
import { ICategory, IPost } from '../../../types';
import { t } from 'i18next';
import { STATE_PRICE } from '../PostActionsPage';

interface ISecondStep {
  selectedCategory: ICategory | undefined;
  fields: Map<string, string>;
  description: string;
  price: string;
  customPrice: string;
  images: Blob[];
  moveToStep: (step: number) => void;
  validationOfPrice: () => boolean;
  validationOfDescription: () => boolean | undefined;
  setImages: Dispatch<SetStateAction<Blob[]>>;
  setCustomPrice: Dispatch<SetStateAction<string>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setPrice: Dispatch<SetStateAction<string>>;
  setUsed: Dispatch<SetStateAction<boolean>>;
  post?: IPost;
  currency: string;
  setCurrency: Dispatch<SetStateAction<string>>;
  isHandmade: boolean;
  setIsHandmade: Dispatch<SetStateAction<boolean>>;
}

const SecondStep: FC<ISecondStep> = ({
  selectedCategory,
  fields,
  description,
  setDescription,
  price,
  customPrice,
  images,
  moveToStep,
  validationOfPrice,
  validationOfDescription,
  setImages,
  setCustomPrice,
  setPrice,
  setUsed,
  post,
  currency,
  setCurrency,
  isHandmade,
  setIsHandmade
}) => {
  const [cardState, setCardState] = useState<string>('used');
  const [selectedCurrency, setSelectedCurrency] = useState<string>(currency.toLowerCase());
  const [showMoveButton, setShowMoveButton] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [errorPrice, setErrorPrice] = useState<string>('');
  const [hideErrorPrice, setHideErrorPrice] = useState<boolean>(false);
  const [hideErrorDescription, setHideErrorDescription] = useState<boolean>(false);

  const cardStates: string[] = ['Used', 'New'];
  const currencies: string[] = ['USD', 'GEL'];

  useEffect(() => {
    customPrice.length && setErrorPrice('');

    validationOfDescription() && validationOfPrice() && (post ? true : images.length > 0)
      ? setShowMoveButton(true)
      : setShowMoveButton(false);
  }, [description, price, images, customPrice]);

  useEffect(() => {
    setUsed(Boolean(cardState === 'used'));
  }, [cardState]);

  useEffect(() => {
    setCurrency(selectedCurrency.slice(0, 3).toUpperCase());
  }, [selectedCurrency]);

  // useEffect(() => {
  //   post && setSelectedCurrency(currency.toLowerCase());
  // }, [currency]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnBlurDescription = (): void => {
    if (!validationOfDescription()) {
      setHideErrorDescription(false);
      setErrorDescription(
        t('translation:creation.second_step.description_error.message') || 'error',
      );
    }
  };

  const handleOnBlurPrice = (): void => {
    if (!validationOfPrice()) {
      setHideErrorPrice(false);
      setErrorPrice(t('translation:creation.second_step.price_error.message') || 'error');
      setPrice('');
    }
  };

  const changePriceToNonBillable = (statePrice: string): void => {
    setHideErrorPrice(true);
    setPrice('');
    customPrice === statePrice ? setCustomPrice('') : setCustomPrice(statePrice);
  };

  return (
    <div className="second-step">
      <div>
        <ImageUploader
          images={images}
          setImages={setImages}
          dataCy="creation-second-step-image-upload"
        />
        <p className="upload-description">
          {t('translation:creation.second_step.upload_description.message')}
        </p>
      </div>

      <div className="description-container">
        <InputGamar
          w={700}
          value={description}
          setValue={setDescription}
          label={t('translation:creation.second_step.description_label.message')}
          error={errorDescription}
          hideError={hideErrorDescription}
          setHideError={setHideErrorDescription}
          isTextarea
          textAreaOnBlur={handleOnBlurDescription}
          dataCy="creation-second-step-description-input"
        />
      </div>

      <div>
        <RadioGroup
          value={cardState}
          setValue={setCardState}
          assets={cardStates}
          name={t('translation:creation.second_step.state_label.message')}
          color="Black"
        />
      </div>

      <div>
        <RadioGroup
          value={selectedCurrency}
          setValue={setSelectedCurrency}
          assets={currencies}
          name={t('translation:creation.second_step.currency_label.message')}
          color="Black"
        />
      </div>

      <div className="input-price-container">
        <div className={`input-price ${errorPrice && hideErrorPrice && 'hide-error'}`}>
          <InputGamar
            value={
              customPrice === STATE_PRICE.FREE || customPrice === STATE_PRICE.DISCUSSED
                ? '0'
                : price
            }
            setValue={setPrice}
            disabled={customPrice === STATE_PRICE.FREE || customPrice === STATE_PRICE.DISCUSSED}
            label={t('translation:creation.second_step.price_label.message')}
            placeholder={'0'}
            w={220}
            onBlur={handleOnBlurPrice}
            error={errorPrice}
            hideError={hideErrorPrice}
            setHideError={setHideErrorPrice}
            type="number"
            dataCy="creation-second-step-price-input"
          />
        </div>

        <CheckBox
          color="black"
          value={Boolean(customPrice === STATE_PRICE.DISCUSSED)}
          onChange={() => changePriceToNonBillable(STATE_PRICE.DISCUSSED)}
          onMouseDown={e => e.preventDefault()}
          label={t('translation:creation.second_step.discussed_label.message')}
        />

        <CheckBox
          color="black"
          value={Boolean(customPrice === STATE_PRICE.FREE)}
          onChange={() => changePriceToNonBillable(STATE_PRICE.FREE)}
          onMouseDown={e => e.preventDefault()}
          label={t('translation:creation.second_step.free_label.message')}
        />
      </div>

      <CheckBox
        color="black"
        value={isHandmade}
        onChange={() => setIsHandmade(!isHandmade)}
        onMouseDown={e => e.preventDefault()}
        label={t('translation:product_card.handmade.message')}
      />

      {selectedCategory &&
        selectedCategory.fields.map(field => (
          <FieldInput key={field.id} field={field} fields={fields} />
        ))}

      <div className="move-buttons_container to-third">
        <p onClick={() => moveToStep(1)}>❮⠀{t('translation:creation.prev_step.message')}</p>

        <p className={`${showMoveButton && 'show'}`} onClick={() => moveToStep(3)}>
          {t('translation:creation.next_step.message')}⠀❯
        </p>
      </div>
    </div>
  );
};

export default SecondStep;
