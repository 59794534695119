import React, { FC, useEffect, useState } from 'react';
import '../Chat.scss';
import { IChatRoom } from '../../../types';
import Skeleton from '@mui/material/Skeleton';
import { api } from '../../../services/Api';
import { Icon } from '../../default_components';
import { useActions, useAppSelector } from '../../../hooks/redux';
import { localStorageKeys } from '../../../routes';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

const PostInfoBar: FC<{ selectedRoom: IChatRoom }> = React.memo(({ selectedRoom }) => {
  const { data: post } = api.useFetchPostQuery({ post_id: selectedRoom.post_id });
  const { chat_id } = useAppSelector(state => state.chatReducer);
  const { setSelectedChat } = useActions();
  const [loadedImage, setLoadedImage] = useState<boolean>(false);
  const navigateWithLocale = useNavigateWithLocale();

  useEffect(() => {
    setLoadedImage(false);
  }, [chat_id]);

  const handleCloseSelectedChat = (): void => {
    setSelectedChat(undefined);
    localStorage.removeItem(localStorageKeys.chat_storage_key);
  };

  return (
    <div className="chat__chat-room-user">
      <div
        className="chat__post-container"
        onClick={() => navigateWithLocale(`/item/${selectedRoom.post_id}`)}
      >
        <div className="chat__image-container">
          {!loadedImage && (
            <Skeleton
              sx={{ width: '100%', height: '100%' }}
              animation="wave"
              variant="rectangular"
            />
          )}

          {post?.images?.length ? (
            <img src={post?.images[0]} alt="icon" onLoad={() => setLoadedImage(true)} />
          ) : (
            <Icon type="imageNotFound" />
          )}
        </div>

        <div className="chat__post-data">
          <p className="chat__post-title">{selectedRoom.post_title}</p>

          <p className="chat__post-price">
            {
              selectedRoom?.post_price === null
                ? 'Discussed'
                : selectedRoom?.post_price == 0.0
                ? 'Free'
                : '$' + Number(selectedRoom.post_price) //change to price
            }
          </p>
        </div>
      </div>

      <div className="chat__close-selected-chat" onClick={handleCloseSelectedChat}>
        <Icon type="cross" />
      </div>
    </div>
  );
});

export default PostInfoBar;
