import React, { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { categoryFields, CheckedElementStyle, RadioGroupStyle } from '../index';
import { t } from 'i18next';
import { ISearchRequest } from '../../../../services/types';
import { useActions, useAppSelector } from '../../../../hooks/redux';
import useUpdateQueryParams from '../../../../hooks/useUpdateQueryParams';

interface IHandmadeOption {
  search: ({}: ISearchRequest) => void;
}

const HandmadeOption: FC<IHandmadeOption> = ({ search }) => {
  const { setIsHandmade } = useActions();
  const {
    currentPage,
    isHandmade,
    query,
    categoryIds,
    sortBy,
    postsStates,
    locationsStates,
    fieldsStates,
    priceValue,
  } = useAppSelector(state => state.filterReducer);

  const { updateQueryParams } = useUpdateQueryParams();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isHandmadeValue: boolean = event.target.checked;
    const regions = Object.values(locationsStates);

    const requestObject: ISearchRequest = {
      category_id: categoryIds,
      page: 1,
      sort_by: sortBy,
      used:
        (!postsStates.used && !postsStates.new) || (postsStates.used && postsStates.new)
          ? undefined
          : postsStates.used,
      location_id: regions.length ? regions : undefined,
      category_fields: categoryFields(fieldsStates),
      price: priceValue,
    };

    if (query) {
      requestObject.q = query;
    }

    if (isHandmadeValue) {
      requestObject.handmade = isHandmadeValue;
    }

    setIsHandmade(isHandmadeValue);

    updateQueryParams({
      page: currentPage,
      used: postsStates.used,
      new: postsStates.new,
      sort_by: sortBy,
      handmade: isHandmadeValue,
      price_min: (priceValue as number[])[0],
      price_max: (priceValue as number[])[1],
      locations: regions,
      fields: encodeURIComponent(JSON.stringify(categoryFields(fieldsStates))),
    });

    search(requestObject);
  };

  return (
    <div>
      <FormControlLabel
        sx={{
          ...RadioGroupStyle,
          marginLeft: 0,
        }}
        control={<Checkbox checked={isHandmade} onChange={handleChange} sx={CheckedElementStyle} />}
        label={t('translation:filter.handmade.message')}
      />
    </div>
  );
};

export default HandmadeOption;
