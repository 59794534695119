import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { api } from '../../../../services/Api';
import { ICategory } from '../../../../types';
import { CatalogDrop, CatalogMobile } from '../index';
import { isDesktop, isTablet, isMobile } from '../../../../style';

interface ICatalogRoute {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CatalogRoute: FC<ICatalogRoute> = ({ open, setOpen }) => {
  const { data: categories, isSuccess } = api.useGetCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<ICategory | undefined>();

  useEffect(() => {
    !open && setSelectedCategory(undefined);
  }, [open]);

  if (isSuccess) {
    return (
      <React.Fragment>
        {(isDesktop || isTablet) && <CatalogDrop categories={categories} setOpen={setOpen} />}

        {isMobile && (
          <CatalogMobile
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setOpen={setOpen}
          />
        )}
      </React.Fragment>
    );
  }

  return <div />;
};

export default CatalogRoute;
