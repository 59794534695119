import React, { FC, SetStateAction, useEffect } from 'react';
import ThirdStepContent from './ThirdStepContent';
import './thirdStep.scss';
import Loader from '../../default_components/loader/Loader';
import { t } from 'i18next';

interface IThirdStep {
  sendPost: () => void;
  isLoading: boolean;
  showMobileNumber: boolean;
  showLocation: boolean;
  setShowMobileNumber: React.Dispatch<SetStateAction<boolean>>;
  setShowLocation: React.Dispatch<SetStateAction<boolean>>;
  moveToStep: (step: number) => void;
}

const ThirdStep: FC<IThirdStep> = ({
  sendPost,
  isLoading,
  showMobileNumber,
  showLocation,
  setShowMobileNumber,
  setShowLocation,
  moveToStep,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="third-step">
      <ThirdStepContent
        showMobileNumber={showMobileNumber}
        showLocation={showLocation}
        setShowMobileNumber={setShowMobileNumber}
        setShowLocation={setShowLocation}
      />

      <div className="button-submit_container">
        <div className="move-buttons_container back-to-second">
          <p onClick={() => moveToStep(2)}>❮⠀{t('translation:creation.prev_step.message')}</p>
        </div>

        <button
          className="button-submit-product"
          onClick={() => {
            !isLoading && sendPost();
          }}
          data-cy="creation-third-step-submit-button"
        >
          {isLoading ? (
            <Loader isWhite={true} />
          ) : (
            <p>{t('translation:creation.third_step.place_ad.message')}</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default ThirdStep;
