import React, { SetStateAction, useState } from 'react';
import { Fade, Modal } from '@mui/material';
import { IPost } from '../../../types';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';

import './dealModal.scss';
import { t } from 'i18next';
import { DealsRadioGroup } from './DealsRadioGroup/DealsRadioGroup';
import { DealsSelectBuyer } from './DealsSelectBuyer/DealsSelectBuyer';
import { api } from '../../../services/Api';

interface DealModalProps {
  activeModal: boolean;
  setActiveModal: React.Dispatch<SetStateAction<boolean>>;
  post?: IPost;
}

export const DealModal = ({ activeModal, setActiveModal, post }: DealModalProps) => {
  const [value, setValue] = useState<string | null>(null);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [makeADeal] = api.useLazyPostDealQuery();
  const [deactivatePost] = api.useDeletePostMutation();
  const goToPrevStep = () => {
    if (isNextStep) {
      setIsNextStep(false);
      return;
    }

    handleClose();
  };

  const handleClose = () => {
    setActiveModal(false);
    setValue(null);
    setIsNextStep(false);
    setSelectedChat(null);
  };

  const handleDeactivateButton = () => {
    if (!isNextStep && value === '1') {
      setIsNextStep(true);
      return;
    }

    if (post && value !== null) {
      deactivatePost(post.id)
        .unwrap()
        .then(() => {
          if (selectedChat) {
            makeADeal({
              chat_id: selectedChat,
              post_id: post.id,
            })
              .unwrap()
              .then(() => {
                handleClose();
              })
              .catch(dealError => {
                console.error('Error occurred:', dealError);
              });
          } else {
            handleClose();
          }
        })
        .catch(error => {
          console.error('Error occurred:', error);
        });
    }
  };

  return (
    <Modal
      open={activeModal}
      onClose={handleClose}
      closeAfterTransition
      className="center"
      disableScrollLock={false}
    >
      <Fade in={activeModal}>
        <div className="deal-modal__container">
          <div className="deal-modal__header">
            <h1>{t('translation:deals.title.message')}</h1>

            <div onClick={handleClose} className="close-modal">
              <img alt="close" src={crossSmallForModal} />
            </div>
          </div>

          {!isNextStep && (
            <DealsRadioGroup value={value} setValue={setValue} postTitle={post?.title} />
          )}

          {isNextStep && (
            <DealsSelectBuyer value={selectedChat} setValue={setSelectedChat} post={post} />
          )}

          <div className="deal-modal__buttons-container">
            <div className="deal-button cancel" onClick={goToPrevStep}>
              {t('translation:deals.cancel_btn.message')}
            </div>

            <div className="deal-button next" onClick={handleDeactivateButton} data-cy="button-deactivate-post">
              {value !== '1' || isNextStep
                ? t('translation:deals.deactivate.message')
                : t('translation:deals.next.message')}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
