import Icon from '../../../default_components/Icon/Icon';
import React from 'react';

interface Props {
  label: string;
  count: number;
  onClick: () => void;
  selected?: boolean;
}

export const CategoryChip = ({ label, count, onClick, selected }: Props) => {
  return (
    <div className={`user-posts__category-chip ${selected && 'selected'}`} onClick={onClick}>
      <span>{label}</span> <span>{count}</span>{' '}
      {selected && <Icon type={'crossDefaultRed'} size={'medium-small'} />}
    </div>
  );
};
