import { sliderClasses } from '@mui/material';

export const PriceSliderStyle = {
  color: '#DD2006',
  height: 3,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    color: '#110B11',
    height: '1px',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#DD2006',
    border: 'none',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
    [`&.${sliderClasses.markLabel}`]: {
      transform: 'translateX(-100%) !important',
    },
    [`& .${sliderClasses.valueLabelOpen}`]: {
      right: 'calc(var(--Slider-thumb-size) / 2) !important',
      borderBottomRightRadius: 1,
      transform: 'translate(-35%, 100%) rotate(-0deg) scale(1) !important',

      '&::before': {
        left: 'initial',
        right: 80,
        transform: 'translateY(100%)',
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    background: 'unset',
    padding: '9px, 15px, 9px, 15px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    color: '#110B11',
    fontFamily: 'Noto Sans, sand-serif',
    fontStyle: 'normal',
    fontWeight: '40px',
    fontSize: '14px',
    lineHeight: '22px',
    transformOrigin: 'bottom left',
    transform: 'translate(35%, 100%) rotate(-0deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(35%, 100%) rotate(-0deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(0deg)',
    },
  },
};
