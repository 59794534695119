import React, { Dispatch, FC, SetStateAction } from 'react';
import './catalogDrop.scss';
import { ICategory } from '../../../../types';
import { CatalogCategory } from '.';
import { getPostCountInCategory } from '../../../../utils/get';
import { t } from 'i18next';
import crossSmallForModal from '../../../default_components/assets/icons/CrossSmallForModal.svg';
import { useLocaleFromUrl } from '../../../../utils/localeFromUrl';

interface ICatalogDrop {
  categories: ICategory[];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CatalogDesktop: FC<ICatalogDrop> = ({ categories, setOpen }) => {
  const localeStr = useLocaleFromUrl();

  const postCountInCategories = categories.reduce(
    (acc, category) => acc + getPostCountInCategory(category),
    0,
  );

  const handleClickOnCategory = (category: ICategory): void => {
    setOpen(false);
  };

  return (
    <div className="catalog-navigation">
      <div className="catalog-navigation-drop-wrap">
        <div className="catalog-navigation-drop">
          <div className="catalog-navigation-head">
            <a
              data-count={`${postCountInCategories} ${t('translation:dashboard.items.message')}`}
              href={localeStr ? `${localeStr}/category/all` : `/category/all`}
              style={{ textDecoration: 'none' }}
            >
              {t('translation:dashboard.all_categories.message')}
            </a>
          </div>

          <button
            className="catalog-navigation-close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <img alt="closeIcon" src={crossSmallForModal} />
          </button>

          <div className="catalog-navigation-grid">
            {categories.map(subCategory => (
              <CatalogCategory
                key={subCategory.id}
                category={subCategory}
                onClick={handleClickOnCategory}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogDesktop;
