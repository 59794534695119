import React, { FC, useEffect, useState } from 'react';
import DefaultButton from '../../default_components/button/DefaultButton';
import Authorization from '../../auth/Authorization';
import { localStorageKeys, REDIRECT_URLS } from '../../../routes';
import { useAppSelector } from '../../../hooks/redux';
import { t } from 'i18next';
import { useInitializationLocale } from '../../../hooks/useInitializationLocale';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface IPromoBannerProps {
  promoData: {
    image: string;
    image_small: string;
    name: string;
    type?: string;
  };
}

const PromoBanner: FC<IPromoBannerProps> = ({ promoData }) => {
  const [fontFamily, setfontFamily] = useState<string>('Noto Sans');
  const navigateWithLocale = useNavigateWithLocale();
  const path = window.location.pathname;
  const { isAuthorized } = useAppSelector(state => state.authReducer);

  const [redirectUrl, setRedirectUrl] = useState<string>(REDIRECT_URLS.ALL_CATEGORIES);
  const [openAuthPopup, setOpenAuthPopup] = useState<boolean>(path == '/register');

  useInitializationLocale();

  useEffect(() => {
    const locale: string | 'en' | null = localStorage.getItem(localStorageKeys.locale);
    setfontFamily(locale === 'ka' ? 'BPG LE Studio Caps' : 'Noto Sans');

    const token: string | null = localStorage.getItem(localStorageKeys.token);
  }, []);

  return (
    <div
      className="first-banner__container"
      style={{
        backgroundImage: `url(${
          window.screen.width < 768 ? promoData.image_small : promoData.image
        })`,
      }}
    >
      <div className={`first-banner__content`}>
        <div>
          <h1
            className={`banner-text ${promoData.type == 'custom' && 'mw-800 mt-custom text-16'}`}
            style={{
              fontFamily: `${fontFamily}, sans-serif`,
            }}
          >
            {t(`translation:dashboard.${promoData.name}.title.message`)}
          </h1>

          <DefaultButton
            otherStyle={` banner-button ${promoData.type == 'custom' && 'mw-800'}`}
            styleType="accent-gray-accent-gray-white"
            onClickAction={() => (isAuthorized ? navigateWithLocale(redirectUrl) : setOpenAuthPopup(true))}
            translateKey={t(`translation:dashboard.${promoData.name}.button.message`)}
          />
        </div>
      </div>

      <Authorization
        activePopup={openAuthPopup}
        setActivePopup={setOpenAuthPopup}
        redirectUrl={redirectUrl}
      />
    </div>
  );
};

export default PromoBanner;
