import React, { FC, useState } from 'react';
import { Icon } from '../../../default_components';
import { t } from 'i18next';
import { IOptions } from '../../../../store/reducers/auth.slice';
import { useAppSelector } from '../../../../hooks/redux';
import { useSaveLocaleCurrency } from '../../../../hooks/useSaveLocaleCurrency';

interface ILocaleCurrencyButton {
  isLocale?: boolean;
  isCurrency?: boolean;
  translationKey: string;
  label: string | undefined;
  options: IOptions[];
}

const LocaleCurrencyButton: FC<ILocaleCurrencyButton> = ({
  isLocale,
  isCurrency,
  translationKey,
  label,
  options,
}) => {
  const { activeLocale, activeCurrency } = useAppSelector(state => state.authReducer);
  const [showLocalesMenu, setShowLocalesMenu] = useState<boolean>(false);
  const { saveOption } = useSaveLocaleCurrency();

  const handleOptionClick = (option: IOptions) => {
    saveOption({
      option,
      isLocale,
      isCurrency,
    });
  };

  return (
    <div className={`menu_locale-currency__container ${showLocalesMenu && 'show'}`}>
      <div className="user-button" onClick={() => setShowLocalesMenu(!showLocalesMenu)}>
        <div className="icon-box">
          {isCurrency ? (
            activeCurrency.coinIcon && <Icon type={activeCurrency.coinIcon} />
          ) : (
            <Icon type="whiteGlobe" />
          )}
        </div>

        <p>
          {t(translationKey)}, {label}
        </p>

        <div className={`arrow ${showLocalesMenu && 'show-menu'}`}>
          <Icon type="whiteMiniArrow" />
        </div>
      </div>

      <div className="items-frame">
        <div className={`items-container ${isCurrency && 'currency'} ${showLocalesMenu && 'show'}`}>
          {options.map(option => (
            <div
              key={option.value}
              className={`user-button item 
              ${
                (activeLocale.value == option.value || activeCurrency.value == option.value) &&
                (isCurrency ? 'active' : 'item-active')
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <div className="icon-box">
                {isCurrency ? (
                  option.whiteIcon && <Icon type={option.whiteIcon} />
                ) : (
                  <Icon type={option.value} />
                )}
                <Icon type={option.value} />
              </div>

              <p>{option.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocaleCurrencyButton;
