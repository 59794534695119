import React from 'react';
import { useButtonStyles } from '../../../hooks/useDefaultButtonStyles';
import Loader from '../loader/Loader';
import { t } from 'i18next';
import './buttonGamar.scss';

interface ButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isToggle?: boolean;
  styleType: string;
  sizeType?: string;
  translateKey: string;
  onClickAction: () => void;
  otherStyle?: string;
  children?: React.ReactNode;
}

const DefaultButton: React.FC<ButtonProps> = ({
  isLoading = false,
  isDisabled = false,
  isToggle = false,
  styleType = 'accent-black-gray-accent-gray',
  sizeType = 'default',
  translateKey,
  onClickAction,
  otherStyle = '',
  children,
}) => {
  const styles = useButtonStyles(styleType);

  const classNameList = [
    'default__component-button',
    ...styles.map(({ styleClass }) => styleClass),
    otherStyle,
    isLoading ? 'loading' : '',
    isDisabled ? 'disabled' : '',
    isToggle ? 'toggle' : '',
    sizeType ? 'button__size-style---' + sizeType : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      className={classNameList}
      onClick={!isDisabled && !isLoading ? onClickAction : undefined}
      disabled={isDisabled}
    >
      {children}

      {isLoading ? <Loader isWhite={true} /> : <span>{t(translateKey)}</span>}
    </button>
  );
};

export default DefaultButton;
