import React from 'react';
import { Menu } from '@mui/material';
import { dotsMenuStyle } from './DotsMenuStyle';
import { t } from 'i18next';

interface IDotsMenu {
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

const DotsMenu = ({ anchorEl, handleClose }: IDotsMenu) => {
  return (
    <Menu
      anchorEl={anchorEl}
      sx={dotsMenuStyle}
      id="basic-menu"
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <button className={'user-info__menu-item'}>{t('user_profile.complain.message')}</button>
      <button className={'user-info__menu-item'}>{t('user_profile.block.message')}</button>
    </Menu>
  );
};

export default DotsMenu;
