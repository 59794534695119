import React, { FC, useEffect, useState } from 'react';
import './postActionsPage.scss';
import { PostActionsPage } from '.';
import { api } from '../../services/Api';
import { apiProfile } from '../../services/ApiProfile';
import { ICategory } from '../../types';

const EditRoute: FC<{ postId: string }> = ({ postId }) => {
  const [updatePost, { data, isLoading, isError, error }] = api.useUpdatePostMutation();
  const [getCategory] = api.useLazyGetCategoryQuery();
  const { data: profileInfo } = apiProfile.useGetProfileInfoQuery();
  const { data: post } = api.useFetchPostQuery({ post_id: postId });

  const [postCategory, setPostCategory] = useState<ICategory | undefined>();
  const [postSubCategory, setPostSubCategory] = useState<ICategory | undefined>();
  const isMyPost = profileInfo?.id === post?.user.id;

  useEffect(() => {
    post &&
      getCategory(post.category_id).then(response => {
        if (response.data?.parents.length) {
          setPostSubCategory(response.data);
          getCategory(response.data.parents[0].id).then(response => setPostCategory(response.data));
        } else {
          setPostCategory(response.data);
        }
      });
  }, [post]);

  useEffect(() => {
    if (post && profileInfo && profileInfo.id !== post.user.id) {
      window.location.replace('/my-posts');
    }
  }, [post, profileInfo]);

  return (
    <React.Fragment>
      {post && isMyPost && (
        <PostActionsPage
          post={post}
          postCategory={postCategory}
          postSubCategory={postSubCategory}
          sendPost={updatePost}
          data={data}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      )}
    </React.Fragment>
  );
};

export default EditRoute;
