import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import slider_center_1 from '../../../img/dashboard-img/slider_1_1420.svg';
import slider_small_center_1 from '../../../img/dashboard-img/slider_1_960.svg';
import slider_center_3 from '../../../img/dashboard-img/slider_3_1420.svg';
import slider_small_center_3 from '../../../img/dashboard-img/slider_3_960.svg';
import PromoBanner from './PromoBanner';
import 'swiper/css/pagination';
import './slider.scss';
import 'swiper/css';

const slidersData = [
  {
    image: slider_center_1,
    image_small: slider_small_center_1,
    name: 'promo_1',
  },
  {
    image: slider_center_3,
    image_small: slider_small_center_3,
    name: 'promo_3',
  },
];

const PromoBannersSlider = () => {
  return (
    <div className="banners__swiper" data-cy="promo-banners-slider">
      <Swiper
        slidesPerView={1}
        pagination={{
          dynamicBullets: true,
        }}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        keyboard={true}
        modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {slidersData.map((sliderData, index) => (
          <SwiperSlide key={index}>
            <PromoBanner promoData={sliderData} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PromoBannersSlider;
