import React, { FC } from 'react';
import './postContent.scss';
import PostRootInfo from './postRootInfo/PostRootInfo';
import { Breadcrumbs, Skeletons } from '../../default_components';
import { t } from 'i18next';
import PostFields from './post_fields/PostFields';
import { IPost } from '../../../types';
import PostSwiper from './postSwiper/PostSwiper';
import { renderTextAsParagraphs } from '../../../utils/renderTextAsParagraphs';
import NoticeBlock from '../../default_components/noticeBlock/NoticeBlock';

interface IProductContent {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostContent: FC<IProductContent> = React.memo(({ post, isPostFetching }) => {
  return (
    <div className="post-content__container">
      <div className="post-content">
        {post?.status === 'closed' && (
          <NoticeBlock textElement={<p>{t('translation:product_card.inactive_notice.message')}</p>} />
        )}

        <Breadcrumbs category_id={post?.category_id} isFetching={isPostFetching} />

        <div className="post-root-content_container">
          <PostSwiper post={post} isPostFetching={isPostFetching} />
          <PostRootInfo post={post} isPostFetching={isPostFetching} />
        </div>

        {post && post.fields.length > 0 && !isPostFetching && <PostFields post={post} />}

        <div className="post-description_frame">
          {post && !isPostFetching ? (
            <React.Fragment>
              <h2 className="post-description_title">
                {t('translation:product_page.description.message')}
              </h2>
              <p className="post-description-text">{renderTextAsParagraphs(post?.description)}</p>
            </React.Fragment>
          ) : (
            <Skeletons.SkeletonPostDescription />
          )}
        </div>
      </div>
    </div>
  );
});

export default PostContent;
