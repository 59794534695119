import React from 'react';
import './postCard.scss';
import { Skeleton } from '@mui/material';

const SkeletonProductCard = () => {
  return (
    <div className="post-card_frame">
      <Skeleton variant="rectangular" className="post-card-image_container" />

      <div className="post-card-content_container">
        <div className="post-card-price-location_container">
          <Skeleton variant="text" className="post-card-price" />
          <Skeleton variant="text" className="post-card-state" />
        </div>

        <Skeleton variant="text" className="post-card-title" />

        <div className="post-card-user-info_container">
          <Skeleton variant="circular" className="post-card-user-avatar" />

          <Skeleton variant="text" className="post-card-username" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonProductCard;
