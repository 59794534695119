import { DateTime } from 'luxon';
import regex from 'url-regex';

export const formattedDate = (date: string) => {
  return `${DateTime.fromISO(date).hour}:${
    DateTime.fromISO(date).minute < 10
      ? `0${DateTime.fromISO(date).minute}`
      : `${DateTime.fromISO(date).minute}`
  }`;
};

export const parseUrl = (text: string) => {
  return text.replace(
    /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
    '<a target="_blank" class="colored" href="$2">$1</a>',
  );
};

export const defineEmbeddedLink = (text: string) => {
  return !!text.match(/\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g);
};

export const getShortMessage = (letters: number, text: string | undefined) => {
  if (text && text.length > letters) {
    return `${text?.slice(0, letters)}...`;
  } else {
    return text;
  }
};
