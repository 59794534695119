import React, { FC, useEffect, useState } from 'react';
import { RatingDigits, RatingStars, ReviewsLink } from '../default_components/reviews';
import { to_rating_digit } from '../../utils/convert';
import { ReviewsType, ReviewType } from '../../types/reviewsTypes';
import './reviews.scss';
import { api } from '../../services/Api';
import { Skeletons } from '../default_components';

const Reviews: FC<ReviewsType> = ({ user, open, setOpen }) => {
  const [rating, setRating] = useState<number>(0);
  const [reviews, setReviews] = useState<ReviewType[] | undefined>([]);

  const { data, isLoading } = api.useGetReviewsBySellerQuery(user?.id ?? '');

  useEffect(() => {
    if (data) {
      setRating(to_rating_digit(data));
      setReviews(data);
    }
  }, [data]);

  return user && isLoading ? (
    <div className="reviews__frame">
      <div className="reviews__rating">
        <Skeletons.SkeletonRatingStars />
        <Skeletons.SkeletonRatingDigits />
        <Skeletons.SkeletonReviewLink />
      </div>
    </div>
  ) : (
    <div className="reviews__frame">
      <div className="reviews__rating">
        <RatingStars rating={rating} starsSize="medium-large" />
        <RatingDigits rating={rating} />
        <ReviewsLink open={open} setOpen={setOpen} reviews={reviews} />
      </div>
    </div>
  );
};

export default Reviews;
