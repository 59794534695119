import { useLocation, useNavigate } from 'react-router-dom';
import { setQueryParams } from '../utils/queryUtils';
import useNavigateWithLocale from './useNavigateWithLocale';

const useUpdateQueryParams = () => {
  const location = useLocation();
  const navigateWithLocale = useNavigateWithLocale();

  const updateQueryParams = (params: Record<string, any>) => {
    const newQueryParams = setQueryParams(params);

    const pathnameWithoutLocale = location.pathname.replace(/^\/[a-z]{2}(\/|$)/, '/');

    navigateWithLocale(`${pathnameWithoutLocale}${newQueryParams ? `?${newQueryParams}` : ''}`);
  };

  return { updateQueryParams };
};

export default useUpdateQueryParams;
