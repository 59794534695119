import React from 'react';
import './footer.scss';
import { api } from '../../services/Api';
import { Link, useNavigate } from 'react-router-dom';
import { t, TFunction } from 'i18next';
import { ICategory } from '../../types';
import useNavigateWithLocale from '../../hooks/useNavigateWithLocale';
import { useLocaleFromUrl } from '../../utils/localeFromUrl';

const leftSectionContent = (t: TFunction<'translation', undefined, 'translation'>) => [
  { link: '/about-us', text: t('translation:footer.about.message') },
  { link: '/support', text: t('translation:footer.support.message') },
  { link: '/terms-and-conditions', text: t('translation:footer.terms_and_conditions.message') },
  { link: '/site-policy', text: t('translation:footer.privacy_policy.message') },
  { link: '/posting-rules', text: t('translation:footer.posting_rules.message') },
  { link: 'https://blog.gamarmarket.ge', text: t('translation:footer.blog.message') },
];

//todo translate remaining text
const rightSectionContent = (t: TFunction<'translation', undefined, 'translation'>) => [
  { link: '/all', text: t('translation:dashboard.all_items.message') },
  { link: '/all?page=1&new=true', text: t('translation:dashboard.new_items.message') },
  { link: '/all?page=1&used=true', text: t('translation:dashboard.pre_owned_items.message') },
  {
    link: '/all?page=1&handmade=true',
    text: t('translation:dashboard.seasonal_items.message'),
  },
];

const FooterContent = () => {
  const localeStr = useLocaleFromUrl();
  const navigateWithLocale = useNavigateWithLocale();
  const { data: categories, isSuccess } = api.useGetCategoriesQuery();

  const preferredOrder = [
    '8c2fcfa1-e160-4a3b-88dd-87476ca50b89', // Электроника
    'd5fa51dc-80c2-4388-b7c8-fab64642bc56', // Бизнес и Промышленность
    '0c4abbb4-85ee-4846-b874-49b7bc37b324', // Спорт и Отдых
    '85025ab9-60da-4c34-832c-61fae07f00ae', // Одежда и Аксессуары
    'b2314591-49c5-4633-a896-56adb5c0176c', // Дом и Сад
    '0eabaa5c-766b-419b-9e7f-80320b6d82bf', // Игрушки
    '58fce5ae-48b4-43d2-b347-49596cceee0d', // Красота и уход
    'a71d5a29-9ef5-48cc-8da9-f678d031d17b', // Услуги
  ];

  const sortCategories = (categories: ICategory[]) => {
    const orderMap = preferredOrder.reduce((acc, uuid, index) => {
      acc[uuid] = index;
      return acc;
    }, {} as Record<string, number>);

    return [...categories].sort((a, b) => {
      return (orderMap[a.id] ?? Infinity) - (orderMap[b.id] ?? Infinity);
    });
  };

  const linkClick = (link: string) => {
    smoothScrollToTop();
  };

  function smoothScrollToTop() {
    const currentY = window.scrollY;
    if (currentY > 50) {
      const step = Math.max(1, Math.floor(currentY / 20)); // Adjust the step value as needed for the desired speed
      window.scrollTo(0, currentY - step);
      requestAnimationFrame(smoothScrollToTop);
    }
  }

  return (
    <div className="footer__content-container">
      <div className="footer__section">
        {isSuccess &&
          sortCategories(categories)
            .slice(0, 8)
            .map((category, index) => (
              <Link
                key={index}
                to={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
              >
                {category.name}
              </Link>
            ))}
      </div>

      <div className="footer__section">
        {rightSectionContent(t).map((line, index) => (
          <a key={index} onClick={() => navigateWithLocale(`/category${line.link}`)}>
            {line.text}
          </a>
        ))}
      </div>

      <div className="footer__section">
        {leftSectionContent(t).map((line, index) => (
          <Link
            key={index}
            onClick={() => linkClick(line.link)}
            to={localeStr ? `${localeStr}${line.link}` : line.link}
          >
            {line.text}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FooterContent;
