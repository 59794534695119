import React from 'react';
import { useParams } from "react-router-dom";
import { CreationRoute, EditRoute } from '.';

const IndexRoute = () => {
  const postId = useParams().itemId;

  if (postId) {
    return <EditRoute postId={postId} />
  } else {
    return <CreationRoute />
  }
};

export default IndexRoute;
