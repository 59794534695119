import React, { FC } from 'react';
import { Icon } from "../../default_components";

interface ICircleComponent {
  name: string,
  active: boolean,
  complete: boolean,
  count: number,
  onClick: () => void
}

const CircleComponent: FC<ICircleComponent> = ({ name, active, complete, count, onClick }) => {
  const circleBoxStyle =  complete ? "content_steps-container complete" : active ? "content_steps-container active" : "content_steps-container";
  const circleStyle = complete ?  "circle_steps-container complete" : active ? "circle_steps-container active" : "circle_steps-container";

  return (
    <div className={ circleBoxStyle }>
      <div onClick={ onClick } className={ circleStyle }>
        { complete ? <Icon type="tick" /> : count }
      </div>

      <p>{ name }</p>
    </div>
  );
};

export default CircleComponent;
