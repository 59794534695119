import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { IChatRoom, IChatUser, IMessage } from "../types";
import basicAuthHeaders, { ApiUrl } from "./index";

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({ baseUrl: ApiUrl }),
  endpoints: build => ({
    getChatUsers: build.query<IChatUser, any>({
      query: ({user_id, token}) => ({
        url: `/users/${user_id}`,
        headers: {
          'Authorization': token,
        },
      }),
    }),

    createChat: build.mutation<IChatRoom, string>({
      query: (id) => ({
        url: '/chats',
        method: 'POST',
        headers: basicAuthHeaders(),
        body: {
          'post_id': id
        }
      })
    }),

    getOneChat: build.query<IChatRoom, any>({
      query: ({id, token}) => ({
        url:  `/chats/${id}`,
        headers: {
          'Authorization': token
        },
      }),
    }),

    getAllChats: build.query<IChatRoom[], void>({
      query: () => ({
        url: '/chats',
        headers: basicAuthHeaders(),
      }),
    }),

    sendMessage: build.mutation<IMessage, { body: FormData }>({
      query: data => ({
        url: '/messages',
        method: 'POST',
        headers: basicAuthHeaders(),
        body: data.body,
      }),
    }),

    replyMessage: build.mutation<any, { body: FormData }>({
      query: data => ({
        url: `messages`,
        method: 'POST',
        headers: basicAuthHeaders(),
        body: data.body,
      })
    }),

    editMessage: build.mutation<IMessage, { body: FormData, id: string }>({
      query: ({ body, id }) => ({
        url: `messages/${id}`,
        method: 'PUT',
        headers: basicAuthHeaders(),
        body: body
      }),
    }),

    deleteMessage: build.mutation<IMessage, string>({
      query: id => ({
        url: `messages/${id}`,
        method: 'DELETE',
        headers: basicAuthHeaders(),
      })
    }),

    seenMessages: build.mutation<{ status: string }, string>({
      query: (id) => ({
        url: `/chats/${id}/seen`,
        method: 'POST',
        headers: basicAuthHeaders(),
      })
    })
  }),
})
