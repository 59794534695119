import React, { useEffect, useState } from 'react';
import './localeAndCurrency.scss';
import Icon from '../../default_components/Icon/Icon';
import LocaleCurrencySelector from './localeCurrencySelector/LocaleCurrencySelector';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import { t } from 'i18next';
import { Fade } from '@mui/material';
import { useAppSelector } from '../../../hooks/redux';
import { CURRENCY_OPTIONS, LOCALE_OPTIONS } from '../../../store/reducers/auth.slice';

const LocaleAndCurrency = () => {
  const { activeLocale, activeCurrency } = useAppSelector(state => state.authReducer);
  const [isActivePopup, setIsActivePopup] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 760) {
        setIsActivePopup(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClose = () => {
    setIsActivePopup(false);
  };

  return (
    <React.Fragment>
      <div onClick={() => setIsActivePopup(true)} className="locale-currency__container">
        <div className="locale-currency__icon">
          <Icon type="globeIcon" />
        </div>

        <p className="locale-currency__settings">
          <span>{activeLocale?.label}</span>,&nbsp;
          <span>{activeCurrency?.label}</span>
        </p>
      </div>

      <div onClick={handleClose} className={`modal_frame ${isActivePopup && 'show'}`}>
        <Fade in={isActivePopup} onClick={e => e.stopPropagation()}>
          <div className="locale-currency__container--modal">
            <div>
              <div className="locale-currency__header pb-50">
                <div className="locale-currency__title">
                  {t('translation:language_modal.title_1.message')} <br />
                  {t('translation:language_modal.title_2.message')}
                </div>

                <div className="locale-currency__icon__close">
                  <img alt="close" src={crossSmallForModal} onClick={handleClose} />
                </div>
              </div>

              <div className="locale-currency__body">
                <LocaleCurrencySelector
                  isLocale
                  isActivePopup={isActivePopup}
                  options={LOCALE_OPTIONS}
                  translationKey={
                    'default_components.language_and_currency_selector.language_selector_title.message'
                  }
                />

                <LocaleCurrencySelector
                  isCurrency
                  isActivePopup={isActivePopup}
                  options={CURRENCY_OPTIONS}
                  translationKey={
                    'default_components.language_and_currency_selector.currency_selector_title.message'
                  }
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default LocaleAndCurrency;
