interface ICategoryFieldFilter {
  id: string;
  values: string[];
}

interface IFilterOption {
  [key: string]: string;
}

interface IPostsStates {
  [key: string]: boolean;
}

interface IPriceRange {
  min_price: number | null;
  max_price: number | null;
}

const FilterSortByVariants = {
  POPULAR: 'popular',
  NEWEST: 'newest',
  LOW_PRICE: 'low_price',
  HIGH_PRICE: 'high_price',
} as const;

type FilterSortByValueType = (typeof FilterSortByVariants)[keyof typeof FilterSortByVariants];

export {
  ICategoryFieldFilter,
  IFilterOption,
  IPostsStates,
  IPriceRange,
  FilterSortByVariants,
  FilterSortByValueType,
};
