import React, { FC, SetStateAction, useEffect } from 'react';
import { getShortMessage } from '../chatUtils';
import { useSelector } from 'react-redux';
import { useActions } from '../../../hooks/redux';
import editImg from '../../../img/chat-img/edit.svg';
import replyImg from '../../../img/chat-img/reply.svg';
import { IChatUser, IUser } from '../../../types';
import '../Chat.scss';
import { chatApi } from '../../../services/chatApi';
import { Modal } from '@mui/material';
import { t } from 'i18next';
import Cross from '../../default_components/assets/icons/Cross.svg';

interface IReplyBar {
  profileInfo: IUser;
  interlocutor?: IChatUser;
  setIsReplyBarActive: React.Dispatch<SetStateAction<boolean>>;
}

const ReplyBar: FC<IReplyBar> = ({ profileInfo, interlocutor, setIsReplyBarActive }) => {
  const repliedMessage = useSelector((state: any) => state.chatReducer.replyMessage);
  const editedMessage = useSelector((state: any) => state.chatReducer.editMessage);
  const deletedMessage = useSelector((state: any) => state.chatReducer.deleteMessage);
  const [deleteMessageRequest] = chatApi.useDeleteMessageMutation();
  const { resetContextMenuState, inputAction } = useActions();

  useEffect(() => {
    setIsReplyBarActive(repliedMessage || editedMessage);
  }, [repliedMessage, editedMessage]);

  const deleteMessage = () => {
    deleteMessageRequest(deletedMessage.id);
    onClose();
  };

  const onClose = () => {
    resetContextMenuState();
    inputAction('');
  };

  return (
    <div className="reply-box__container">
      {repliedMessage && (
        <div className="chat__reply-box">
          <img className="image" src={replyImg} alt="replyImg" />

          <div className="text-box">
            <p className="username">
              {repliedMessage.user_id === profileInfo.id
                ? `${t('translation:chat.replying_to_yourself.message')}`
                : `${t('translation:chat.replied_to.message')} ${interlocutor?.username}`}
            </p>
            <p className="text">{getShortMessage(100, repliedMessage.text)}</p>
          </div>

          <img onClick={onClose} src={Cross} />
        </div>
      )}

      {editedMessage && (
        <div className="chat__reply-box">
          <img className="image" src={editImg} alt="replyImg" />

          <div className="text-box">
            <p className="text">{getShortMessage(100, editedMessage.text)}</p>
          </div>

          <img onClick={onClose} src={Cross} />
        </div>
      )}

      <Modal open={deletedMessage} onClose={onClose} className="center">
        <div className="delete-modal-content" onClick={(e: any) => e.stopPropagation()}>
          <p>{t('translation:chat.delete_confirm.message')}</p>

          <div className="delete-message-menu_container">
            <button onClick={deleteMessage} className="delete-message-menu_button delete">
              {t('translation:chat.delete.message')}
            </button>

            <button onClick={onClose} className="delete-message-menu_button cancel">
              {t('translation:chat.cancel.message')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReplyBar;
