import { DateTime as dt } from 'luxon';

const getShortProductName = (letters: number, title: string) => {
  if (title?.length > letters) {
    return `${title.slice(0, letters)}...`;
  } else {
    return title;
  }
};

const isoDateToHumanDate = (date: string) => {
  return dt.fromISO(date).toFormat('dd LLL yyyy');
};

export { getShortProductName, isoDateToHumanDate };
