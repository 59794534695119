import React, { FC } from 'react';
import { Radio, RadioProps } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";

interface IRadioBoxProps extends RadioProps{
  value: string,
  label: string,
  new_color?: string,
  size?: "small" | "medium" | undefined,
}

const RadioBox: FC<IRadioBoxProps> = ({ value, label, new_color, size }) => {
  return (
    <FormControlLabel
      value={ value }
      label={ label }
      control={
        <Radio
          size={ size }
          sx={{
            color: "#110B11",
            "&:hover": {
              background: "transparent !important"
            },
            '&.Mui-checked': {
              color: new_color
            }}}
        />
      }
    />
  );
};

export default RadioBox;
