import { ICategory } from "../../types";

const getBreadcrumbsPath = (categories: ICategory[], categoryId: string): ICategory[] => {
  const currentCategory: ICategory | undefined = categories.find(category => category.id === categoryId);

  if (currentCategory) {
    return [...currentCategory.parents, currentCategory]
  }

  return []
}

export { getBreadcrumbsPath };
