import React, { FC } from 'react';
import './postRootInfo.scss';
import { IPost } from '../../../../types';
import { PostTitle, PostUsedPrice, PostLocation, PostOwnerInfo } from '.';

interface IPostRootContent {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostRootInfo: FC<IPostRootContent> = ({ post, isPostFetching }) => {
  return (
    <div className="root-post-info_frame">
      <div className="root-post-info_container">
        <PostTitle post={post} isPostFetching={isPostFetching} />

        <PostUsedPrice post={post} isPostFetching={isPostFetching} />

        <PostLocation post={post} isPostFetching={isPostFetching} />

        <PostOwnerInfo post={post} isPostFetching={isPostFetching} />
      </div>
    </div>
  );
};

export default PostRootInfo;
