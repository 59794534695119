import React, { FC } from 'react';
import { Icon } from "../../../default_components";

interface IChip {
  text: string;
  isShow: boolean | undefined;
  disableOption: any;
  priceIcon?: React.JSX.Element;
}

const Chip: FC<IChip> = ({ text, isShow, disableOption, priceIcon }) => {
  return (
    <div className={`chip_frame ${!isShow && "hide"}`}>
      <div className="chip">
        {priceIcon}
        <div className="chip-text">{text}</div>
        <Icon
          onClick={disableOption}
          type={'crossDefault'}
        />
      </div>
    </div>
  );
};

export default Chip;
