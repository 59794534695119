import React, { FC } from 'react';
import "./burgerMenuButton.scss";

interface IBurgerMenuButton {
  open?: boolean;
}

const BurgerMenuButton: FC<IBurgerMenuButton> = ({ open }) => {
  return (
    <div className={`burger-button ${open ? "active" : "not-active"}`}>
      <span className="span-line"></span>
      <span className="span-line"></span>
      <span className="span-line"></span>
    </div>
  );
};

export default BurgerMenuButton;
