import React, { FC } from 'react';
import { RatingLinkType } from '../../../../types/reviewsTypes';
import './reviewsLink.scss';
import { t } from 'i18next';

const ReviewsLink: FC<RatingLinkType> = ({ setOpen, reviews, open }) => {
  const hasReviews = (reviews?.length ?? 0) > 0;

  return (
    <div
      className={`reviews__link ${hasReviews && 'active'}`}
      onClick={() => hasReviews && setOpen(!open)}
    >
      <p>
        {reviews?.length}
        {t(`translation:view_reviews.reviews_link.${reviews?.length === 1 ? 1 : 0}`)}
      </p>
    </div>
  );
};

export default ReviewsLink;
