import React, { useEffect } from 'react';
import './dashboard.scss';
import Header from '../header/Header';
import DashboardContent from './DashboardContent';
import Footer from '../footer/Footer';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta property="og:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta property="og:image" content="https://gamar-api.s3.eu-central-1.amazonaws.com/OG_LOGO.png"/>
        <meta property="og:url" content="https://gamarmarket.ge"/>

        <meta name="twitter:title" content="Gamarmarket.ge - უნივერსალური ონლაინ სავაჭრო სივრცე"/>
        <meta name="twitter:description" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია. მიუხედავად იმისა, ეძებთ ტანსაცმელს, ავეჯს, ელექტრონიკას."/>
        <meta name="twitter:url" content="https://gamarmarket.ge"/>
        <meta name="twitter:card" content="გაყიდეთ, შეიძინეთ ან გადაცვალეთ ნებისმიერი პროდუქცია."/>
      </Helmet>

      <Header />
      <DashboardContent />
      <Footer />
    </React.Fragment>
  );
};

export default Dashboard;
