import React, { FC, useRef, useState } from 'react';
import './postSwiper.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import { IPost } from '../../../../types';
import swiper, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Icon, LikeComponent } from '../../../default_components';
import { PostImageSlide, PostZoomSwiper, ThumbsSwiper } from '.';
import Skeleton from '@mui/material/Skeleton';
import { t } from 'i18next';

interface IPostSwiper {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const SkeletonGalleryStyle = {
  width: '100%',
  height: '100%',
};

const PostSwiper: FC<IPostSwiper> = React.memo(({ post, isPostFetching }) => {
  const swiperRef = useRef<SwiperRef>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [thumbsSwiper, setThumbsSwiper] = useState<swiper | null | undefined>(null);
  const [isShowZoomSwiper, setIsShowZoomSwiper] = useState<boolean>(false);

  const onSlideChange = () => {
    if (swiperRef.current) {
      setCurrentSlide(swiperRef.current.swiper.realIndex + 1);
    }
  };

  const goToNext = () => {
    if (swiperRef.current && !swiperRef.current.swiper.isEnd) {
      swiperRef.current.swiper.slideNext();
      setCurrentSlide(prev => prev + 1);
    }
  };

  const goToPrev = () => {
    if (swiperRef.current && !swiperRef.current.swiper.isBeginning) {
      swiperRef.current.swiper.slidePrev();
      setCurrentSlide(prev => prev - 1);
    }
  };

  if (post && !isPostFetching) {
    return (
      <React.Fragment>
        <div className="post-gallery__frame">
          <div className="post-gallery__wrapper">
            <div className="post-container">
              <Swiper
                slidesPerView={'auto'}
                centeredSlides
                spaceBetween={20}
                onSnapIndexChange={index => setCurrentSlide(index.activeIndex + 1)}
                navigation={{
                  enabled: true,
                  nextEl: '.button-slide-next',
                  prevEl: '.button-slide-prev',
                }}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[Navigation, Thumbs]}
                onSlideChange={onSlideChange}
                className="mySwiper"
              >
                {[...post.images_variants].reverse().map((image, id) => (
                  <SwiperSlide key={id} onClick={() => setIsShowZoomSwiper(true)}>
                    <PostImageSlide id={id} image={image} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <button className="button-slide-prev" type="button" onClick={goToPrev}>
              <Icon type="arrowVertical" />
            </button>

            <button className="button-slide-next" type="button" onClick={goToNext}>
              <Icon type="arrowVertical" />
            </button>

            <div className="post-gallery_counter">{`${currentSlide}/${post.images.length}`}</div>

            <LikeComponent post={post} viewLikeAlways />

            {post.handmade && (
              <div className="post-gallery__descriptor descriptor">
                {t('translation:product_card.handmade.message')}
              </div>
            )}
          </div>

          <ThumbsSwiper post={post} setThumbsSwiper={setThumbsSwiper} />
        </div>

        <PostZoomSwiper
          post={post}
          isShow={isShowZoomSwiper}
          setIsShow={setIsShowZoomSwiper}
          currentSlideNumber={currentSlide}
        />
      </React.Fragment>
    );
  }

  return (
    <div className="skeleton-gallery__container">
      <Skeleton sx={SkeletonGalleryStyle} animation="wave" variant="rounded" />
    </div>
  );
});

export default PostSwiper;
