import { DateTime as dt } from 'luxon';
import { IPost } from '../types';
import { ReviewType } from '../types/reviewsTypes';

const by_date = (array: IPost[], reverse: boolean) => {
  return array.sort((a: any, b: any) => {
    if (!reverse) {
      return dt.fromISO(b.created_at).toMillis() - dt.fromISO(a.created_at).toMillis();
    } else {
      return dt.fromISO(a.created_at).toMillis() - dt.fromISO(b.created_at).toMillis();
    }
  });
};

const by_price = (array: IPost[], reverse: boolean) => {
  return array.sort((a: any, b: any) => {
    if (!reverse) {
      return a.prices.gel - b.prices.gel;
    } else {
      return b.prices.gel - a.prices.gel;
    }
  });
};

const by_title = (array: IPost[]) => {
  return array.sort((a: any, b: any) => (a.title > b.title ? 1 : -1));
};

const sortModule = (posts: IPost[], sort: string): any[] => {
  if (sort === 'new_first') {
    return by_date([...posts], false);
  } else if (sort === 'old_first') {
    return by_date([...posts], true);
  } else if (sort === 'price_low_first') {
    return by_price([...posts], false);
  } else if (sort === 'price_high_first') {
    return by_price([...posts], true);
  } else if (sort === 'alphabetical') {
    return by_title([...posts]);
  } else {
    return [];
  }
};

export { by_date, by_price, by_title, sortModule };
