interface ButtonStyleConfig {
  [key: string]: ButtonStyleState[];
}

interface ButtonStyleState {
  state: 'default' | 'hover' | 'disabled' | 'loading' | 'toggle';
  styleClass: string;
}

/**
 * **Hook: `useButtonStyles`**
 *
 * This hook returns an array of style classes based on the provided `styleType`. 
 * t is primarily used to fetch dynamic style configurations for the `DefaultButton` component.
 *
 * The configuration includes five different button states: 'default', 'hover', 'disabled', 'loading', and 'toggle'. 
 * Each state is associated with a specific style class.
 *
 * The `styleType` parameter is a string that specifies the style configuration to use. For example, 
 * a `styleType` of 'accent-black-gray-accent-gray' would return the following style classes:
 *
 * - 'default' state: 'static-accent'
 * - 'hover' state: 'hover-black'
 * - 'disabled' state: 'disabled-gray'
 * - 'loading' state: 'loader-accent'
 * - 'toggle' state: 'toggle-gray'
 *
 * If the `styleType` does not match any existing configuration, an empty array is returned.
 *
 * Additional style configurations can be added to the `buttonStyleConfig` object as needed.
 *
 * @param {string} type - The `styleType` indicating which style configuration to use.
 * @returns An array of style classes corresponding to the `styleType`.
 */

const buttonStyleConfig: ButtonStyleConfig = {
  'accent-black-gray-accent-gray': [
    { state: 'default', styleClass: 'static-accent' },
    { state: 'hover', styleClass: 'hover-black' },
    { state: 'disabled', styleClass: 'disabled-gray' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-gray' },
  ],
  'accent-gray-accent-gray-white': [
    { state: 'default', styleClass: 'static-accent' },
    { state: 'hover', styleClass: 'hover-black' },
    { state: 'disabled', styleClass: 'disabled-gray' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-white' },
  ],
  'accent-accent-accent-accent-gray': [
    { state: 'default', styleClass: 'static-accent' },
    { state: 'hover', styleClass: 'hover-accent' },
    { state: 'disabled', styleClass: 'disabled-accent' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-gray' },
  ],
  'gray-gray_outline-gray-gray-gray': [
    { state: 'default', styleClass: 'static-gray' },
    { state: 'hover', styleClass: 'hover-gray-outline' },
    { state: 'disabled', styleClass: 'disabled-gray' },
    { state: 'loading', styleClass: 'loader-gray' },
    { state: 'toggle', styleClass: 'toggle-gray' },
  ],
  'accent-outline-gray-accent-gray': [
    { state: 'default', styleClass: 'static-accent' },
    { state: 'hover', styleClass: 'hover-outline' },
    { state: 'disabled', styleClass: 'disabled-gray' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-gray' },
  ],
  'outline-accent-accent-accent-accent': [
    { state: 'default', styleClass: 'static-outline' },
    { state: 'hover', styleClass: 'hover-accent' },
    { state: 'disabled', styleClass: 'disabled-accent' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-accent' },
  ],
  'gray-gray-outline-accent-accent-accent': [
    { state: 'default', styleClass: 'static-gray' },
    { state: 'hover', styleClass: 'hover-gray-outline' },
    { state: 'disabled', styleClass: 'disabled-gray' },
    { state: 'loading', styleClass: 'loader-accent' },
    { state: 'toggle', styleClass: 'toggle-accent' },
  ],
};

export const useButtonStyles = (type: string) => {
  return buttonStyleConfig[type] || [];
};
