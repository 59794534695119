import React, { FC } from 'react';
import './ratingDigits.scss';
import { Skeletons } from '../../../default_components/';

const RatingDigits: FC<{ rating?: number }> = ({ rating }) => {
  return (
    <div className="rating__digits">
      <p>{rating?.toFixed(1)}</p>
    </div>
  );
};

export default RatingDigits;
