import React, { FC, useEffect, useRef, useState } from 'react';
import '../Chat.scss';
import editImg from '../../../img/chat-img/edit.svg';
import replyImg from '../../../img/chat-img/reply.svg';
import deleteImg from '../../../img/chat-img/delete.svg';
import ContextMenu from '../context_menu/ContextMenu';
import { useActions, useAppSelector } from '../../../hooks/redux';
import { formattedDate, getShortMessage, parseUrl } from '../chatUtils';
import { t } from 'i18next';
import Skeleton from '@mui/material/Skeleton';
import { IChatUser, IMessage } from '../../../types';
import { PostZoomSwiper } from '../../post/postContent/postSwiper';
import { useGetProfileInfo } from '../../../hooks/useGetProfileInfo';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface IMyMessageBox {
  message: IMessage;
  userId: string;
  replied_message?: IMessage;
  interlocutor?: IChatUser;
  deleted?: boolean;
  hideAvatar: boolean;
}

const MyMessageBox: FC<IMyMessageBox> = ({
  message,
  userId,
  replied_message,
  interlocutor,
  hideAvatar,
}) => {
  const navigateWithLocale = useNavigateWithLocale();
  const { profileInfo } = useAppSelector(state => state.authReducer);
  const { replyToMessage, editAMessage, deleteAMessage, resetContextMenuState } = useActions();
  const divRef: any = useRef(0);
  const { profileInfo: myProfile } = useGetProfileInfo();

  const [anchorEl, setAnchorEl] = useState(null);
  const [loadedAvatar, setLoadedAvatar] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const [isZoomSwiperOpened, setIsZoomSwiperOpened] = useState<boolean>(false);

  const handleClick = (event: any) => {
    if (message && message.deleted_at) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, []);

  useEffect(() => {
    if (profileInfo && !profileInfo.avatar) {
      setLoadedAvatar(true);
    }
  }, [profileInfo]);

  const replyMessage = (message: any) => {
    resetContextMenuState();
    replyToMessage(message);
  };

  const editMessage = (message: any) => {
    resetContextMenuState();
    editAMessage(message);
  };

  const deleteMessage = (message: any) => {
    resetContextMenuState();
    deleteAMessage(message);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {replied_message && (
        <div className="reply-on-message-box">
          <p className="username">
            {replied_message.user_id === userId
              ? t('translation:chat.replying_to_yourself.message')
              : `${t('translation:chat.replied_to.message')} ${getShortMessage(
                  10,
                  interlocutor?.username,
                )}`}
          </p>
          <p className="text">{getShortMessage(15, replied_message?.text)}</p>
        </div>
      )}

      <div ref={divRef} className="chat__chat-room-message__from-me">
        {!message.seen && <div className="unread-message-indicator" />}

        <div
          onContextMenu={e => {
            handleClick(e);
            e.preventDefault();
          }}
          onClick={handleClick}
          id="text"
          className={`chat__chat-room-message__from-me__text ${
            message.deleted_at && 'is-deleted'
          } ${hideAvatar && 'hide-arrow'}`}
        >
          <div className="message-image-container">
            {message.images_urls?.length > 0 &&
              message.images_urls.map(image_url => (
                <img
                  onClick={e => {
                    e.stopPropagation();
                    setIsZoomSwiperOpened(true);
                  }}
                  src={image_url}
                  className="message-image"
                  alt={image_url}
                />
              ))}
          </div>

          {message.phone_call && !message.text && !message.deleted_at ? (
            <i>{t('translation:chat.phone_call.message')}</i>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: parseUrl(message.text) }} />
          )}

          <div className="chat__chat-room-message__from-me__time">
            {message.deleted_at && t('translation:chat.message_deleted.message')}
            {!message.deleted_at && message.updated && t('translation:chat.edited.message')}
            {` ${formattedDate(message.created_at)}`}
          </div>
        </div>

        <div className="chat__user-avatar__container chat-avatar">
          {!hideAvatar && (
            <React.Fragment>
              {!loadedAvatar && (
                <Skeleton
                  sx={{ width: '60px', height: '60px' }}
                  animation="wave"
                  variant="rectangular"
                />
              )}

              {profileInfo?.avatar ? (
                <img
                  src={profileInfo?.avatar}
                  alt="avatar"
                  className="chat__chat-room-message__from-me-image"
                  onLoad={() => setLoadedAvatar(true)}
                  onClick={() => navigateWithLocale(`/user/${myProfile?.id}`)}
                />
              ) : (
                <div
                  className="chat__user-avatar"
                  onClick={() => navigateWithLocale(`/user/${myProfile?.id}`)}
                >
                  <h1>{profileInfo?.username?.[0]?.toUpperCase()}</h1>
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        <ContextMenu openMenu={open} anchorEl={anchorEl} handleClose={handleClose}>
          {!message.phone_call ? (
            <button
              onClick={() => {
                replyMessage(message);
                handleClose();
              }}
              className="chat__edit-button"
            >
              <img src={replyImg} alt="replyImg" />
              {t('translation:chat.reply.message')}
            </button>
          ) : (
            <React.Fragment />
          )}

          {!message.phone_call ? (
            <button
              onClick={() => {
                editMessage(message);
                handleClose();
              }}
              className="chat__edit-button"
            >
              <img src={editImg} alt="editImg" />
              {t('translation:chat.edit.message')}
            </button>
          ) : (
            <React.Fragment />
          )}

          <button
            onClick={() => {
              deleteMessage(message);
              handleClose();
            }}
            className="chat__edit-button"
          >
            <img src={deleteImg} alt="deleteImg" />
            {t('translation:chat.delete.message')}
          </button>
        </ContextMenu>

        <PostZoomSwiper
          images={message.images_urls}
          isShow={isZoomSwiperOpened}
          setIsShow={setIsZoomSwiperOpened}
        />
      </div>
    </div>
  );
};

export default MyMessageBox;
