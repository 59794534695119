export const dotsMenuStyle = {
  width: '90%',
  position: 'none',
  maxWidth: '340px',

  '.MuiPaper-root': {
    // boxShadow: 'none !important',
    borderRadius: '10px',
  },

  '.MuiList-root': {
    height: '100%',
    maxWidth: '200px',

    padding: '10px',
    position: 'none',
    border: 'transparent !important',
    fontFamily: 'Noto Sans, sans serif !important',
    fontSize: '14px !important',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    gap: '5px',

    color: '#110B11 !important',
    opacity: '1 !important',
    background: '#f0f0f0',
    transition: 'all .2s ease-in-out',
  },
};
