import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Icon } from '../index';
import './autocompleteSelect.scss';
import { t } from 'i18next';

interface IDefaultSelect {
  selectedItem: any | undefined;
  setSelectedItem: Dispatch<SetStateAction<any | undefined>>;
  assets: any[];
  title: string;
  placeholder: string;
  listenerForReset?: unknown;
  dataCy?: string;
}

const AutocompleteSelect: FC<IDefaultSelect> = ({
  selectedItem,
  setSelectedItem,
  assets: gatedItems,
  title,
  placeholder,
  listenerForReset,
  dataCy,
}) => {
  const defaultSelectedItem = selectedItem?.name || selectedItem;

  const [assets, setAssets] = useState<any[]>(gatedItems);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue('');
    setAssets(gatedItems);
  }, [gatedItems]);

  useEffect(() => {
    defaultSelectedItem && setValue(defaultSelectedItem);
  }, [defaultSelectedItem]);

  useEffect(() => {
    value.length > 0 &&
      defaultSelectedItem &&
      value !== defaultSelectedItem &&
      value.length < defaultSelectedItem.length &&
      setSelectedItem(undefined);
    defaultSelectedItem &&
      value.includes(defaultSelectedItem) &&
      value.length > defaultSelectedItem.length &&
      setSelectedItem(defaultSelectedItem);
  }, [value]);

  useEffect(() => {
    !defaultSelectedItem && setAssets(gatedItems);
  }, [open]);

  useEffect(() => {
    listenerForReset && setValue('');
  }, [listenerForReset]);

  const handleSelectItem = (e: React.MouseEvent, item: any) => {
    if (open) {
      e.preventDefault();
      setOpen(false);
      setValue(item.name || item);
      setTimeout(() => setAssets(gatedItems), 300);
      setSelectedItem(item);
      itemsContainerRef.current?.scroll(0, 0);
    }
  };

  const onChangeOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(true);
    setValue(e.target.value);

    const filteredAssets = gatedItems.filter(item =>
      (item.name || item).toLowerCase().includes(e.target.value.toLowerCase()),
    );
    if (filteredAssets.length === 0) {
      setAssets([]);
      return;
    }

    setAssets(filteredAssets);
    !e.target.value.length && setAssets(gatedItems);
  };

  const onArrowClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpen(!open);
    inputRef.current?.focus();
  };

  const onBlurInput = () => {
    setOpen(false);
    if (!defaultSelectedItem) {
      setValue('');
      setTimeout(() => setAssets(gatedItems), 200);
    } else {
      value.includes(defaultSelectedItem) && setValue(defaultSelectedItem);
    }
  };

  return (
    <div className="select-frame">
      <p className="select-title">{title}</p>

      <input
        onClick={() => setOpen(true)}
        onBlur={onBlurInput}
        ref={inputRef}
        value={value}
        className="select-input"
        placeholder={placeholder}
        onChange={onChangeOnInput}
        type="text"
        data-cy={dataCy}
      />

      <div onMouseDown={onArrowClick} className={`select-icon-container ${open && 'open'}`}>
        <Icon type="arrowVertical" />
      </div>

      <div className={`select-dropdown-frame ${open && 'show'}`}>
        <div ref={itemsContainerRef} className="select-dropdown-container">
          {assets?.map((item, id) => (
            <div
              key={id}
              className={`select-dropdown-item ${(item.name || item) == value && 'active'}`}
              onMouseDown={e => handleSelectItem(e, item)}
            >
              {item.name || item}
            </div>
          ))}

          {assets.length === 0 && (
            <div className="select-dropdown-item empty">
              {t('translation:default_components.autoselect.no_options.message')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutocompleteSelect;
