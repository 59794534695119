import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const setQueryParams = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== false &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      queryParams.set(key, String(value));
    }
  });

  return queryParams.toString();
};
