import { t } from 'i18next';
import React from 'react';

export const PostStateChip = ({ isUsed }: { isUsed: boolean }) => {
  return (
    <div className={`post-card__chip ${isUsed && 'used'}`}>
      {isUsed
        ? t('translation:product_card.used.message')
        : t('translation:product_card.new.message')}
    </div>
  );
};
