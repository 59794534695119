import React, { FC, useEffect, useState } from 'react';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import { InputGamar, Loader, Snackbar } from '../../default_components';
import GoogleCircle from '../../default_components/assets/icons/GoogleCircle.svg';
import { validateField } from '../../../lib/textFieldsValidators';
import { useActions } from '../../../hooks/redux';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import '../authorization.scss';
import { api } from '../../../services/Api';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';

interface IRegistrationProps {
  handleClose: () => void;
  setAuthType: (authType: string) => void;
  redirectUrl: string;
  handleGoogleLogin?: () => void;
}

const Registration: FC<IRegistrationProps> = ({ setAuthType, handleClose, handleGoogleLogin }) => {
  const { savePassword, saveEmail, saveName, savePhone } = useActions();
  const authData = useSelector((state: any) => state.authorizationReducer);
  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();
  const [register, { data, isLoading, isError, error }] = api.useRegisterMutation();

  const [errors, setErrors] = useState<boolean>(true);

  const [email, setEmail] = useState(authData.email);
  const [emailError, setEmailError] = useState<string>('');
  const [hideErrorEmail, setHideErrorEmail] = useState<boolean>(true);

  const [name, setName] = useState(authData.name);
  const [nameError, setNameError] = useState<string>('');
  const [hideErrorName, setHideErrorName] = useState<boolean>(true);

  const [phone, setPhone] = useState('+995');
  const [phoneError, setPhoneError] = useState<string>('');
  const [hideErrorPhone, setHideErrorPhone] = useState<boolean>(true);

  const [password, setPassword] = useState(authData.password);
  const [passwordError, setPasswordError] = useState<string>('');
  const [hideErrorPassword, setHideErrorPassword] = useState<boolean>(true);

  const [savedData] = useState({
    email: authData.email,
    name: authData.name,
    phone: authData.phone,
    password: authData.password,
  });

  const { startOtpTimer } = useActions();

  const moveTo = (page: string) => {
    if (page === 'VERIFICATION') {
      if (authData.otpTimeLeft == 0) {
        startOtpTimer();
      }
    }
    setAuthType(page);
  };

  const REGISTRATION = () => {
    if (errors) {
      setHideErrorEmail(false);
      setHideErrorName(false);
      setHideErrorPhone(false);
      setHideErrorPassword(false);
      return;
    }

    const savedReduxData = {
      email: authData.email,
      name: authData.name,
      phone: authData.phone,
      password: authData.password,
    };

    const savedDataString = JSON.stringify(savedData);
    const savedReduxDataString = JSON.stringify(savedReduxData);

    if (savedDataString !== savedReduxDataString) {
      register({
        username: authData.name,
        email: authData.email,
        password: authData.password,
        phone: authData.phone.replace(/\s/g, ''),
      });
    } else {
      moveTo('VERIFICATION');
    }
  };

  const handlePhoneChange = (value: string) => {
    if (value.startsWith('+995') && value.length <= 13 && /^\d+$/.test(value.slice(1))) {
     setPhone(value);
    }
  };

  useEffect(() => {
    saveName(name);
    savePhone(phone);
    saveEmail(email);
    savePassword(password);
  }, [name, phone, email, password]);

  useEffect(() => {
    emailError || nameError || phoneError || passwordError ? setErrors(true) : setErrors(false);
  }, [emailError, nameError, phoneError, passwordError, email, name, phone, password]);

  useEffect(() => {
    validateField(setEmailError, emailError, email, setHideErrorEmail, 'email', t);
    validateField(setNameError, nameError, name, setHideErrorName, 'name', t);
    validateField(setPhoneError, phoneError, phone, setHideErrorPhone, 'phone', t);
    validateField(setPasswordError, passwordError, password, setHideErrorPassword, 'password', t);
  }, [email, name, phone, password]);

  useEffect(() => {
    if (data) {
      moveTo('VERIFICATION');
    } else if (error && 'status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
      getActiveSnackbar(JSON.parse(errMsg)?.errors, 'error');
    }
  }, [data, isError]);

  return (
    <React.Fragment>
      <div className="authorization__header mb-40">
        <div className="authorization__title">{t('translation:auth.sign_up.message')}</div>

        <div className="authorization__icon__close">
          <img alt="close" src={crossSmallForModal} onClick={handleClose} />
        </div>
      </div>

      <div className="authorization__body">
        <form className="authorization__form">
          <InputGamar
            value={name}
            setValue={setName}
            label={t('translation:auth.name.message')}
            placeholder={'John Doe'}
            type="name"
            error={nameError}
            hideError={hideErrorName}
            setHideError={setHideErrorName}
            onBlur={() => setHideErrorName(false)}
          />

          <InputGamar
            value={phone}
            setValue={handlePhoneChange}
            label={t('translation:auth.phone.message')}
            placeholder={'+995 555 549419'}
            type="phone"
            maxLength={16}
            error={phoneError}
            hideError={hideErrorPhone}
            setHideError={setHideErrorPhone}
            onBlur={() => setHideErrorPhone(false)}
          />

          <InputGamar
            type="email"
            value={email}
            label={t('translation:auth.email.message')}
            error={emailError}
            setValue={setEmail}
            hideError={hideErrorEmail}
            placeholder={'your_e@mail.com'}
            setHideError={setHideErrorEmail}
            onBlur={() => setHideErrorEmail(false)}
          />

          <InputGamar
            value={password}
            setValue={setPassword}
            label={t('translation:auth.password.message')}
            placeholder={'password'}
            passwordType={true}
            showPlaceholder={true}
            error={passwordError}
            hideError={hideErrorPassword}
            setHideError={setHideErrorPassword}
            onBlur={() => setHideErrorPassword(false)}
          />

          <div
            className={`authorization__button mt-60 ${
              errors ? 'authorization__button--disabled' : ''
            }`}
            onClick={REGISTRATION}
          >
            {isLoading ? <Loader isWhite={true} /> : <p>{t('translation:auth.sign_up.message')}</p>}
          </div>
        </form>

        <div className="authorization__footer">
          <div className="authorization__move">
            {t('translation:auth.already_have_account.message')} &nbsp;
            <p className="authorization__small-text underline" onClick={() => moveTo('LOGIN')}>
              {t('translation:auth.sign_in.message')}
            </p>

            <button className="authorization__google-login-button" onClick={handleGoogleLogin}>
              <img src={GoogleCircle} alt="" />
            </button>
          </div>
        </div>
      </div>

      <Snackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </React.Fragment>
  );
};

export default Registration;
