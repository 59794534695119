import React, { FC, useEffect, useState } from 'react';
import { ICategoryField } from "../../../../types";
import { AutocompleteSelect, InputGamar } from "../../../default_components";
import FieldBoolean from "./FieldBoolean";

interface IFieldInput {
  field: ICategoryField;
  fields: Map<string, string>;
}

const FieldInput: FC<IFieldInput> = ({ field, fields }) => {
  const [value, setValue] = useState<string>(fields.get(field.id)!!);

  useEffect(() => {
    fields.set(field.id, value);
  }, [value]);

  const getCorrectType = (fieldType: string): string => fieldType == "integer" ? "number" : "text";

  if (field.type === "boolean") {
    return <FieldBoolean field={field} fields={fields} />
  } else {
    if (field.options.length) {
      return <div style={{ maxWidth: "220px" }}>
        <AutocompleteSelect
          assets={field.options}
          selectedItem={value}
          setSelectedItem={setValue}
          title={field.title}
          placeholder={field.title}
        />
      </div>
    } else {
      return <InputGamar
        value={value}
        setValue={setValue}
        label={field.title}
        placeholder={field.title}
        type={getCorrectType(field.type)}
        w={220}
      />
    }
  }
};

export default FieldInput;
