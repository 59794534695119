import React, { FC, useState } from 'react';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { searchInputStyle } from './searchInputStyle';
import { Icon } from '../../../default_components';
import { t } from 'i18next';
import { useActions } from '../../../../hooks/redux';
import { useNavigate, useParams } from 'react-router-dom';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';

interface ISearchInput {
  closeDrawer?: () => void;
}

const SearchInput: FC<ISearchInput> = ({ closeDrawer }) => {
  const { setPosts, setQuery: setStoredQuery, setIsHandmade } = useActions();

  const [query, setQuery] = useState('');

  const { category_id, query: UrlQuery } = useParams();
  const navigateWithLocale = useNavigateWithLocale();

  const handleSearch = (): void => {
    if (query !== UrlQuery) {
      navigateWithLocale(`/category/${category_id}/${query}`);
      setPosts(undefined);
      setIsHandmade(false);
      setStoredQuery(query);
    }
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      closeDrawer && closeDrawer();
      handleSearch();
    }
  };

  return (
    <FormControl sx={searchInputStyle}>
      <TextField
        autoComplete="off"
        onChange={event => setQuery(event.target.value)}
        onKeyPress={handleEnter}
        value={query}
        placeholder={`${query ? query : t('translation:filter.search.message')}`}
        type="text"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" disableRipple onClick={handleSearch}>
                <Icon type="search" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchInput;
