import React, { FC, useEffect, useRef, useState } from 'react';
import '../Chat.scss';
import replyImg from '../../../img/chat-img/reply.svg';
import ContextMenu from '../context_menu/ContextMenu';
import { IChatUser, IMessage } from '../../../types';
import { formattedDate, getShortMessage, parseUrl } from '../chatUtils';
import { useActions } from '../../../hooks/redux';
import { t } from 'i18next';
import Skeleton from '@mui/material/Skeleton';
import { PostZoomSwiper } from '../../post/postContent/postSwiper';
import useNavigateWithLocale from '../../../hooks/useNavigateWithLocale';

interface IOpponentMessageBox {
  message: IMessage;
  opponent_info?: IChatUser;
  replied_message?: IMessage;
  hideAvatar: boolean;
}

const OpponentMessageBox: FC<IOpponentMessageBox> = ({
  opponent_info,
  message,
  replied_message,
  hideAvatar,
}) => {
  const divRef: any = useRef(0);
  const { replyToMessage, resetContextMenuState } = useActions();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadedAvatar, setLoadedAvatar] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const navigateWithLocale = useNavigateWithLocale();

  const [isZoomSwiperOpened, setIsZoomSwiperOpened] = useState<boolean>(false);

  const handleClick = (event: any) => {
    !message.phone_call && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    divRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  useEffect(() => {
    if (!opponent_info?.avatar) {
      setLoadedAvatar(true);
    }
  }, [opponent_info]);

  const replyMessage = (message: any) => {
    resetContextMenuState();
    replyToMessage(message);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      {replied_message && (
        <div className="reply-on-opponent-message-box">
          <p className="username">
            {replied_message.user_id === opponent_info?.id
              ? `${t('translation:chat.replied_to.message')} ${getShortMessage(
                  10,
                  opponent_info?.username,
                )}`
              : t('translation:chat.replied_to_yours.message')}
          </p>
          <p className="text">{getShortMessage(15, replied_message?.text)}</p>
        </div>
      )}

      <div ref={divRef} className="chat__chat-room-message__from-other">
        <div
          className="chat__user-avatar__container chat-avatar"
          onClick={() => navigateWithLocale(`/user/${opponent_info?.id}`)}
        >
          {!hideAvatar && (
            <React.Fragment>
              {!loadedAvatar && (
                <Skeleton
                  sx={{ width: '60px', height: '60px' }}
                  animation="wave"
                  variant="rectangular"
                />
              )}

              {opponent_info?.avatar ? (
                <img
                  src={opponent_info?.avatar}
                  alt="avatar"
                  className="chat__chat-room-message__from-other-image"
                  onLoad={() => setLoadedAvatar(true)}
                />
              ) : (
                <div className="chat__user-avatar">
                  <h1>{opponent_info?.username[0].toUpperCase()}</h1>
                </div>
              )}
            </React.Fragment>
          )}
        </div>

        <div
          onContextMenu={e => {
            handleClick(e);
            e.preventDefault();
          }}
          onClick={handleClick}
          className={`chat__chat-room-message__from-other__text ${
            message.deleted_at && 'is-deleted'
          } ${hideAvatar && 'hide-arrow'}`}
        >
          <div className="message-image-container">
            {message.images_urls?.length > 0 &&
              message.images_urls.map(image_url => (
                <img
                  onClick={e => {
                    e.stopPropagation();
                    setIsZoomSwiperOpened(true);
                  }}
                  src={image_url}
                  className="message-image"
                  alt={image_url}
                />
              ))}
          </div>

          {message.phone_call && !message.text && !message.deleted_at ? (
            <i>{t('translation:chat.phone_call.message')}</i>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: parseUrl(message.text) }} />
          )}

          <div className="chat__chat-room-message__from-other__time">
            {message.deleted_at && 'Deleted'}
            {!message.deleted_at && message.updated && t('translation:chat.edited.message')}
            {` ${formattedDate(message.created_at)}`}
          </div>
        </div>

        <ContextMenu openMenu={open} anchorEl={anchorEl} handleClose={handleClose}>
          <button
            onClick={() => {
              replyMessage(message);
              handleClose();
            }}
            className="chat__edit-button"
          >
            <img src={replyImg} alt="replyImg" />
            {t('translation:chat.reply.message')}
          </button>
        </ContextMenu>

        <PostZoomSwiper
          images={message.images_urls}
          isShow={isZoomSwiperOpened}
          setIsShow={setIsZoomSwiperOpened}
        />

        {!message.seen && <div className="unread-message-indicator oponent" />}
      </div>
    </div>
  );
};

export default OpponentMessageBox;
