import React, { FC } from 'react';
import { ICategory } from '../../../../../types';

interface IBreadcrumb {
  category: ICategory;
  isLast: boolean;
  onClick: () => void;
}

const Breadcrumb: FC<IBreadcrumb> = ({ category, isLast, onClick }) => (
  <>
    <h1 onClick={onClick} className={`breadcrumb-item ${isLast ? 'current last' : ''}`}>
      {category.name}
    </h1>
  </>
);

export default Breadcrumb;
