import React, { FC, useEffect, useState } from 'react';
import { api } from '../../../services/Api';
import arrowBackModal from '../../default_components/assets/icons/ArrowBackModal.svg';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import OTPInput from './otp/OTPInput';
import Loader from '../../default_components/loader/Loader';
import DefaultSnackbar from '../../default_components/snackbar/DefaultSnackbar';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useActions } from '../../../hooks/redux';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';
import '../authorization.scss';
import { localStorageKeys } from '../../../routes';

interface ILoginProps {
  handleClose: () => void;
  setAuthType: (authType: string) => void;
  redirectUrl: string;
}

const VerifyPhone: FC<ILoginProps> = ({ handleClose, setAuthType, redirectUrl }) => {
  const authData = useSelector((state: any) => state.authorizationReducer);
  const { saveOtp, resetOtpTimeLeft } = useActions();

  const [isErrorSim] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [otp, setOtp] = useState(authData.otp);

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  const [verify, { data, isLoading, isError, error }] = api.usePhoneValidationMutation();
  const [resendOtp] = api.useResendOtpCodeMutation();

  const moveTo = (page: string) => {
    setAuthType(page);
  };

  const resetTimer = () => {
    resetOtpTimeLeft();
    resendOtp({
      email: authData.email,
    });
  };

  const handleOtpChange = (value: any) => {
    setOtp(value);
    saveOtp(value);
  };

  const VERIFICATION = () => {
    verify({ email: authData.email, otp_token: authData.otp });
  };

  useEffect(() => {
    if (authData.otpTimeLeft == 0) {
      setIsTimeOut(true);
    } else {
      setIsTimeOut(false);
    }
  }, [authData.otpTimeLeft]);

  useEffect(() => {
    if (data !== undefined && data.token) {
      localStorage.setItem(localStorageKeys.token, data && data.token);
      getActiveSnackbar(t('translation:auth.registration_success.message'), 'success');
      window.location.replace(redirectUrl);
    } else if (error && 'status' in error) {
      if (error.status === 422) {
        getActiveSnackbar(t('translation:auth.invalid_otp.message'), 'error');
      } else {
        getActiveSnackbar(t('translation:auth.undefined_error.message'), 'error');
      }
    }
  }, [data, isError]);

  return (
    <React.Fragment>
      <div className="authorization__header pb-30">
        <div className="authorization__icon__back">
          <img alt="back" src={arrowBackModal} onClick={() => moveTo('REGISTRATION')} />
        </div>

        <div className="authorization__title">{t('translation:auth.confirm_phone.message')}</div>

        <div className="authorization__icon__close">
          <img alt="close" src={crossSmallForModal} onClick={handleClose} />
        </div>
      </div>

      <div className="authorization__body with-back-icon">
        <form className="authorization__form">
          <div className="authorization__small-text  mb-80">
            {t('translation:auth.confirm_phone_text_1.message')}{' '}
            <b className="authorization__small-text underline colored">{authData.phone}</b>
            {t('translation:auth.confirm_phone_text_2.message')}
          </div>

          <p className={`authorization__small-text--error ${isErrorSim ? '' : 'hidden'}`}>
            {isErrorSim}
          </p>

          <OTPInput value={otp} onChange={handleOtpChange} />

          <div className="space-between mt-20">
            <p className="authorization__small-text">
              <b
                className={`authorization__small-text ${isTimeOut ? 'underline' : ''}`}
                onClick={() => {
                  isTimeOut && resetTimer();
                }}
              >
                {t('translation:auth.resend_code.message')}
              </b>{' '}
              {t('translation:auth.in_.message')}
              {authData.otpTimeLeft < 10 ? `0${authData.otpTimeLeft}` : authData.otpTimeLeft}
            </p>
          </div>

          <div className="authorization__button mt-30" onClick={VERIFICATION}>
            {isLoading ? <Loader isWhite={true} /> : <p>{t('translation:auth.confirm.message')}</p>}
          </div>
        </form>
      </div>

      <div className="authorization__footer">
        <div className="authorization__move">
          {t('translation:auth.already_have_account.message')} &nbsp;
          <p className="authorization__small-text underline" onClick={() => moveTo('LOGIN')}>
            {t('translation:auth.login.message')}
          </p>
        </div>
      </div>

      <DefaultSnackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </React.Fragment>
  );
};

export default VerifyPhone;
