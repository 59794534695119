import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISearch {
  search: {
    q: any;
    category_id: any;
    category_filters: any;
    price: any;
    sort_by: any;
    used: any;
    regions: any;
  };
}

const initialState: ISearch = {
  search: {
    q: '',
    category_id: undefined,
    category_filters: [],
    price: undefined,
    sort_by: 'popular',
    used: undefined,
    regions: undefined,
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateCategory(state: any, category: PayloadAction<any>) {

      if (category.payload === 'all') {
        state.search.category_id = undefined;
      } else {
        state.search.category_id = category.payload;
      }
    },

    updateQuery(state: any, query: PayloadAction<any>) {
      state.search.query = query.payload;
    },
  },
});

export const searchReducer = searchSlice.reducer;
export const searchAction = searchSlice.actions;
