import React, { FC, useEffect, useState } from 'react';
import { api } from '../../../services/Api';
import crossSmallForModal from '../../default_components/assets/icons/CrossSmallForModal.svg';
import { InputGamar, Loader, Snackbar } from '../../default_components';
import GoogleCircle from '../../default_components/assets/icons/GoogleCircle.svg';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useActions } from '../../../hooks/redux';
import { useMethodsSnackbar } from '../../../hooks/useMethodsSnackbar';
import '../authorization.scss';
import { localStorageKeys } from '../../../routes';
import { IAuthToken } from '../../../services/types';

interface ILoginProps {
  handleClose: () => void;
  setAuthType: (authType: string) => void;
  redirectUrl: string;
  handleGoogleLogin?: () => void;
}

const Login: FC<ILoginProps> = ({ handleClose, setAuthType, redirectUrl, handleGoogleLogin }) => {
  const [login, { data, isLoading, isError, error }] = api.useLoginMutation();

  const authData = useSelector((state: any) => state.authorizationReducer);
  const { savePassword, saveEmail } = useActions();
  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  const [password, setPassword] = useState(authData.password);
  const [email, setEmail] = useState(authData.email);

  const LOGIN = () => {
    login({
      email: email,
      password: password,
    });
  };
  const moveTo = (page: string) => {
    setAuthType(page);
  };

  useEffect(() => {
    savePassword(password);
    saveEmail(email);
  }, [email, password]);

  useEffect(() => {
    if (data) {
      handleLogin(data);
    } else if (error && 'status' in error) {
      const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);

      getActiveSnackbar(JSON.parse(errMsg)?.error + ' ' + error.status, 'error');
      if (error.status === 401) {
        getActiveSnackbar(t('translation:auth.wrong_email_or_password.message'), 'error');
      } else if (error.status === 404) {
        getActiveSnackbar(t('translation:auth.user_not_found.message'), 'error');
      } else if (error.status === 500) {
        getActiveSnackbar(t('translation:auth.smth_wrong.message'), 'error');
      }
    }
  }, [data, isError]);

  const handleLogin = (data: IAuthToken) => {
    localStorage.setItem(localStorageKeys.token, data.token);
    getActiveSnackbar(t('translation:auth.auth_success.message'), 'success');
    window.location.replace(redirectUrl);
  };

  return (
    <React.Fragment>
      <div className="authorization__header pb-50">
        <div className="authorization__title">{t('translation:auth.login.message')}</div>

        <div onClick={handleClose} className="authorization__icon__close">
          <img alt="close" src={crossSmallForModal} />
        </div>
      </div>

      <div className="authorization__body">
        <form className="authorization__form">
          <InputGamar
            value={email}
            setValue={setEmail}
            label={t('translation:auth.email.message')}
            placeholder={'your_e@mail.com'}
            showPlaceholder={true}
            type="email"
            autoComplete="email"
            data-cy="login-email-input"
          />

          <InputGamar
            value={password}
            setValue={setPassword}
            label={t('translation:auth.password.message')}
            placeholder={'password'}
            passwordType={true}
            showPlaceholder={true}
            data-cy="login-password-input"
          />

          <p
            className="authorization__small-text underline mt-5"
            onClick={() => moveTo('FORGOT_PASSWORD')}
          >
            {t('translation:auth.forgot_password.message')}
          </p>

          <div className="authorization__button mt-60" onClick={LOGIN} data-cy="login-button">
            {isLoading ? <Loader isWhite={true} /> : <p>{t('translation:auth.login.message')}</p>}
          </div>
        </form>
      </div>

      <div className="authorization__footer">
        <div className="authorization__move">
          {t('translation:auth.no_account.message')} &nbsp;{' '}
          <p className="authorization__small-text underline" onClick={() => moveTo('REGISTRATION')}>
            {t('translation:auth.sign_up.message')}
          </p>
          <button className="authorization__google-login-button" onClick={handleGoogleLogin}>
            <img src={GoogleCircle} alt="" />
          </button>
        </div>
      </div>

      <Snackbar open={open} close={closeSnackbar} message={message} severity={severity} />
    </React.Fragment>
  );
};

export default Login;
