import { Crop } from 'react-image-crop';
import React, { SetStateAction } from 'react';

export const useCropFunction = (
  imageRef: React.RefObject<HTMLImageElement>,
  fileName: string,
  setCroppedImage: React.Dispatch<SetStateAction<File | undefined>>,
): ((crop: Crop) => void) => {
  const IMG_WIDTH = 300;
  const IMG_HEIGHT = 300;

  const makeClientCrop = (crop: Crop): void => {
    if (imageRef.current && crop.width && crop.height) {
      getCroppedImg(imageRef.current, crop, fileName);
    }
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string): void => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = IMG_WIDTH;
    canvas.height = IMG_HEIGHT;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      IMG_WIDTH,
      IMG_HEIGHT,
    );

    const reader = new FileReader();
    canvas.toBlob(async (blob: Blob | null) => {
      if (blob) {
        await reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          await dataURLtoFile(reader.result, fileName);
        };
      }
    });
  };

  const dataURLtoFile = (dataUrl: any, filename: string): void => {
    let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return setCroppedImage(croppedImage);
  };

  return makeClientCrop;
};
