import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './catalogMobileDrop.scss';
import { ICategory } from '../../../../../types';
import { Icon } from '../../../../default_components';
import { getPostCountInCategory } from '../../../../../utils/get';
import { t } from 'i18next';
import { useLocaleFromUrl } from '../../../../../utils/localeFromUrl';

interface ICatalogMobileDrop {
  category: ICategory;
  setCategory: Dispatch<SetStateAction<ICategory | undefined>>;
  isShow: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const CatalogMobileDrop: FC<ICatalogMobileDrop> = ({ category, setCategory, isShow, setOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState<ICategory | undefined>();
  const localeStr = useLocaleFromUrl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isShow]);

  const handleClickOnDropButton = (category: ICategory): void => {
    setOpen(false);
  };

  const handleClickOnPostCount = (category: ICategory): void => {
    setOpen(false);
  };

  return (
    <div className={`catalog-mobile__drop-content ${isShow && 'show'}`}>
      <button className={`catalog-mobile__close-drop-button ${selectedCategory && 'move-action'}`}>
        <div onClick={() => setCategory(undefined)}>
          <Icon type="arrowVertical" />
          <span>{category.name}</span>
        </div>

        <a
          className="post-count"
          onClick={() => handleClickOnPostCount(category)}
          href={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
          style={{ textDecoration: 'none' }}
        >
          {getPostCountInCategory(category)} {t('translation:my_posts.posts.message')}
        </a>
      </button>

      <ul className="catalog-mobile__drop-list">
        {category.children.map((category, key) => (
          <li className="catalog-mobile__drop-item" key={key}>
            <a
              className={`catalog-mobile__drop-button ${selectedCategory && 'move-action'}`}
              href={localeStr ? `${localeStr}/category/${category.id}` : `/category/${category.id}`}
              onClick={() => handleClickOnDropButton(category)}
              style={{ textDecoration: 'none' }}
            >
              <span>{category.name}</span>

              {category.children.length > 0 ? (
                <Icon type="arrowVertical" />
              ) : (
                <span>{getPostCountInCategory(category)}</span>
              )}
            </a>

            <CatalogMobileDrop
              category={category}
              setCategory={setSelectedCategory}
              isShow={selectedCategory?.id == category.id}
              setOpen={setOpen}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CatalogMobileDrop;
