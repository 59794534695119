import React, { FC, useEffect, useState } from 'react';
import './profileAvatar.scss';
import { AvatarUploadModal } from '..';
import { apiProfile } from '../../../../services/ApiProfile';
import { useActions } from '../../../../hooks/redux';
import { Icon, Loader } from '../../../default_components';
import { IUser } from '../../../../types';

interface IProfileAvatar {
  name: string;
  avatar: string | undefined;
  profileInfo: IUser | undefined;
}

const ProfileAvatar: FC<IProfileAvatar> = ({ name, avatar, profileInfo }) => {
  const [deleteAvatar, { isLoading }] = apiProfile.useDeleteAvatarMutation();
  const { setSelectedSize, setCrop } = useActions();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadedImage, setLoadedImage] = useState<boolean>(false);

  useEffect(() => {
    profileInfo && setLoadedImage(profileInfo && !profileInfo.avatar);
  }, [profileInfo]);

  const handleEditAvatar = (): void => {
    setOpenModal(true);
  };

  const handleDeleteAvatar = (): void => {
    deleteAvatar();
    setCrop({
      width: 80,
      height: 80,
      x: 80,
      y: 80,
      unit: 'px',
    });
    setSelectedSize(100);
  };

  return (
    <div>
      <div
        className={`avatar-container 
        ${(isLoading || !loadedImage || !profileInfo) && 'loading'} 
        ${!profileInfo?.avatar && 'none-avatar'}`}
      >
        <div className="upload-avatar-buttons-container">
          {isLoading || !loadedImage || !name.length ? (
            <Loader />
          ) : avatar ? (
            <React.Fragment>
              <div className="edit-icon-btn" onClick={handleEditAvatar}>
                <div className="default-icon">
                  <Icon type="pencilBlackColor" />
                </div>

                <div className="hover-icon">
                  <Icon type="pencil" />
                </div>
              </div>

              <div className="delete-icon-btn" onClick={handleDeleteAvatar}>
                <div className="default-icon">
                  <Icon type="deleteType" />
                </div>

                <div className="hover-icon">
                  <Icon type="deleteRedIcon" />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="upload-avatar-back-arrow" onClick={handleEditAvatar}>
              <Icon type="uploadArrow" />
            </div>
          )}
        </div>

        <div className="avatar-content">
          {avatar ? (
            <img src={avatar} alt="icon" onLoad={() => setLoadedImage(true)} />
          ) : (
            name && <h1>{name[0].toUpperCase()}</h1>
          )}
        </div>

        <div
          className={`avatar-overlay ${!avatar && 'without-avatar'}`}
          onClick={() => !avatar && handleEditAvatar()}
        />
      </div>

      <AvatarUploadModal avatar={avatar} open={openModal} setOpen={setOpenModal} />
    </div>
  );
};

export default ProfileAvatar;
