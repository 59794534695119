import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import basicAuthHeaders, { ApiUrl } from '.';
import {
  ICategory,
  IFacebookPost,
  ILocation,
  ILoginData,
  IPost,
  IRegisterData,
  IResendCode,
  IUser,
  IValidationData,
} from '../types';
import {
  GoogleToken,
  IAuthToken,
  IPurchase,
  IResponseFromCreation,
  ISearchRequest,
  ISearchResponse,
} from './types';
import { ReviewType } from '../types/reviewsTypes';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: ApiUrl }),
  tagTypes: ['FreshPost', 'Profile', 'LikedPosts', 'MyPosts', 'MyPurchases'],
  endpoints: build => ({
    getCategories: build.query<ICategory[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/categories/root`,
      }),
    }),

    getAllCategories: build.query<ICategory[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/categories`,
      }),
    }),

    getCategory: build.query<ICategory, string | unknown>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/categories/${id}`,
      }),
    }),

    login: build.mutation<IAuthToken, ILoginData>({
      query: user => ({
        headers: basicAuthHeaders(),
        url: `/login`,
        method: 'POST',
        body: user,
      }),
    }),

    register: build.mutation<IAuthToken, IRegisterData>({
      query: user => ({
        headers: basicAuthHeaders(),
        url: `/register`,
        method: 'POST',
        body: user,
      }),
    }),

    phoneValidation: build.mutation<IAuthToken, IValidationData>({
      query: user => ({
        headers: basicAuthHeaders(),
        url: `/validate_phone`,
        method: 'POST',
        body: user,
      }),
    }),

    resendOtpCode: build.mutation<IAuthToken, IResendCode>({
      query: user => ({
        headers: basicAuthHeaders(),
        url: `/resend_code`,
        method: 'POST',
        body: user,
      }),
    }),

    sendPost: build.mutation<IResponseFromCreation, { body: FormData }>({
      query: data => ({
        headers: basicAuthHeaders(),
        url: `/posts`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: ['MyPosts'],
    }),

    updatePost: build.mutation<IResponseFromCreation, { body: FormData; id?: string }>({
      query: data => ({
        headers: basicAuthHeaders(),
        url: `/posts/${data.id}`,
        method: 'PUT',
        body: data.body,
      }),
      invalidatesTags: ['MyPosts'],
    }),

    deletePost: build.mutation<IResponseFromCreation, string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/posts/${id}/close`,
        method: 'POST',
      }),
      invalidatesTags: ['MyPosts'],
    }),

    searchByQuery: build.mutation<ISearchResponse, ISearchRequest>({
      query: query => ({
        headers: basicAuthHeaders(),
        url: '/posts/search',
        method: 'POST',
        body: query,
      }),
    }),

    fetchPost: build.query<IPost, { post_id: string }>({
      query: data => ({
        headers: basicAuthHeaders(),
        url: `/posts/${data.post_id}`,
      }),
      providesTags: () => ['MyPosts'],
    }),

    getFreshPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts/fresh`,
      }),
      providesTags: () => ['FreshPost'],
    }),

    getSimilarPosts: build.query<IPost[], string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/posts/${id}/similar`,
      }),
    }),

    getForYouPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts/for_you`,
      }),
    }),

    getAsBuyerPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts/as_buyer`,
      }),
    }),

    getLocations: build.query<ILocation[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/locations`,
      }),
    }),

    getMyPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts`,
      }),
      providesTags: () => ['MyPosts'],
    }),

    getLikedPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts/liked`,
      }),
      providesTags: () => ['LikedPosts'],
    }),

    toLikePost: build.mutation<void, { post_id: string }>({
      query: data => ({
        headers: basicAuthHeaders(),
        url: `/favorites`,
        method: 'POST',
        body: {
          post_id: data.post_id,
        },
      }),
      invalidatesTags: ['LikedPosts'],
    }),

    toUnlikePost: build.mutation<void, { post_id: string }>({
      query: data => ({
        headers: basicAuthHeaders(),
        url: `/favorites`,
        method: 'DELETE',
        body: {
          post_id: data.post_id,
        },
      }),
      invalidatesTags: ['LikedPosts'],
    }),

    getUser: build.query<IUser, string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/users/${id}`,
        method: 'GET',
      }),
    }),

    facebookLogin: build.mutation<IAuthToken, string>({
      query: access_token => ({
        headers: basicAuthHeaders({ access_token }),
        url: `/facebook_login`,
        method: 'POST',
      }),
    }),

    googleLogin: build.mutation<IAuthToken, string>({
      query: access_token => ({
        headers: basicAuthHeaders({ access_token }),
        url: `/google_login`,
        method: 'POST',
      }),
    }),

    getGoogleAccessToken: build.mutation<GoogleToken, string>({
      query: authorization_code => ({
        headers: basicAuthHeaders({ authorization_code }),
        url: `/google_auth`,
        method: 'POST',
      }),
    }),

    getFacebookPosts: build.query<IFacebookPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/blog`,
        method: 'GET',
      }),
    }),

    getRecentlyViewedPosts: build.query<IPost[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/posts/recently_viewed`,
        method: 'GET',
      }),
    }),

    getMyPurchases: build.query<IPurchase[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/deals/?type=buyer`,
        method: 'GET',
      }),
      providesTags: () => ['MyPurchases'],
    }),

    postDeal: build.query<IUser, { chat_id: string | null; post_id: string }>({
      query: ({ chat_id, post_id }) => ({
        headers: basicAuthHeaders(),
        url: `/deals`,
        method: 'POST',
        body: {
          chat_id: chat_id,
          post_id: post_id,
        },
      }),
    }),

    getReviewsBySeller: build.query<ReviewType[], string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/reviews/?seller_id=${id}`,
        method: 'GET',
      }),
    }),

    getReviewsByPost: build.query<ReviewType[], string>({
      query: id => ({
        headers: basicAuthHeaders(),
        url: `/reviews/?post_id=${id}`,
        method: 'GET',
      }),
    }),

    postReview: build.mutation<any, { post_id: string; rating: number; comment: string }>({
      query: ({ post_id, rating, comment }) => ({
        headers: basicAuthHeaders(),
        url: `/reviews`,
        method: 'POST',
        body: {
          post_id,
          rating,
          comment,
        },
      }),
      invalidatesTags: ['MyPurchases'],
    }),

    getTopSellers: build.query<IUser[], void>({
      query: () => ({
        headers: basicAuthHeaders(),
        url: `/users/top_sellers`,
        method: 'GET',
      }),
    }),
  }),
});
