import { useLocation } from 'react-router-dom';

const VALID_LOCALES = ['en', 'ka', 'ru'];

export const useLocaleFromUrl = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);

  const localeFromUrl = pathSegments[0];

  if (VALID_LOCALES.includes(localeFromUrl)) {
    return `/${localeFromUrl}`;
  }

  return '';
};
